import { Inject, Injectable, Injector } from '@angular/core';

import { Document360_ReportService } from './Document360.report.index';
import { Usersnap_ReportService } from './Usersnap.report.index';
import { Utilities_ReportService } from './Utilities.report.index';
import { Addresses_ReportService } from './Addresses.report.index';
import { Carriers_ReportService } from './Carriers.report.index';
import { DockAppointments_ReportService } from './DockAppointments.report.index';
import { Locations_ReportService } from './Locations.report.index';
import { Materials_ReportService } from './Materials.report.index';
import { PurchaseOrders_ReportService } from './PurchaseOrders.report.index';
import { EasyPost_ReportService } from './EasyPost.report.index';
import { Inventory_ReportService } from './Inventory.report.index';
import { Notifications_ReportService } from './Notifications.report.index';
import { Owners_ReportService } from './Owners.report.index';
import { SalesOrders_ReportService } from './SalesOrders.report.index';
import { Lots_ReportService } from './Lots.report.index';
import { FootPrintApiManager_ReportService } from './FootPrintApiManager.report.index';
import { ShipTheory_ReportService } from './ShipTheory.report.index';
import { Manifesting_ReportService } from './Manifesting.report.index';
import { FootPrintManager_ReportService } from './FootPrintManager.report.index';
import { Reports_ReportService } from './Reports.report.index';


@Injectable({ providedIn: 'root' })
export class app_ReportService {

  constructor(
    private injector: Injector
  ) {
    this.Document360 = this.injector.get(Document360_ReportService);
    this.Usersnap = this.injector.get(Usersnap_ReportService);
    this.Utilities = this.injector.get(Utilities_ReportService);
    this.Addresses = this.injector.get(Addresses_ReportService);
    this.Carriers = this.injector.get(Carriers_ReportService);
    this.DockAppointments = this.injector.get(DockAppointments_ReportService);
    this.Locations = this.injector.get(Locations_ReportService);
    this.Materials = this.injector.get(Materials_ReportService);
    this.PurchaseOrders = this.injector.get(PurchaseOrders_ReportService);
    this.EasyPost = this.injector.get(EasyPost_ReportService);
    this.Inventory = this.injector.get(Inventory_ReportService);
    this.Notifications = this.injector.get(Notifications_ReportService);
    this.Owners = this.injector.get(Owners_ReportService);
    this.SalesOrders = this.injector.get(SalesOrders_ReportService);
    this.Lots = this.injector.get(Lots_ReportService);
    this.FootPrintApiManager = this.injector.get(FootPrintApiManager_ReportService);
    this.ShipTheory = this.injector.get(ShipTheory_ReportService);
    this.Manifesting = this.injector.get(Manifesting_ReportService);
    this.FootPrintManager = this.injector.get(FootPrintManager_ReportService);
    this.Reports = this.injector.get(Reports_ReportService);
  }
    public Document360: Document360_ReportService;
    public Usersnap: Usersnap_ReportService;
    public Utilities: Utilities_ReportService;
    public Addresses: Addresses_ReportService;
    public Carriers: Carriers_ReportService;
    public DockAppointments: DockAppointments_ReportService;
    public Locations: Locations_ReportService;
    public Materials: Materials_ReportService;
    public PurchaseOrders: PurchaseOrders_ReportService;
    public EasyPost: EasyPost_ReportService;
    public Inventory: Inventory_ReportService;
    public Notifications: Notifications_ReportService;
    public Owners: Owners_ReportService;
    public SalesOrders: SalesOrders_ReportService;
    public Lots: Lots_ReportService;
    public FootPrintApiManager: FootPrintApiManager_ReportService;
    public ShipTheory: ShipTheory_ReportService;
    public Manifesting: Manifesting_ReportService;
    public FootPrintManager: FootPrintManager_ReportService;
    public Reports: Reports_ReportService;

  public app: app_ReportService = this;

  // injecting lazily in order to avoid circular dependencies
}

