import { Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { ReportBaseService } from './report-base.service';

import { UtilsService } from './utils.service';
import { Reports_DatasourceService } from './Reports.datasource.index';

interface IReports_custom_sales_order_export_reportServiceInParams {
  order_id: number}

interface IReports_custom_sales_order_export_reportServiceData {
  Details?: { result?: { OrderId?: number, LineNumber?: number, HasChildren?: boolean, PackagedAmount?: number, ParentOrderLine?: { OrderId?: number, Material?: { LookupCode?: string }, MaterialPackagingLookup?: { UpcCode?: string } }, Order?: { LookupCode?: string, OwnerReference?: string, VendorReference?: string }, Material?: { LookupCode?: string }, MaterialPackagingLookup?: { UpcCode?: string } }[] }
}

@Injectable({ providedIn: 'root' })
export class Reports_custom_sales_order_export_reportService extends ReportBaseService<IReports_custom_sales_order_export_reportServiceInParams, IReports_custom_sales_order_export_reportServiceData> {

  protected reportReferenceName = 'custom_sales_order_export_report';
  protected appReferenceName = 'Reports';

  constructor(
    utils: UtilsService,
    private datasources: Reports_DatasourceService,
    ) {
      super(utils);
  }

  override throwIfMissingRequiredInParams (inParams: IReports_custom_sales_order_export_reportServiceInParams) {
  }

  protected async getData(inParams: IReports_custom_sales_order_export_reportServiceInParams): Promise<IReports_custom_sales_order_export_reportServiceData> 
  {
    const $utils = this.utils;
    const $report: { inParams: IReports_custom_sales_order_export_reportServiceInParams } = {
      inParams: inParams
    };

    const data: IReports_custom_sales_order_export_reportServiceData = {
    };
    const datasourcesQueries: (() => Promise<void>)[] = [];

    datasourcesQueries.push(async () => {
      const dsParams = {
        order_id:  $report.inParams.order_id 
      };
      
      const dsData = await this.datasources.Reports.custom_ds_sales_order_export_report.get(dsParams);
      
      data.Details = dsData;
    });

    await Promise.all(datasourcesQueries.map(t => t()));

    return data;
  }
}
