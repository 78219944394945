import { Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { CleanupLoggerService } from './cleanup.logging.service';
import { ReportBaseService } from './report-base.service';

import { UtilsService } from './utils.service';
import { SalesOrders_DatasourceService } from './SalesOrders.datasource.index';

interface ISalesOrders_packing_list_reportServiceInParams {
  orderId?: number}

interface ISalesOrders_packing_list_reportServiceData {
  Header?: { result?: { Id?: number, CreatedSysDateTime?: string, CreatedSysUser?: string, LookupCode?: string, Notes?: string, OwnerReference?: string, RequestedDeliveryDate?: string, VendorReference?: string, ShipmentOrderLookups?: { OrderId?: number, ShipmentId?: number, Shipment?: { Id?: number, ItemCount?: number, LookupCode?: string, PalletCount?: number, TrackingIdentifier?: string, Carrier?: { Name?: string } } }[], Project?: { Name?: string, Owner?: { Name?: string } }, shipToAddress?: { AttentionOf?: string, FirstName?: string, LastName?: string, Line1?: string, Line2?: string, City?: string, State?: string, PostalCode?: string, Country?: string, PrimaryTelephone?: string, OrderId?: number, accountName?: string, accountLookupcode?: string }, shipByAddress?: { Id?: number, Project?: { Id?: number, Name?: string, Owner?: { Id?: number, Name?: string, OwnersContactsLookup?: { ContactId?: number, Contact?: { Id?: number, FirstName?: string, LastName?: string, PrimaryTelephone?: string, TypeId?: number, Address?: { City?: string, Country?: string, Line1?: string, Line2?: string, PostalCode?: string, State?: string } } }[] } } }, soldByAddress?: { Id?: number, Project?: { Id?: number, Name?: string, Owner?: { Id?: number, Name?: string, OwnersContactsLookup?: { ContactId?: number, Contact?: { Id?: number, FirstName?: string, LastName?: string, PrimaryTelephone?: string, TypeId?: number, Address?: { City?: string, Country?: string, Line1?: string, Line2?: string, PostalCode?: string, State?: string } } }[] } } } } }
  Details?: { result?: { lookupCode?: string, description?: string, packagedAmount?: number, upcCode?: string, PackagedId?: number, ShipmentLine?: { ShipmentId?: number }, Lot?: { MaterialId?: number, Material?: { IsFixedWeight?: boolean } }, getWeights?: { TotalGrossWeight?: number, TotalNetWeight?: number } }[] }
}

@Injectable({ providedIn: 'root' })
export class SalesOrders_packing_list_reportService extends ReportBaseService<ISalesOrders_packing_list_reportServiceInParams, ISalesOrders_packing_list_reportServiceData> {

  protected reportReferenceName = 'packing_list_report';
  protected appReferenceName = 'SalesOrders';

  constructor(
    utils: UtilsService,
    private datasources: SalesOrders_DatasourceService,
    private logger: CleanupLoggerService
    ) {
      super(utils);
      logger.log('SalesOrders', 'packing_list_report');
  }

  override throwIfMissingRequiredInParams (inParams: ISalesOrders_packing_list_reportServiceInParams) {
  }

  protected async getData(inParams: ISalesOrders_packing_list_reportServiceInParams): Promise<ISalesOrders_packing_list_reportServiceData> 
  {
    const $utils = this.utils;
    const $report: { inParams: ISalesOrders_packing_list_reportServiceInParams } = {
      inParams: inParams
    };

    const data: ISalesOrders_packing_list_reportServiceData = {
    };
    const datasourcesQueries: (() => Promise<void>)[] = [];

    datasourcesQueries.push(async () => {
      const dsParams = {
        orderId:  $report.inParams.orderId 
      };
      
      const dsData = await this.datasources.SalesOrders.ds_packing_list_report_header.get(dsParams);
      
      data.Header = dsData;
    });
    datasourcesQueries.push(async () => {
      const dsParams = {
        orderId:  $report.inParams.orderId 
      };
      
      const dsData = await this.datasources.SalesOrders.ds_packing_list_report_details.get(dsParams);
      
      data.Details = dsData;
    });

    await Promise.all(datasourcesQueries.map(t => t()));

    return data;
  }
}
