<div class="blade-wrapper">
  <div class="blade-header">
    <div  class="blade-tools">
        <app-toolbar [toolbar]="toolbar">
            <ng-template toolbarToolDef="confirm" let-tool>
              <button mat-button
                      data-cy="button"
                      class="datex-button"
                      [disabled]="tool.control.readOnly"
                      [ngStyle]="tool.control.styles.style"
                      [ngClass]="tool.control.styles.classes"
                      (click)="on_confirm_clicked($event)"
                      >
                <div class="button-label">
                  <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                    <div class="button-icon">
                      <i data-cy="button-icon" class="datex-default-spinner"></i>
                    </div>
                  </ng-container>
                  <ng-template #showIcon>
                    <div class="button-icon">
                      <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                    </div>
                  </ng-template>
                  <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                </div>
              
              </button>
            </ng-template>
            <ng-template toolbarToolDef="cancel" let-tool>
              <button mat-button
                      data-cy="button"
                      class="datex-button"
                      [disabled]="tool.control.readOnly"
                      [ngStyle]="tool.control.styles.style"
                      [ngClass]="tool.control.styles.classes"
                      (click)="on_cancel_clicked($event)"
                      >
                <div class="button-label">
                  <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                    <div class="button-icon">
                      <i data-cy="button-icon" class="datex-default-spinner"></i>
                    </div>
                  </ng-container>
                  <ng-template #showIcon>
                    <div class="button-icon">
                      <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                    </div>
                  </ng-template>
                  <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                </div>
              
              </button>
            </ng-template>
        </app-toolbar>
    </div>
    <ng-content></ng-content>
  </div>
  <div class="blade-content">
    <div class="datex-form">
      <ng-container *ngIf="initialized ">
        <div [formGroup]="formGroup"
             class="formdata">
                <div data-cy="fieldset-id-newGroup1"
                     *ngIf="!fieldsets.newGroup1.hidden"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.newGroup1.collapsible }">
                    <div *ngIf="!fieldsets.newGroup1.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.newGroup1.toggle()">
                      <span class="fieldsetsTitle-text">Material</span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.newGroup1.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.newGroup1.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.newGroup1.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.newGroup1.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-owner" *ngIf="!fields.owner.hidden" 
                            class="field-container standard {{fields.owner.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.owner.styles.style"
                            [ngClass]="fields.owner.styles.classes">
                        <div class="label-container"
                              title="{{fields.owner.label}}{{fields.owner.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.owner.label}}">{{fields.owner.label}}<span *ngIf="fields.owner.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <Owners-owners_dd_single 
                            data-cy="selector"
                            [type]="fields.owner.control.type"
                            formControlName="owner"
                            (displayTextChange)="fields.owner.control.displayText=$event"
                            [placeholder]="fields.owner.control.placeholder"
                            [styles]="fields.owner.control.styles"
                          [statusId]="$fields_owner_selector_inParams_statusId"
                          [projectId]="$fields_owner_selector_inParams_projectId"
                        >
                        </Owners-owners_dd_single>
                        <ng-container *ngIf="fields.owner.invalid">
                          <ng-container *ngFor="let error of fields.owner.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-project" *ngIf="!fields.project.hidden" 
                            class="field-container standard {{fields.project.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.project.styles.style"
                            [ngClass]="fields.project.styles.classes">
                        <div class="label-container"
                              title="{{fields.project.label}}{{fields.project.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.project.label}}">{{fields.project.label}}<span *ngIf="fields.project.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <Owners-projects_dd_single 
                            data-cy="selector"
                            [type]="fields.project.control.type"
                            formControlName="project"
                            (displayTextChange)="fields.project.control.displayText=$event"
                            [placeholder]="fields.project.control.placeholder"
                            [styles]="fields.project.control.styles"
                          [statusId]="$fields_project_selector_inParams_statusId"
                          [ownerId]="$fields_project_selector_inParams_ownerId"
                        >
                        </Owners-projects_dd_single>
                        <ng-container *ngIf="fields.project.invalid">
                          <ng-container *ngFor="let error of fields.project.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-lookupcode" *ngIf="!fields.lookupcode.hidden" 
                            class="field-container standard {{fields.lookupcode.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.lookupcode.styles.style"
                            [ngClass]="fields.lookupcode.styles.classes">
                        <div class="label-container"
                              title="{{fields.lookupcode.label}}{{fields.lookupcode.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.lookupcode.label}}">{{fields.lookupcode.label}}<span *ngIf="fields.lookupcode.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <input data-cy="textBox"formControlName="lookupcode"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{fields.lookupcode.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.lookupcode.control.placeholder}}"
                                [ngStyle]="fields.lookupcode.control.styles.style"
                                [ngClass]="fields.lookupcode.control.styles.classes"> 
                        <ng-container *ngIf="fields.lookupcode.invalid">
                          <ng-container *ngFor="let error of fields.lookupcode.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-name" *ngIf="!fields.name.hidden" 
                            class="field-container standard {{fields.name.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.name.styles.style"
                            [ngClass]="fields.name.styles.classes">
                        <div class="label-container"
                              title="{{fields.name.label}}{{fields.name.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.name.label}}">{{fields.name.label}}<span *ngIf="fields.name.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <input data-cy="textBox"formControlName="name"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{fields.name.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.name.control.placeholder}}"
                                [ngStyle]="fields.name.control.styles.style"
                                [ngClass]="fields.name.control.styles.classes"> 
                        <ng-container *ngIf="fields.name.invalid">
                          <ng-container *ngFor="let error of fields.name.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-description" *ngIf="!fields.description.hidden" 
                            class="field-container double {{fields.description.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.description.styles.style"
                            [ngClass]="fields.description.styles.classes">
                        <div class="label-container"
                              title="{{fields.description.label}}{{fields.description.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.description.label}}">{{fields.description.label}}<span *ngIf="fields.description.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <input data-cy="textBox"formControlName="description"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{fields.description.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.description.control.placeholder}}"
                                [ngStyle]="fields.description.control.styles.style"
                                [ngClass]="fields.description.control.styles.classes"> 
                        <ng-container *ngIf="fields.description.invalid">
                          <ng-container *ngFor="let error of fields.description.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-packaging" *ngIf="!fields.packaging.hidden" 
                            class="field-container standard {{fields.packaging.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.packaging.styles.style"
                            [ngClass]="fields.packaging.styles.classes">
                        <div class="label-container"
                              title="{{fields.packaging.label}}{{fields.packaging.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.packaging.label}}">{{fields.packaging.label}}<span *ngIf="fields.packaging.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <Materials-material_packagings_dd_single 
                            data-cy="selector"
                            [type]="fields.packaging.control.type"
                            formControlName="packaging"
                            (displayTextChange)="fields.packaging.control.displayText=$event"
                            [placeholder]="fields.packaging.control.placeholder"
                            [styles]="fields.packaging.control.styles"
                        >
                        </Materials-material_packagings_dd_single>
                        <ng-container *ngIf="fields.packaging.invalid">
                          <ng-container *ngFor="let error of fields.packaging.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-material_group" *ngIf="!fields.material_group.hidden" 
                            class="field-container standard {{fields.material_group.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.material_group.styles.style"
                            [ngClass]="fields.material_group.styles.classes">
                        <div class="label-container"
                              title="{{fields.material_group.label}}{{fields.material_group.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.material_group.label}}">{{fields.material_group.label}}<span *ngIf="fields.material_group.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <Materials-material_groups_dd_single 
                            data-cy="selector"
                            [type]="fields.material_group.control.type"
                            formControlName="material_group"
                            (displayTextChange)="fields.material_group.control.displayText=$event"
                            [placeholder]="fields.material_group.control.placeholder"
                            [styles]="fields.material_group.control.styles"
                        >
                        </Materials-material_groups_dd_single>
                        <ng-container *ngIf="fields.material_group.invalid">
                          <ng-container *ngFor="let error of fields.material_group.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-allocation_strategy" *ngIf="!fields.allocation_strategy.hidden" 
                            class="field-container standard {{fields.allocation_strategy.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.allocation_strategy.styles.style"
                            [ngClass]="fields.allocation_strategy.styles.classes">
                        <div class="label-container"
                              title="{{fields.allocation_strategy.label}}{{fields.allocation_strategy.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.allocation_strategy.label}}">{{fields.allocation_strategy.label}}<span *ngIf="fields.allocation_strategy.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <Materials-allocation_strategy_dd_single 
                            data-cy="selector"
                            [type]="fields.allocation_strategy.control.type"
                            formControlName="allocation_strategy"
                            (displayTextChange)="fields.allocation_strategy.control.displayText=$event"
                            [placeholder]="fields.allocation_strategy.control.placeholder"
                            [styles]="fields.allocation_strategy.control.styles"
                        >
                        </Materials-allocation_strategy_dd_single>
                        <ng-container *ngIf="fields.allocation_strategy.invalid">
                          <ng-container *ngFor="let error of fields.allocation_strategy.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-upc_code" *ngIf="!fields.upc_code.hidden" 
                            class="field-container standard {{fields.upc_code.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.upc_code.styles.style"
                            [ngClass]="fields.upc_code.styles.classes">
                        <div class="label-container"
                              title="{{fields.upc_code.label}}{{fields.upc_code.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.upc_code.label}}">{{fields.upc_code.label}}<span *ngIf="fields.upc_code.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <input data-cy="textBox"formControlName="upc_code"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{fields.upc_code.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.upc_code.control.placeholder}}"
                                [ngStyle]="fields.upc_code.control.styles.style"
                                [ngClass]="fields.upc_code.control.styles.classes"> 
                        <ng-container *ngIf="fields.upc_code.invalid">
                          <ng-container *ngFor="let error of fields.upc_code.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
                  </div>
                </div>
                <div data-cy="fieldset-id-newGroup2"
                     *ngIf="!fieldsets.newGroup2.hidden"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.newGroup2.collapsible }">
                    <div *ngIf="!fieldsets.newGroup2.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.newGroup2.toggle()">
                      <span class="fieldsetsTitle-text">Details</span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.newGroup2.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.newGroup2.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.newGroup2.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.newGroup2.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-lot_controlled" *ngIf="!fields.lot_controlled.hidden" 
                            class="field-container standard {{fields.lot_controlled.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.lot_controlled.styles.style"
                            [ngClass]="fields.lot_controlled.styles.classes">
                        <div class="label-container"
                              title="{{fields.lot_controlled.label}}{{fields.lot_controlled.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.lot_controlled.label}}">{{fields.lot_controlled.label}}<span *ngIf="fields.lot_controlled.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <mat-checkbox data-cy="checkBox" formControlName="lot_controlled"
                                      class="datex-checkbox"
                                      color="primary"
                                      [ngStyle]="fields.lot_controlled.control.styles.style"
                                      [ngClass]="fields.lot_controlled.control.styles.classes">{{fields.lot_controlled.control.label}}</mat-checkbox>
                        <ng-container *ngIf="fields.lot_controlled.invalid">
                          <ng-container *ngFor="let error of fields.lot_controlled.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-serial_controlled" *ngIf="!fields.serial_controlled.hidden" 
                            class="field-container standard {{fields.serial_controlled.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.serial_controlled.styles.style"
                            [ngClass]="fields.serial_controlled.styles.classes">
                        <div class="label-container"
                              title="{{fields.serial_controlled.label}}{{fields.serial_controlled.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.serial_controlled.label}}">{{fields.serial_controlled.label}}<span *ngIf="fields.serial_controlled.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <mat-checkbox data-cy="checkBox" formControlName="serial_controlled"
                                      class="datex-checkbox"
                                      color="primary"
                                      [ngStyle]="fields.serial_controlled.control.styles.style"
                                      [ngClass]="fields.serial_controlled.control.styles.classes">{{fields.serial_controlled.control.label}}</mat-checkbox>
                        <ng-container *ngIf="fields.serial_controlled.invalid">
                          <ng-container *ngFor="let error of fields.serial_controlled.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-fixed_weight" *ngIf="!fields.fixed_weight.hidden" 
                            class="field-container standard {{fields.fixed_weight.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.fixed_weight.styles.style"
                            [ngClass]="fields.fixed_weight.styles.classes">
                        <div class="label-container"
                              title="{{fields.fixed_weight.label}}{{fields.fixed_weight.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.fixed_weight.label}}">{{fields.fixed_weight.label}}<span *ngIf="fields.fixed_weight.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <mat-checkbox data-cy="checkBox" formControlName="fixed_weight"
                                      class="datex-checkbox"
                                      color="primary"
                                      [ngStyle]="fields.fixed_weight.control.styles.style"
                                      [ngClass]="fields.fixed_weight.control.styles.classes">{{fields.fixed_weight.control.label}}</mat-checkbox>
                        <ng-container *ngIf="fields.fixed_weight.invalid">
                          <ng-container *ngFor="let error of fields.fixed_weight.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-fixed_volume" *ngIf="!fields.fixed_volume.hidden" 
                            class="field-container standard {{fields.fixed_volume.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.fixed_volume.styles.style"
                            [ngClass]="fields.fixed_volume.styles.classes">
                        <div class="label-container"
                              title="{{fields.fixed_volume.label}}{{fields.fixed_volume.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.fixed_volume.label}}">{{fields.fixed_volume.label}}<span *ngIf="fields.fixed_volume.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <mat-checkbox data-cy="checkBox" formControlName="fixed_volume"
                                      class="datex-checkbox"
                                      color="primary"
                                      [ngStyle]="fields.fixed_volume.control.styles.style"
                                      [ngClass]="fields.fixed_volume.control.styles.classes">{{fields.fixed_volume.control.label}}</mat-checkbox>
                        <ng-container *ngIf="fields.fixed_volume.invalid">
                          <ng-container *ngFor="let error of fields.fixed_volume.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-fixed_dimension" *ngIf="!fields.fixed_dimension.hidden" 
                            class="field-container standard {{fields.fixed_dimension.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.fixed_dimension.styles.style"
                            [ngClass]="fields.fixed_dimension.styles.classes">
                        <div class="label-container"
                              title="{{fields.fixed_dimension.label}}{{fields.fixed_dimension.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.fixed_dimension.label}}">{{fields.fixed_dimension.label}}<span *ngIf="fields.fixed_dimension.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <mat-checkbox data-cy="checkBox" formControlName="fixed_dimension"
                                      class="datex-checkbox"
                                      color="primary"
                                      [ngStyle]="fields.fixed_dimension.control.styles.style"
                                      [ngClass]="fields.fixed_dimension.control.styles.classes">{{fields.fixed_dimension.control.label}}</mat-checkbox>
                        <ng-container *ngIf="fields.fixed_dimension.invalid">
                          <ng-container *ngFor="let error of fields.fixed_dimension.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-decimal_precision" *ngIf="!fields.decimal_precision.hidden" 
                            class="field-container standard {{fields.decimal_precision.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.decimal_precision.styles.style"
                            [ngClass]="fields.decimal_precision.styles.classes">
                        <div class="label-container"
                              title="{{fields.decimal_precision.label}}{{fields.decimal_precision.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.decimal_precision.label}}">{{fields.decimal_precision.label}}<span *ngIf="fields.decimal_precision.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <Materials-decimal_precision_single 
                            data-cy="selector"
                            [type]="fields.decimal_precision.control.type"
                            formControlName="decimal_precision"
                            (displayTextChange)="fields.decimal_precision.control.displayText=$event"
                            [placeholder]="fields.decimal_precision.control.placeholder"
                            [styles]="fields.decimal_precision.control.styles"
                        >
                        </Materials-decimal_precision_single>
                        <ng-container *ngIf="fields.decimal_precision.invalid">
                          <ng-container *ngFor="let error of fields.decimal_precision.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-weight" *ngIf="!fields.weight.hidden" 
                            class="field-container standard {{fields.weight.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.weight.styles.style"
                            [ngClass]="fields.weight.styles.classes">
                        <div class="label-container"
                              title="{{fields.weight.label}}{{fields.weight.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.weight.label}}">{{fields.weight.label}}<span *ngIf="fields.weight.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <input data-cy="numberBox" formControlName="weight"
                                matInput
                                numberBox
                                [format]="fields.weight.control.format"
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-numberbox {{fields.weight.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.weight.control.placeholder}}"
                                [ngStyle]="fields.weight.control.styles.style"
                                [ngClass]="fields.weight.control.styles.classes">
                        <ng-container *ngIf="fields.weight.invalid">
                          <ng-container *ngFor="let error of fields.weight.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-shipping_weight" *ngIf="!fields.shipping_weight.hidden" 
                            class="field-container standard {{fields.shipping_weight.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.shipping_weight.styles.style"
                            [ngClass]="fields.shipping_weight.styles.classes">
                        <div class="label-container"
                              title="{{fields.shipping_weight.label}}{{fields.shipping_weight.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.shipping_weight.label}}">{{fields.shipping_weight.label}}<span *ngIf="fields.shipping_weight.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <input data-cy="numberBox" formControlName="shipping_weight"
                                matInput
                                numberBox
                                [format]="fields.shipping_weight.control.format"
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-numberbox {{fields.shipping_weight.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.shipping_weight.control.placeholder}}"
                                [ngStyle]="fields.shipping_weight.control.styles.style"
                                [ngClass]="fields.shipping_weight.control.styles.classes">
                        <ng-container *ngIf="fields.shipping_weight.invalid">
                          <ng-container *ngFor="let error of fields.shipping_weight.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-weight_uom" *ngIf="!fields.weight_uom.hidden" 
                            class="field-container standard {{fields.weight_uom.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.weight_uom.styles.style"
                            [ngClass]="fields.weight_uom.styles.classes">
                        <div class="label-container"
                              title="{{fields.weight_uom.label}}{{fields.weight_uom.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.weight_uom.label}}">{{fields.weight_uom.label}}<span *ngIf="fields.weight_uom.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <Materials-measurement_units_dd_single 
                            data-cy="selector"
                            [type]="fields.weight_uom.control.type"
                            formControlName="weight_uom"
                            (displayTextChange)="fields.weight_uom.control.displayText=$event"
                            [placeholder]="fields.weight_uom.control.placeholder"
                            [styles]="fields.weight_uom.control.styles"
                          [typeId]="$fields_weight_uom_selector_inParams_typeId"
                        >
                        </Materials-measurement_units_dd_single>
                        <ng-container *ngIf="fields.weight_uom.invalid">
                          <ng-container *ngFor="let error of fields.weight_uom.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-volume" *ngIf="!fields.volume.hidden" 
                            class="field-container standard {{fields.volume.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.volume.styles.style"
                            [ngClass]="fields.volume.styles.classes">
                        <div class="label-container"
                              title="{{fields.volume.label}}{{fields.volume.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.volume.label}}">{{fields.volume.label}}<span *ngIf="fields.volume.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <input data-cy="numberBox" formControlName="volume"
                                matInput
                                numberBox
                                [format]="fields.volume.control.format"
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-numberbox {{fields.volume.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.volume.control.placeholder}}"
                                [ngStyle]="fields.volume.control.styles.style"
                                [ngClass]="fields.volume.control.styles.classes">
                        <ng-container *ngIf="fields.volume.invalid">
                          <ng-container *ngFor="let error of fields.volume.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-shipping_volume" *ngIf="!fields.shipping_volume.hidden" 
                            class="field-container standard {{fields.shipping_volume.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.shipping_volume.styles.style"
                            [ngClass]="fields.shipping_volume.styles.classes">
                        <div class="label-container"
                              title="{{fields.shipping_volume.label}}{{fields.shipping_volume.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.shipping_volume.label}}">{{fields.shipping_volume.label}}<span *ngIf="fields.shipping_volume.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <input data-cy="numberBox" formControlName="shipping_volume"
                                matInput
                                numberBox
                                [format]="fields.shipping_volume.control.format"
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-numberbox {{fields.shipping_volume.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.shipping_volume.control.placeholder}}"
                                [ngStyle]="fields.shipping_volume.control.styles.style"
                                [ngClass]="fields.shipping_volume.control.styles.classes">
                        <ng-container *ngIf="fields.shipping_volume.invalid">
                          <ng-container *ngFor="let error of fields.shipping_volume.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-volume_uom" *ngIf="!fields.volume_uom.hidden" 
                            class="field-container standard {{fields.volume_uom.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.volume_uom.styles.style"
                            [ngClass]="fields.volume_uom.styles.classes">
                        <div class="label-container"
                              title="{{fields.volume_uom.label}}{{fields.volume_uom.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.volume_uom.label}}">{{fields.volume_uom.label}}<span *ngIf="fields.volume_uom.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <Materials-measurement_units_dd_single 
                            data-cy="selector"
                            [type]="fields.volume_uom.control.type"
                            formControlName="volume_uom"
                            (displayTextChange)="fields.volume_uom.control.displayText=$event"
                            [placeholder]="fields.volume_uom.control.placeholder"
                            [styles]="fields.volume_uom.control.styles"
                          [typeId]="$fields_volume_uom_selector_inParams_typeId"
                        >
                        </Materials-measurement_units_dd_single>
                        <ng-container *ngIf="fields.volume_uom.invalid">
                          <ng-container *ngFor="let error of fields.volume_uom.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-length" *ngIf="!fields.length.hidden" 
                            class="field-container standard {{fields.length.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.length.styles.style"
                            [ngClass]="fields.length.styles.classes">
                        <div class="label-container"
                              title="{{fields.length.label}}{{fields.length.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.length.label}}">{{fields.length.label}}<span *ngIf="fields.length.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <input data-cy="numberBox" formControlName="length"
                                matInput
                                numberBox
                                [format]="fields.length.control.format"
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-numberbox {{fields.length.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.length.control.placeholder}}"
                                [ngStyle]="fields.length.control.styles.style"
                                [ngClass]="fields.length.control.styles.classes">
                        <ng-container *ngIf="fields.length.invalid">
                          <ng-container *ngFor="let error of fields.length.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-width" *ngIf="!fields.width.hidden" 
                            class="field-container standard {{fields.width.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.width.styles.style"
                            [ngClass]="fields.width.styles.classes">
                        <div class="label-container"
                              title="{{fields.width.label}}{{fields.width.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.width.label}}">{{fields.width.label}}<span *ngIf="fields.width.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <input data-cy="numberBox" formControlName="width"
                                matInput
                                numberBox
                                [format]="fields.width.control.format"
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-numberbox {{fields.width.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.width.control.placeholder}}"
                                [ngStyle]="fields.width.control.styles.style"
                                [ngClass]="fields.width.control.styles.classes">
                        <ng-container *ngIf="fields.width.invalid">
                          <ng-container *ngFor="let error of fields.width.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-height" *ngIf="!fields.height.hidden" 
                            class="field-container standard {{fields.height.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.height.styles.style"
                            [ngClass]="fields.height.styles.classes">
                        <div class="label-container"
                              title="{{fields.height.label}}{{fields.height.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.height.label}}">{{fields.height.label}}<span *ngIf="fields.height.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <input data-cy="numberBox" formControlName="height"
                                matInput
                                numberBox
                                [format]="fields.height.control.format"
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-numberbox {{fields.height.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.height.control.placeholder}}"
                                [ngStyle]="fields.height.control.styles.style"
                                [ngClass]="fields.height.control.styles.classes">
                        <ng-container *ngIf="fields.height.invalid">
                          <ng-container *ngFor="let error of fields.height.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-dimension_uom" *ngIf="!fields.dimension_uom.hidden" 
                            class="field-container standard {{fields.dimension_uom.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.dimension_uom.styles.style"
                            [ngClass]="fields.dimension_uom.styles.classes">
                        <div class="label-container"
                              title="{{fields.dimension_uom.label}}{{fields.dimension_uom.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.dimension_uom.label}}">{{fields.dimension_uom.label}}<span *ngIf="fields.dimension_uom.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <Materials-measurement_units_dd_single 
                            data-cy="selector"
                            [type]="fields.dimension_uom.control.type"
                            formControlName="dimension_uom"
                            (displayTextChange)="fields.dimension_uom.control.displayText=$event"
                            [placeholder]="fields.dimension_uom.control.placeholder"
                            [styles]="fields.dimension_uom.control.styles"
                          [typeId]="$fields_dimension_uom_selector_inParams_typeId"
                        >
                        </Materials-measurement_units_dd_single>
                        <ng-container *ngIf="fields.dimension_uom.invalid">
                          <ng-container *ngFor="let error of fields.dimension_uom.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-pallet_high" *ngIf="!fields.pallet_high.hidden" 
                            class="field-container standard {{fields.pallet_high.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.pallet_high.styles.style"
                            [ngClass]="fields.pallet_high.styles.classes">
                        <div class="label-container"
                              title="{{fields.pallet_high.label}}{{fields.pallet_high.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.pallet_high.label}}">{{fields.pallet_high.label}}<span *ngIf="fields.pallet_high.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <input data-cy="numberBox" formControlName="pallet_high"
                                matInput
                                numberBox
                                [format]="fields.pallet_high.control.format"
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-numberbox {{fields.pallet_high.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.pallet_high.control.placeholder}}"
                                [ngStyle]="fields.pallet_high.control.styles.style"
                                [ngClass]="fields.pallet_high.control.styles.classes">
                        <ng-container *ngIf="fields.pallet_high.invalid">
                          <ng-container *ngFor="let error of fields.pallet_high.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-pallet_tie" *ngIf="!fields.pallet_tie.hidden" 
                            class="field-container standard {{fields.pallet_tie.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.pallet_tie.styles.style"
                            [ngClass]="fields.pallet_tie.styles.classes">
                        <div class="label-container"
                              title="{{fields.pallet_tie.label}}{{fields.pallet_tie.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.pallet_tie.label}}">{{fields.pallet_tie.label}}<span *ngIf="fields.pallet_tie.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <input data-cy="numberBox" formControlName="pallet_tie"
                                matInput
                                numberBox
                                [format]="fields.pallet_tie.control.format"
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-numberbox {{fields.pallet_tie.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.pallet_tie.control.placeholder}}"
                                [ngStyle]="fields.pallet_tie.control.styles.style"
                                [ngClass]="fields.pallet_tie.control.styles.classes">
                        <ng-container *ngIf="fields.pallet_tie.invalid">
                          <ng-container *ngFor="let error of fields.pallet_tie.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-shelf_life_span" *ngIf="!fields.shelf_life_span.hidden" 
                            class="field-container standard {{fields.shelf_life_span.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.shelf_life_span.styles.style"
                            [ngClass]="fields.shelf_life_span.styles.classes">
                        <div class="label-container"
                              title="{{fields.shelf_life_span.label}}{{fields.shelf_life_span.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.shelf_life_span.label}}">{{fields.shelf_life_span.label}}<span *ngIf="fields.shelf_life_span.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <input data-cy="numberBox" formControlName="shelf_life_span"
                                matInput
                                numberBox
                                [format]="fields.shelf_life_span.control.format"
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-numberbox {{fields.shelf_life_span.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.shelf_life_span.control.placeholder}}"
                                [ngStyle]="fields.shelf_life_span.control.styles.style"
                                [ngClass]="fields.shelf_life_span.control.styles.classes">
                        <ng-container *ngIf="fields.shelf_life_span.invalid">
                          <ng-container *ngFor="let error of fields.shelf_life_span.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
                  </div>
                </div>
                <div data-cy="fieldset-id-newGroup3"
                     *ngIf="!fieldsets.newGroup3.hidden"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.newGroup3.collapsible }">
                    <div *ngIf="!fieldsets.newGroup3.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.newGroup3.toggle()">
                      <span class="fieldsetsTitle-text">Advanced</span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.newGroup3.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.newGroup3.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.newGroup3.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.newGroup3.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-allocation_quantity" *ngIf="!fields.allocation_quantity.hidden" 
                            class="field-container standard {{fields.allocation_quantity.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.allocation_quantity.styles.style"
                            [ngClass]="fields.allocation_quantity.styles.classes">
                        <div class="label-container"
                              title="{{fields.allocation_quantity.label}}{{fields.allocation_quantity.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.allocation_quantity.label}}">{{fields.allocation_quantity.label}}<span *ngIf="fields.allocation_quantity.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <input data-cy="numberBox" formControlName="allocation_quantity"
                                matInput
                                numberBox
                                [format]="fields.allocation_quantity.control.format"
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-numberbox {{fields.allocation_quantity.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.allocation_quantity.control.placeholder}}"
                                [ngStyle]="fields.allocation_quantity.control.styles.style"
                                [ngClass]="fields.allocation_quantity.control.styles.classes">
                        <ng-container *ngIf="fields.allocation_quantity.invalid">
                          <ng-container *ngFor="let error of fields.allocation_quantity.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-commodity_code" *ngIf="!fields.commodity_code.hidden" 
                            class="field-container standard {{fields.commodity_code.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.commodity_code.styles.style"
                            [ngClass]="fields.commodity_code.styles.classes">
                        <div class="label-container"
                              title="{{fields.commodity_code.label}}{{fields.commodity_code.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.commodity_code.label}}">{{fields.commodity_code.label}}<span *ngIf="fields.commodity_code.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <input data-cy="textBox"formControlName="commodity_code"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{fields.commodity_code.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.commodity_code.control.placeholder}}"
                                [ngStyle]="fields.commodity_code.control.styles.style"
                                [ngClass]="fields.commodity_code.control.styles.classes"> 
                        <ng-container *ngIf="fields.commodity_code.invalid">
                          <ng-container *ngFor="let error of fields.commodity_code.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-country_of_origin" *ngIf="!fields.country_of_origin.hidden" 
                            class="field-container standard {{fields.country_of_origin.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.country_of_origin.styles.style"
                            [ngClass]="fields.country_of_origin.styles.classes">
                        <div class="label-container"
                              title="{{fields.country_of_origin.label}}{{fields.country_of_origin.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.country_of_origin.label}}">{{fields.country_of_origin.label}}<span *ngIf="fields.country_of_origin.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <input data-cy="textBox"formControlName="country_of_origin"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{fields.country_of_origin.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.country_of_origin.control.placeholder}}"
                                [ngStyle]="fields.country_of_origin.control.styles.style"
                                [ngClass]="fields.country_of_origin.control.styles.classes"> 
                        <ng-container *ngIf="fields.country_of_origin.invalid">
                          <ng-container *ngFor="let error of fields.country_of_origin.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-duty_rate" *ngIf="!fields.duty_rate.hidden" 
                            class="field-container standard {{fields.duty_rate.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.duty_rate.styles.style"
                            [ngClass]="fields.duty_rate.styles.classes">
                        <div class="label-container"
                              title="{{fields.duty_rate.label}}{{fields.duty_rate.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.duty_rate.label}}">{{fields.duty_rate.label}}<span *ngIf="fields.duty_rate.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <input data-cy="numberBox" formControlName="duty_rate"
                                matInput
                                numberBox
                                [format]="fields.duty_rate.control.format"
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-numberbox {{fields.duty_rate.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.duty_rate.control.placeholder}}"
                                [ngStyle]="fields.duty_rate.control.styles.style"
                                [ngClass]="fields.duty_rate.control.styles.classes">
                        <ng-container *ngIf="fields.duty_rate.invalid">
                          <ng-container *ngFor="let error of fields.duty_rate.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-require_outbound_scanning" *ngIf="!fields.require_outbound_scanning.hidden" 
                            class="field-container standard {{fields.require_outbound_scanning.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.require_outbound_scanning.styles.style"
                            [ngClass]="fields.require_outbound_scanning.styles.classes">
                        <div class="label-container"
                              title="{{fields.require_outbound_scanning.label}}{{fields.require_outbound_scanning.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.require_outbound_scanning.label}}">{{fields.require_outbound_scanning.label}}<span *ngIf="fields.require_outbound_scanning.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <mat-slide-toggle data-cy="slideToggle" formControlName="require_outbound_scanning"
                                      color="primary"
                                      class="datex-toggle"
                                      [ngStyle]="fields.require_outbound_scanning.control.styles.style"
                                      [ngClass]="fields.require_outbound_scanning.control.styles.classes">{{fields.require_outbound_scanning.control.label}}</mat-slide-toggle>
                        <ng-container *ngIf="fields.require_outbound_scanning.invalid">
                          <ng-container *ngFor="let error of fields.require_outbound_scanning.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-special_instructions" *ngIf="!fields.special_instructions.hidden" 
                            class="field-container standard {{fields.special_instructions.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.special_instructions.styles.style"
                            [ngClass]="fields.special_instructions.styles.classes">
                        <div class="label-container"
                              title="{{fields.special_instructions.label}}{{fields.special_instructions.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.special_instructions.label}}">{{fields.special_instructions.label}}<span *ngIf="fields.special_instructions.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <input data-cy="textBox"formControlName="special_instructions"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{fields.special_instructions.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.special_instructions.control.placeholder}}"
                                [ngStyle]="fields.special_instructions.control.styles.style"
                                [ngClass]="fields.special_instructions.control.styles.classes"> 
                        <ng-container *ngIf="fields.special_instructions.invalid">
                          <ng-container *ngFor="let error of fields.special_instructions.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
                  </div>
                </div>
              </div>      </ng-container>

    </div>
  </div>
</div>