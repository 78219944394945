import { Injectable } from '@angular/core';
import { ParamMap } from '@angular/router';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { isNil, cloneDeep } from 'lodash-es';

import { ShellService, EModalSize, EToasterType, EToasterPosition } from './shell.service'
export { EModalSize, EToasterType, EToasterPosition } from './shell.service';
import { ToastrService } from 'ngx-toastr';
import { CleanupLoggerService } from './cleanup.logging.service';
import { Inspections_inspection_operation_codes_dd_singleComponent } from './Inspections.inspection_operation_codes_dd_single.component';
import { Inspections_reason_codes_dd_singleComponent } from './Inspections.reason_codes_dd_single.component';
import { Inspections_task_date_types_dd_singleComponent } from './Inspections.task_date_types_dd_single.component';
import { Inspections_task_statuses_dd_singleComponent } from './Inspections.task_statuses_dd_single.component';
import { Inspections_inspection_operation_codes_dd_multiComponent } from './Inspections.inspection_operation_codes_dd_multi.component';
import { Inspections_reason_codes_dd_multiComponent } from './Inspections.reason_codes_dd_multi.component';
import { Inspections_task_date_types_dd_multiComponent } from './Inspections.task_date_types_dd_multi.component';
import { Inspections_task_statuses_dd_multiComponent } from './Inspections.task_statuses_dd_multi.component';

import { Utilities_ShellService } from './Utilities.shell.service';

@Injectable({ providedIn: 'root' })
export class Inspections_ShellService extends ShellService  {
  constructor(
    dialog : MatDialog,
    toastr: ToastrService,
    private logger: CleanupLoggerService,
    public Utilities: Utilities_ShellService,
  ) {
    super(dialog, toastr);
  }

  public Inspections: Inspections_ShellService = this;

  // wizards shouldn't be opened in blades (hacky check with "#unless steps" to recognize the config type)

  public getComponentInformation(referenceName: string, params: ParamMap): { title: string, component: any, inParams: any } {
    if (referenceName === 'Inspections_inspection_operation_codes_dd_single') {
      this.logger.log('Inspections', 'inspection_operation_codes_dd_single');
      const title = 'inspection_operation_codes_dd';
      const component = Inspections_inspection_operation_codes_dd_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Inspections_reason_codes_dd_single') {
      this.logger.log('Inspections', 'reason_codes_dd_single');
      const title = 'reason_codes_dd';
      const component = Inspections_reason_codes_dd_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Inspections_task_date_types_dd_single') {
      this.logger.log('Inspections', 'task_date_types_dd_single');
      const title = 'task_date_types_dd';
      const component = Inspections_task_date_types_dd_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Inspections_task_statuses_dd_single') {
      this.logger.log('Inspections', 'task_statuses_dd_single');
      const title = 'task_statuses_dd';
      const component = Inspections_task_statuses_dd_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Inspections_inspection_operation_codes_dd_multi') {
      this.logger.log('Inspections', 'inspection_operation_codes_dd_multi');
      const title = 'inspection_operation_codes_dd';
      const component = Inspections_inspection_operation_codes_dd_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Inspections_reason_codes_dd_multi') {
      this.logger.log('Inspections', 'reason_codes_dd_multi');
      const title = 'reason_codes_dd';
      const component = Inspections_reason_codes_dd_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Inspections_task_date_types_dd_multi') {
      this.logger.log('Inspections', 'task_date_types_dd_multi');
      const title = 'task_date_types_dd';
      const component = Inspections_task_date_types_dd_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Inspections_task_statuses_dd_multi') {
      this.logger.log('Inspections', 'task_statuses_dd_multi');
      const title = 'task_statuses_dd';
      const component = Inspections_task_statuses_dd_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }

    let result = null;
    result = this.Utilities.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    return result;
  }
}
