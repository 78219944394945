import { Component, OnInit, Input, SimpleChanges, OnChanges, EventEmitter, Output, ViewChild, Inject, OnDestroy, forwardRef } from '@angular/core';
import { FormGroup, FormControl, Validators} from '@angular/forms';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles 
} from './models/control';
import { GridHeaderModel, GridCellModel, GridRowModel, CellStyles, GridContainerStyle, HeaderStyles } from './models/grid'
import { FieldModel } from './models/field'
import { ToolModel } from './models/tool';
import { Styles, ControlContainerStyles } from './models/style';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { isEqual, isNil, set } from 'lodash-es';
import { GridComponent, ELoadingStatus } from './components/grid.component';
import { BaseComponent } from './components/base.component';
import { WorkBook, read as readExcelFile, writeFile as writeExcelFile, utils as excelUtils } from 'xlsx';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { app_ShellService, EModalSize, EToasterType, EToasterPosition } from './app.shell.service';
import { app_OperationService } from './app.operation.service';
import { app_DatasourceService } from './app.datasource.index';
import { app_FlowService } from './app.flow.index';
import { app_ReportService } from './app.report.index';
import { app_LocalizationService } from './app.localization.service';
import { Language } from './localization.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './app.frontend.types'
import { $frontendTypes as $types} from './app.frontend.types' 



interface Iapp_custom_excel_sales_order_tracking_import_gridComponentEntity {
Id?: number}



class app_custom_excel_sales_order_tracking_import_gridComponentRowModel extends GridRowModel {
  grid: app_custom_excel_sales_order_tracking_import_gridComponent;
  entity: Iapp_custom_excel_sales_order_tracking_import_gridComponentEntity;



 // temporarily put required on all edit controls
  override formGroup = new FormGroup({
    orderId_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    shipmentId_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    owner_reference_edit: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'change' }),
    tracking_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
  });

  override cells = {
    orderId: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
      new NumberBoxModel(this.formGroup.controls['orderId_edit'] as DatexFormControl, null, false, '', '')
      ),
    shipmentId: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
      new NumberBoxModel(this.formGroup.controls['shipmentId_edit'] as DatexFormControl, null, false, '', '')
      ),
    owner_reference: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
      new TextBoxModel(this.formGroup.controls['owner_reference_edit'] as DatexFormControl, null, false, '')
      ),
    tracking: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
      new TextBoxModel(this.formGroup.controls['tracking_edit'] as DatexFormControl, null, false, '')
      ),
    error: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
  }



  constructor(
    private utils: UtilsService,
private settings: SettingsValuesService,
private shell: app_ShellService,
private datasources: app_DatasourceService,
private flows: app_FlowService,
private reports: app_ReportService,
private localization: app_LocalizationService,
private operations: app_OperationService,
private logger: CleanupLoggerService,
) {
    super();
    
  }

  async $initializeExisting(grid: app_custom_excel_sales_order_tracking_import_gridComponent, entity: Iapp_custom_excel_sales_order_tracking_import_gridComponentEntity, propertyChangeCallback: (source: GridRowModel, property: string) => void = null) {
    this.$propertyChangeCallback = propertyChangeCallback;

    this.grid = grid;
    this.entity = entity;

    this.rowId = [this.entity.Id].join('-');

    await this.$dataLoaded();
  }

  async $initializeNew(grid: app_custom_excel_sales_order_tracking_import_gridComponent, entity?: Iapp_custom_excel_sales_order_tracking_import_gridComponentEntity, propertyChangeCallback: (source: GridRowModel, property: string) => void = null) {
    this.$propertyChangeCallback = propertyChangeCallback;

    this.grid = grid;
    this.isNew = true;

    if (entity) {
      this.entity = entity;
    }
    else {
      this.entity = {};
      await this.on_init_new_row();
    }

    this.rowId = [this.entity.Id].join('-');
    this.$dataLoaded();
    // mark the whole form as dirty, since this is a new row 
    // and even if it is valid, it should have a changed state
    this.formGroup.markAsDirty();
  }

  private $unsubscribe$ = new Subject();
  override destroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  $init() {
  }

  async $dataLoad() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.utils;
    const $resultKey = this.entity.Id;
    const inParams = {
      $keys:[$resultKey],
      id:  0 ,
    };
    const data = await this.datasources.app.custom_ds_excel_sales_order_tracking_import_grid.getByKeys(inParams);
    this.entity = data.result[0];
    await this.$dataLoaded();
  }

  async $dataLoaded() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.utils;
    
    

  }

  override async refresh() {
    await this.$dataLoad();
    // grid refresh skipSelf=true, skipParent=false, skipChildren=false  
    this.grid.$refreshEvent.emit();
  }

  override async save() {
    if (this.isNew) {
      this.isNew = false;
    } else {
    } 
  }

  override $cellClicked(cellId: string) {
    super.$cellClicked(cellId);
    switch(cellId) {
    }
  }

  //#region private flows
  on_init_new_row(event = null) {
    return this.on_init_new_rowInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_init_new_rowInternal(
    $row: app_custom_excel_sales_order_tracking_import_gridComponentRowModel,
  $grid: app_custom_excel_sales_order_tracking_import_gridComponent, 
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
  
  
  
  
  
  
  $row.entity = {
    //  Id: nextId
  };
  
  
  
  
  
  
  
  
  }
  //#endregion private flows

}


@Component({
  standalone: true,
  imports: [
    SharedModule,
  ],
  selector: 'app-custom_excel_sales_order_tracking_import_grid',
  templateUrl: './app.custom_excel_sales_order_tracking_import_grid.component.html'
})
export class app_custom_excel_sales_order_tracking_import_gridComponent extends BaseComponent implements OnInit, OnDestroy {
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  entities: Iapp_custom_excel_sales_order_tracking_import_gridComponentEntity[];

  pageSize = 10;
  pageSkip = 0;
  totalCount = 0;
  loadingStatus = ELoadingStatus.Loading;

canEdit: boolean = true;
// to stop add from flow
// canAdd = false; //hide add button, check on exitFromLastCell 
// bottomToolbar.addLine.hidden = true; //hide add button, no check on exitFromLastCell 
// bottomToolbar.addLine.control.readOnly = true; //disable add button, check on exitFromLastCell 
canAdd: boolean = true; 
  
  containerStyles: GridContainerStyle = new GridContainerStyle('cellsWidth', ['compact','fit-content-table']);




  //#region Variables
  //#endregion
  //#region Events
  
  //#endregion

  headers = {
     orderId: new GridHeaderModel(new HeaderStyles(null, null), 'Order', false, false, null),       shipmentId: new GridHeaderModel(new HeaderStyles(null, null), 'shipmentId', false, false, null),       owner_reference: new GridHeaderModel(new HeaderStyles(null, null), 'Owner reference', true, false, null),       tracking: new GridHeaderModel(new HeaderStyles(null, null), 'Tracking', false, false, null),       error: new GridHeaderModel(new HeaderStyles(null, null), 'Errors', false, false, null),  
  };

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  rows: app_custom_excel_sales_order_tracking_import_gridComponentRowModel[] = [];
  @ViewChild('$gridComponent', { read:  GridComponent}) $gridComponent: GridComponent;


  topToolbar = {
      linecount: new ToolModel(new ButtonModel('linecount', new ButtonStyles(null, null), true, 'LineCount', '')
    )
  };

  bottomToolbar = {
    addLine : new ToolModel(new ButtonModel(null, null, false, 'Add row', 'icon-ic_fluent_add_circle_20_regular')),
  };



  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    private shell: app_ShellService,
    private datasources: app_DatasourceService,
    private flows: app_FlowService,
    private reports: app_ReportService,
    private localization: app_LocalizationService,
    private operations: app_OperationService,
    private logger: CleanupLoggerService,
    ) {
    super();
    this.title = 'Tracking Import';
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$init();
  }
  


  initialized = false;

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();

    this.clearRows();
  }

  async $init() {
    const $grid = this;
    const $utils = this.utils;


    await this.on_init();
    this.initialized = true;

    this.pageSkip = 0;
    this.refresh(true, true, null);
  }

  protected override $initEmpty() {
    this.clearRows();
  }

  private $subscribeFormControlValueChanges() {

  }

  reload() {
    this.pageSkip = 0;
    this.refresh();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();

    // children
    if (skipChildren === false) {
    }

    return result;
  }

  $rowPropertyChangeCallback (source: GridRowModel, property: string): void {
    if (property === 'selected') {
      this.$gridComponent.updateAllSelected();
    }
  }

  async $dataLoad() {
    this.loadingStatus = ELoadingStatus.Loading;
    const $grid = this;
    const $utils = this.utils;
    const inParams = {
      $top: this.pageSize,
      $skip: this.pageSkip,
      id:  0 ,
    };
    try {
    const data = await this.datasources.app.custom_ds_excel_sales_order_tracking_import_grid.getList(inParams);
      this.entities = data.result;
      this.totalCount = data.totalCount;
      await this.$dataLoaded();
      if (this.totalCount === 0) {
        this.loadingStatus = ELoadingStatus.NoResults;
      } else {
        this.loadingStatus = ELoadingStatus.Loaded;
      }
    } catch(error) {
      console.error("Error loading data:", error);
      this.loadingStatus = ELoadingStatus.Error;
    }
  }

  async $dataLoaded() {
    const $grid = this;
    const $utils = this.utils;
    
    this.clearRows();

    if(this.entities) {
      let rowLoadPromises = [];
      for (let entity of this.entities) {
        const row = new app_custom_excel_sales_order_tracking_import_gridComponentRowModel(
          this.utils,
          this.settings,
          this.shell, 
          this.datasources,
          this.flows,
          this.reports,
          this.localization,
          this.operations,
          this.logger);
        rowLoadPromises.push( row.$initializeExisting(this, entity, this.$rowPropertyChangeCallback.bind(this)));
        this.rows.push(row);
      }
      await Promise.all(rowLoadPromises);
    }

  }

  clearRows() {
    if(this.rows && this.rows.length) {
      this.rows.forEach(r => r.destroy());
    }
    this.rows = [];
  }


  async addRow(entity?: Iapp_custom_excel_sales_order_tracking_import_gridComponentEntity) {
    const row = new app_custom_excel_sales_order_tracking_import_gridComponentRowModel(
      this.utils,
      this.settings,
      this.shell, 
      this.datasources,
      this.flows,
      this.reports,
      this.localization,
      this.operations,
      this.logger);
    await row.$initializeNew(this, entity, this.$rowPropertyChangeCallback.bind(this));
    this.rows.push(row);
    row.setEditMode();
    return row;
  }
  
  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }

  //#region private flows
  on_excel_export(event = null) {
    return this.on_excel_exportInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_excel_exportInternal(
    $grid: app_custom_excel_sales_order_tracking_import_gridComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
  const wb = $event;
  
  const projects = [];
  
  projects.push({
  
  OwnerReference: 'Required',	
  Tracking: 'Required'
  });
  
  
  const ws = $utils.excel.json_to_sheet(projects);
  
  $utils.excel.book_append_sheet(wb, ws, 'TrackingImport');
   
     
  
  }
  on_excel_import(event = null) {
    return this.on_excel_importInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_excel_importInternal(
    $grid: app_custom_excel_sales_order_tracking_import_gridComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
  
  
  const wb = $event;
  const wsname = wb.SheetNames[0];
  const ws = wb.Sheets[wsname];
  
  $grid.topToolbar.linecount.hidden = false;
  $grid.topToolbar.linecount.control.icon = "icon ms-Icon ms-Icon--ProgressRingDots";
  $grid.topToolbar.linecount.control.label = 'Extracting Data..';
  
  const Orders = $utils.excel.sheet_to_json<{
      OwnerReference: string,
      Tracking: string
  
  }>(ws);
  
  $grid.topToolbar.linecount.control.label = 'Preparing Data...';
  
  // Initialize grid and data
  const candidates = [];
  var linecount = 0;
  var confirmLineCount = 0;
  var orderCheckLineCount = 0;
  var totalLineCount = Orders.length;
  
  
  
  for (const Order of Orders) {
  
  
      linecount = linecount + 1;
      $grid.topToolbar.linecount.control.label = 'Validating Line ' + linecount.toString() + ' of ' + totalLineCount.toString();
  
      var errors = '';
  
  
      const row = await $grid.addRow({
  
      })
  
      var ownerReference = Order.OwnerReference?.toString();
      var tracking = Order.Tracking?.toString();
  
  
      row.cells.owner_reference.editControl.value = ownerReference;
      row.cells.owner_reference.displayControl.text = ownerReference;
      row.cells.tracking.editControl.value = tracking;
      row.cells.tracking.displayControl.text = tracking;
  
  
      const orderShipment = (await $datasources.custom_ds_get_sales_order_by_owner_reference.get({
          ownerReference: ownerReference
      })).result;
  
      if ($utils.isDefined(orderShipment)) {
  
          if (orderShipment.length > 1) {
              errors += 'More than one current sales order found for the provided owner reference. '
          } else {
  
              row.cells.orderId.editControl.value = orderShipment[0]?.Id;
              row.cells.shipmentId.editControl.value = orderShipment[0]?.ShipmentOrderLookups[0]?.ShipmentId;
  
              row.cells.orderId.displayControl.text = orderShipment[0]?.Id?.toString();
              row.cells.shipmentId.displayControl.text = orderShipment[0]?.ShipmentOrderLookups[0]?.ShipmentId?.toString();
          }
  
      }
      else {
  
          errors += 'Unable to determine order from the provided owner reference.'
      }
  
  
      row.cells.owner_reference.editControl.readOnly = true;
      row.cells.tracking.editControl.readOnly = true;
      row.cells.orderId.editControl.readOnly = true;
      row.cells.shipmentId.editControl.readOnly = true;
  
  
  
  
      $grid.topToolbar.linecount.control.label = 'Validated Line ' + linecount.toString() + ' of ' + totalLineCount.toString();
  
      row.cells.error.displayControl.text = errors;
  
      candidates.push({ row: row, errors: errors });
  
  
  }
  
  
  
  $grid.topToolbar.linecount.control.label = 'Updating Grid...'
  var totalConfirmLineCount = candidates.length;
  
  for (const candidate of candidates) {
      const noErrors = candidate.errors === '';
      if (noErrors) {
  
          try {
  
              confirmLineCount = confirmLineCount + 1;
              $grid.topToolbar.linecount.control.label = 'Updating order ' + confirmLineCount.toString() + ' of ' + totalConfirmLineCount.toString();
  
              let payload: any = {};
              let shipmentId = candidate.row.cells.shipmentId.editControl.value;
              if ($utils.isDefined(shipmentId)) {
  
                  payload.TrackingIdentifier = candidate.row.cells.tracking.editControl.value
  
                  await $flows.Utilities.crud_update_flow({ entitySet: 'Shipments', id: shipmentId, entity: payload });
  
              }
  
  
  
             // candidate.row.refresh();
              candidate.row.setDisplayMode();
              candidate.row.isNew = false;
  
              candidate.row.cells.owner_reference.editControl.readOnly = true;
              candidate.row.cells.tracking.editControl.readOnly = true;
              candidate.row.cells.orderId.editControl.readOnly = true;
              candidate.row.cells.shipmentId.editControl.readOnly = true;
  
  
          }
  
          catch (error) {
  
  
              errors += 'Error updating order'
              candidate.row.cells.error.displayControl.text = errors;
  
              $grid.topToolbar.linecount.hidden = true;
              throw error; // to prevent displayMode 
          }
      }
  
  }
  
  $grid.topToolbar.linecount.hidden = true;
  
  
  
  }
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $grid: app_custom_excel_sales_order_tracking_import_gridComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
  
  
  $grid.topToolbar.linecount.hidden = true;
  
  
  $grid.headers.shipmentId.hidden = true;
  
  
  }
  //#endregion private flows

  $importExcel(fileInput: HTMLInputElement) {
    if(fileInput.files?.length !== 1) {
      throw new Error('Cannot import multiple files');
    }
    const file = fileInput.files[0];
    if(file.size > 15 * 1000 * 1000) {
      throw new Error('Cannot import a file with size greater than 15MB');
    }
    const reader: FileReader = new FileReader();
    reader.onload = (e: ProgressEvent<FileReader>) => {
      const ab: ArrayBuffer = e.target?.result as ArrayBuffer;
      const wb: WorkBook = readExcelFile(ab);
      this.on_excel_import(wb);
    };
    reader.readAsArrayBuffer(file);
    fileInput.value = '';
  }

  async $exportExcel() {
    const wb: WorkBook = excelUtils.book_new();

    await this.on_excel_export(wb);

    writeExcelFile(wb, 'app_custom_excel_sales_order_tracking_import_gridComponent_export.xlsx');
  }
 
  close() {
    this.$finish.emit();
  }
}
