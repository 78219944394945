import { Inject, Injectable, Injector } from '@angular/core';

import { Discussions_FlowService } from './Discussions.flow.index';
import { BarcodeParsing_FlowService } from './BarcodeParsing.flow.index';
import { BarTender_FlowService } from './BarTender.flow.index';
import { Utilities_FlowService } from './Utilities.flow.index';
import { Addresses_FlowService } from './Addresses.flow.index';
import { AsnOrders_FlowService } from './AsnOrders.flow.index';
import { Attachments_FlowService } from './Attachments.flow.index';
import { Carriers_FlowService } from './Carriers.flow.index';
import { Cartonization_FlowService } from './Cartonization.flow.index';
import { DockAppointments_FlowService } from './DockAppointments.flow.index';
import { EntityImport_FlowService } from './EntityImport.flow.index';
import { Equipment_FlowService } from './Equipment.flow.index';
import { ExcelInventoryImport_FlowService } from './ExcelInventoryImport.flow.index';
import { ExcelLocationImport_FlowService } from './ExcelLocationImport.flow.index';
import { ExcelMaterialImport_FlowService } from './ExcelMaterialImport.flow.index';
import { ExcelOrderImport_FlowService } from './ExcelOrderImport.flow.index';
import { ExcelProjectImport_FlowService } from './ExcelProjectImport.flow.index';
import { Inspections_FlowService } from './Inspections.flow.index';
import { Instructions_FlowService } from './Instructions.flow.index';
import { InventoryCounts_FlowService } from './InventoryCounts.flow.index';
import { InventoryTransfers_FlowService } from './InventoryTransfers.flow.index';
import { LaborManagement_FlowService } from './LaborManagement.flow.index';
import { LoadContainers_FlowService } from './LoadContainers.flow.index';
import { Locations_FlowService } from './Locations.flow.index';
import { PrintNode_FlowService } from './PrintNode.flow.index';
import { Materials_FlowService } from './Materials.flow.index';
import { PackVerification_FlowService } from './PackVerification.flow.index';
import { PalletTransactions_FlowService } from './PalletTransactions.flow.index';
import { PurchaseOrders_FlowService } from './PurchaseOrders.flow.index';
import { Replenishments_FlowService } from './Replenishments.flow.index';
import { Returns_FlowService } from './Returns.flow.index';
import { SerialNumbers_FlowService } from './SerialNumbers.flow.index';
import { ShippingContainers_FlowService } from './ShippingContainers.flow.index';
import { Surveys_FlowService } from './Surveys.flow.index';
import { TemperatureReadings_FlowService } from './TemperatureReadings.flow.index';
import { TransloadOrders_FlowService } from './TransloadOrders.flow.index';
import { WarehouseTransfers_FlowService } from './WarehouseTransfers.flow.index';
import { Waves_FlowService } from './Waves.flow.index';
import { WorkOrders_FlowService } from './WorkOrders.flow.index';
import { Inventory_FlowService } from './Inventory.flow.index';
import { Notifications_FlowService } from './Notifications.flow.index';
import { Owners_FlowService } from './Owners.flow.index';
import { Invoices_FlowService } from './Invoices.flow.index';
import { SalesOrders_FlowService } from './SalesOrders.flow.index';
import { Lots_FlowService } from './Lots.flow.index';
import { Manifesting_FlowService } from './Manifesting.flow.index';

import { FootPrintManager_get_integration_info_by_order_idService } from './FootPrintManager.flow.index';
import { FootPrintManager_get_inventory_count_status_flow_altService } from './FootPrintManager.flow.index';
import { FootPrintManager_main_auto_email_execute_rules_flowService } from './FootPrintManager.flow.index';
import { FootPrintManager_reason_codes_create_update_delete_flowService } from './FootPrintManager.flow.index';

import { $frontendTypes } from './FootPrintManager.frontend.types'

@Injectable({ providedIn: 'root' })
export class FootPrintManager_FlowService {

  constructor(
    private injector: Injector
  ) {
    this.Discussions = this.injector.get(Discussions_FlowService);
    this.BarcodeParsing = this.injector.get(BarcodeParsing_FlowService);
    this.BarTender = this.injector.get(BarTender_FlowService);
    this.Utilities = this.injector.get(Utilities_FlowService);
    this.Addresses = this.injector.get(Addresses_FlowService);
    this.AsnOrders = this.injector.get(AsnOrders_FlowService);
    this.Attachments = this.injector.get(Attachments_FlowService);
    this.Carriers = this.injector.get(Carriers_FlowService);
    this.Cartonization = this.injector.get(Cartonization_FlowService);
    this.DockAppointments = this.injector.get(DockAppointments_FlowService);
    this.EntityImport = this.injector.get(EntityImport_FlowService);
    this.Equipment = this.injector.get(Equipment_FlowService);
    this.ExcelInventoryImport = this.injector.get(ExcelInventoryImport_FlowService);
    this.ExcelLocationImport = this.injector.get(ExcelLocationImport_FlowService);
    this.ExcelMaterialImport = this.injector.get(ExcelMaterialImport_FlowService);
    this.ExcelOrderImport = this.injector.get(ExcelOrderImport_FlowService);
    this.ExcelProjectImport = this.injector.get(ExcelProjectImport_FlowService);
    this.Inspections = this.injector.get(Inspections_FlowService);
    this.Instructions = this.injector.get(Instructions_FlowService);
    this.InventoryCounts = this.injector.get(InventoryCounts_FlowService);
    this.InventoryTransfers = this.injector.get(InventoryTransfers_FlowService);
    this.LaborManagement = this.injector.get(LaborManagement_FlowService);
    this.LoadContainers = this.injector.get(LoadContainers_FlowService);
    this.Locations = this.injector.get(Locations_FlowService);
    this.PrintNode = this.injector.get(PrintNode_FlowService);
    this.Materials = this.injector.get(Materials_FlowService);
    this.PackVerification = this.injector.get(PackVerification_FlowService);
    this.PalletTransactions = this.injector.get(PalletTransactions_FlowService);
    this.PurchaseOrders = this.injector.get(PurchaseOrders_FlowService);
    this.Replenishments = this.injector.get(Replenishments_FlowService);
    this.Returns = this.injector.get(Returns_FlowService);
    this.SerialNumbers = this.injector.get(SerialNumbers_FlowService);
    this.ShippingContainers = this.injector.get(ShippingContainers_FlowService);
    this.Surveys = this.injector.get(Surveys_FlowService);
    this.TemperatureReadings = this.injector.get(TemperatureReadings_FlowService);
    this.TransloadOrders = this.injector.get(TransloadOrders_FlowService);
    this.WarehouseTransfers = this.injector.get(WarehouseTransfers_FlowService);
    this.Waves = this.injector.get(Waves_FlowService);
    this.WorkOrders = this.injector.get(WorkOrders_FlowService);
    this.Inventory = this.injector.get(Inventory_FlowService);
    this.Notifications = this.injector.get(Notifications_FlowService);
    this.Owners = this.injector.get(Owners_FlowService);
    this.Invoices = this.injector.get(Invoices_FlowService);
    this.SalesOrders = this.injector.get(SalesOrders_FlowService);
    this.Lots = this.injector.get(Lots_FlowService);
    this.Manifesting = this.injector.get(Manifesting_FlowService);
  }

    public Discussions: Discussions_FlowService;
    public BarcodeParsing: BarcodeParsing_FlowService;
    public BarTender: BarTender_FlowService;
    public Utilities: Utilities_FlowService;
    public Addresses: Addresses_FlowService;
    public AsnOrders: AsnOrders_FlowService;
    public Attachments: Attachments_FlowService;
    public Carriers: Carriers_FlowService;
    public Cartonization: Cartonization_FlowService;
    public DockAppointments: DockAppointments_FlowService;
    public EntityImport: EntityImport_FlowService;
    public Equipment: Equipment_FlowService;
    public ExcelInventoryImport: ExcelInventoryImport_FlowService;
    public ExcelLocationImport: ExcelLocationImport_FlowService;
    public ExcelMaterialImport: ExcelMaterialImport_FlowService;
    public ExcelOrderImport: ExcelOrderImport_FlowService;
    public ExcelProjectImport: ExcelProjectImport_FlowService;
    public Inspections: Inspections_FlowService;
    public Instructions: Instructions_FlowService;
    public InventoryCounts: InventoryCounts_FlowService;
    public InventoryTransfers: InventoryTransfers_FlowService;
    public LaborManagement: LaborManagement_FlowService;
    public LoadContainers: LoadContainers_FlowService;
    public Locations: Locations_FlowService;
    public PrintNode: PrintNode_FlowService;
    public Materials: Materials_FlowService;
    public PackVerification: PackVerification_FlowService;
    public PalletTransactions: PalletTransactions_FlowService;
    public PurchaseOrders: PurchaseOrders_FlowService;
    public Replenishments: Replenishments_FlowService;
    public Returns: Returns_FlowService;
    public SerialNumbers: SerialNumbers_FlowService;
    public ShippingContainers: ShippingContainers_FlowService;
    public Surveys: Surveys_FlowService;
    public TemperatureReadings: TemperatureReadings_FlowService;
    public TransloadOrders: TransloadOrders_FlowService;
    public WarehouseTransfers: WarehouseTransfers_FlowService;
    public Waves: Waves_FlowService;
    public WorkOrders: WorkOrders_FlowService;
    public Inventory: Inventory_FlowService;
    public Notifications: Notifications_FlowService;
    public Owners: Owners_FlowService;
    public Invoices: Invoices_FlowService;
    public SalesOrders: SalesOrders_FlowService;
    public Lots: Lots_FlowService;
    public Manifesting: Manifesting_FlowService;
  public FootPrintManager: FootPrintManager_FlowService = this;

  // injecting lazily in order to avoid circular dependencies
 
  private _get_integration_info_by_order_id: FootPrintManager_get_integration_info_by_order_idService;
  public async get_integration_info_by_order_id(inParams: { order_id?: number }): Promise< { Source?: string, IntegrationHubId?: string, IntegrationHubSourceId?: string, IntegrationHubSourceLookupCode?: string, IntegrationHubSourcePlatform?: string, WavelengthConnectorTransmissions?: { Id?: number, CreatedSysDateTime?: string, CreatedSysUser?: string, Direction?: string, IntegrationId?: string, ModifiedSysDateTime?: string, ModifiedSysUser?: string, Notes?: string, TransactionId?: string, Type?: string, Entity?: string }[] }> {
    if(!this._get_integration_info_by_order_id) {
      this._get_integration_info_by_order_id = this.injector.get(FootPrintManager_get_integration_info_by_order_idService);
    }
    return this._get_integration_info_by_order_id.run(inParams);
  }
   
   

   
 
  private _get_inventory_count_status_flow_alt: FootPrintManager_get_inventory_count_status_flow_altService;
  public async get_inventory_count_status_flow_alt(inParams: { inventoryCountIds: number[], optimalInputs?: { inventoryCountId?: number, tasks?: { operationCodeId?: number, statusId?: number }[], statusId?: number, statusName?: string }[] }): Promise< { inventoryCounts?: { id?: number, actualStatusId?: number, actualStatusName?: string, descriptiveStatusName?: string, actionValidations?: { failReleaseReasons?: string[], failHoldReasons?: string[], failDuplicateReasons?: string[], failCompleteReasons?: string[], failCancelReasons?: string[], failDeleteReasons?: string[], allowEdit?: boolean } }[], errors?: string[] }> {
    if(!this._get_inventory_count_status_flow_alt) {
      this._get_inventory_count_status_flow_alt = this.injector.get(FootPrintManager_get_inventory_count_status_flow_altService);
    }
    return this._get_inventory_count_status_flow_alt.run(inParams);
  }
   
   

   
 
  private _main_auto_email_execute_rules_flow: FootPrintManager_main_auto_email_execute_rules_flowService;
  public async main_auto_email_execute_rules_flow(inParams: {  }): Promise< { logs?: string[] }> {
    if(!this._main_auto_email_execute_rules_flow) {
      this._main_auto_email_execute_rules_flow = this.injector.get(FootPrintManager_main_auto_email_execute_rules_flowService);
    }
    return this._main_auto_email_execute_rules_flow.run(inParams);
  }
   
   

   
 
  private _reason_codes_create_update_delete_flow: FootPrintManager_reason_codes_create_update_delete_flowService;
  public async reason_codes_create_update_delete_flow(inParams: { payload?: any, action: string, id?: number }): Promise< { success?: boolean, message?: string, id?: number }> {
    if(!this._reason_codes_create_update_delete_flow) {
      this._reason_codes_create_update_delete_flow = this.injector.get(FootPrintManager_reason_codes_create_update_delete_flowService);
    }
    return this._reason_codes_create_update_delete_flow.run(inParams);
  }
   
   

   
}
