import { Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { ReportBaseService } from './report-base.service';

import { UtilsService } from './utils.service';
import { Reports_DatasourceService } from './Reports.datasource.index';

interface IReports_custom_so_reportServiceInParams {
  orderId: number}

interface IReports_custom_so_reportServiceData {
  custom_pil_so_report_header?: { result?: { Id?: number, CreatedSysDateTime?: string, CreatedSysUser?: string, Notes?: string, OwnerReference?: string, RequestedDeliveryDate?: string, VendorReference?: string, ShipmentOrderLookups?: { OrderId?: number, ShipmentId?: number, Shipment?: { Id?: number, Carrier?: { Name?: string } } }[], Project?: { Name?: string, Owner?: { Name?: string } }, shipToAddress?: { AttentionOf?: string, FirstName?: string, LastName?: string, Line1?: string, Line2?: string, City?: string, State?: string, PostalCode?: string, Country?: string, PrimaryTelephone?: string, OrderId?: number, accountName?: string, accountLookupcode?: string } }[] }
  custom_pil_so_report_details?: { result?: { materialID?: number, packagedAmount?: number, lineNumber?: number, notes?: string, establishmentIdentifier?: string, marks?: string, lotId?: number, totalCost?: number, price?: number, packaging?: string, OrderId?: number, PackagedId?: number, MaterialId?: number, materialDetails?: { Id?: number, ControllerTypeId?: number, Description?: string, IsFixedLength?: boolean, IsFixedVolume?: boolean, IsFixedWeight?: boolean, LookupCode?: string, ProjectId?: number }, lotDetails?: { Id?: number, LastReceiveDate?: string, LookupCode?: string, MaterialId?: number, Notes?: string, VendorLotId?: number, VendorLot?: { ExpirationDate?: string, LookupCode?: string, ManufactureDate?: string, Vat?: string }, Status?: { Id?: number, Name?: string }, Material?: { LookupCode?: string, MixingRuleId?: number, ProjectId?: number, Project?: { LookupCode?: string, OwnerId?: number }, StorageCategoryRule?: { Id?: number, Name?: string } } } }[] }
}

@Injectable({ providedIn: 'root' })
export class Reports_custom_so_reportService extends ReportBaseService<IReports_custom_so_reportServiceInParams, IReports_custom_so_reportServiceData> {

  protected reportReferenceName = 'custom_so_report';
  protected appReferenceName = 'Reports';

  constructor(
    utils: UtilsService,
    private datasources: Reports_DatasourceService,
    ) {
      super(utils);
  }

  override throwIfMissingRequiredInParams (inParams: IReports_custom_so_reportServiceInParams) {
  }

  protected async getData(inParams: IReports_custom_so_reportServiceInParams): Promise<IReports_custom_so_reportServiceData> 
  {
    const $utils = this.utils;
    const $report: { inParams: IReports_custom_so_reportServiceInParams } = {
      inParams: inParams
    };

    const data: IReports_custom_so_reportServiceData = {
    };
    const datasourcesQueries: (() => Promise<void>)[] = [];

    datasourcesQueries.push(async () => {
      const dsParams = {
        orderId:  $report.inParams.orderId 
      };
      
      const dsData = await this.datasources.Reports.custom_ds_so_report_header.get(dsParams);
      
      data.custom_pil_so_report_header = dsData;
    });
    datasourcesQueries.push(async () => {
      const dsParams = {
        orderId:  $report.inParams.orderId 
      };
      
      const dsData = await this.datasources.Reports.custom_ds_so_report_details.get(dsParams);
      
      data.custom_pil_so_report_details = dsData;
    });

    await Promise.all(datasourcesQueries.map(t => t()));

    return data;
  }
}
