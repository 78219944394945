<div class="blade-wrapper">
  <div class="blade-header">
    <div *ngIf="initialized && $hasDataLoaded && !$hasMissingRequiredInParams" class="blade-tools">
        <app-toolbar [toolbar]="toolbar">
            <ng-template toolbarToolDef="confirm" let-tool>
              <button mat-button
                      data-cy="button"
                      class="datex-button"
                      [disabled]="tool.control.readOnly"
                      [ngStyle]="tool.control.styles.style"
                      [ngClass]="tool.control.styles.classes"
                      (click)="on_confirm_clicked($event)"
                      >
                <div class="button-label">
                  <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                    <div class="button-icon">
                      <i data-cy="button-icon" class="datex-default-spinner"></i>
                    </div>
                  </ng-container>
                  <ng-template #showIcon>
                    <div class="button-icon">
                      <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                    </div>
                  </ng-template>
                  <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                </div>
              
              </button>
            </ng-template>
            <ng-template toolbarToolDef="cancel" let-tool>
              <button mat-button
                      data-cy="button"
                      class="datex-button"
                      [disabled]="tool.control.readOnly"
                      [ngStyle]="tool.control.styles.style"
                      [ngClass]="tool.control.styles.classes"
                      (click)="on_cancel_clicked($event)"
                      >
                <div class="button-label">
                  <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                    <div class="button-icon">
                      <i data-cy="button-icon" class="datex-default-spinner"></i>
                    </div>
                  </ng-container>
                  <ng-template #showIcon>
                    <div class="button-icon">
                      <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                    </div>
                  </ng-template>
                  <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                </div>
              
              </button>
            </ng-template>
        </app-toolbar>
    </div>
    <ng-content></ng-content>
  </div>
  <div class="blade-content">
    <div class="datex-editor">
      <ng-container *ngIf="initialized && $hasDataLoaded && !$hasMissingRequiredInParams">
        <div [formGroup]="formGroup"
             class="formdata">
                <div data-cy="fieldset-id-newGroup1"
                     *ngIf="!fieldsets.newGroup1.hidden"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.newGroup1.collapsible }">
                    <div *ngIf="!fieldsets.newGroup1.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.newGroup1.toggle()">
                      <span class="fieldsetsTitle-text"></span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.newGroup1.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.newGroup1.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.newGroup1.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.newGroup1.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-name" *ngIf="!fields.name.hidden" 
                            class="field-container full {{fields.name.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.name.styles.style"
                            [ngClass]="fields.name.styles.classes">
                        <div class="label-container"
                              title="{{fields.name.label}}{{fields.name.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.name.label}}">{{fields.name.label}}<span *ngIf="fields.name.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <input data-cy="textBox"formControlName="name"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{fields.name.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.name.control.placeholder}}"
                                [ngStyle]="fields.name.control.styles.style"
                                [ngClass]="fields.name.control.styles.classes"> 
                        <ng-container *ngIf="fields.name.invalid">
                          <ng-container *ngFor="let error of fields.name.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-status" *ngIf="!fields.status.hidden" 
                            class="field-container full {{fields.status.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.status.styles.style"
                            [ngClass]="fields.status.styles.classes">
                        <div class="label-container"
                              title="{{fields.status.label}}{{fields.status.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.status.label}}">{{fields.status.label}}<span *ngIf="fields.status.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <Locations-location_statuses_dd_single 
                            data-cy="selector"
                            [type]="fields.status.control.type"
                            formControlName="status"
                            (displayTextChange)="fields.status.control.displayText=$event"
                            [placeholder]="fields.status.control.placeholder"
                            [styles]="fields.status.control.styles"
                        >
                        </Locations-location_statuses_dd_single>
                        <ng-container *ngIf="fields.status.invalid">
                          <ng-container *ngFor="let error of fields.status.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-eligible_for_allocation" *ngIf="!fields.eligible_for_allocation.hidden" 
                            class="field-container full {{fields.eligible_for_allocation.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.eligible_for_allocation.styles.style"
                            [ngClass]="fields.eligible_for_allocation.styles.classes">
                        <div class="label-container"
                              title="{{fields.eligible_for_allocation.label}}{{fields.eligible_for_allocation.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.eligible_for_allocation.label}}">{{fields.eligible_for_allocation.label}}<span *ngIf="fields.eligible_for_allocation.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <mat-checkbox data-cy="checkBox" formControlName="eligible_for_allocation"
                                      class="datex-checkbox"
                                      color="primary"
                                      [ngStyle]="fields.eligible_for_allocation.control.styles.style"
                                      [ngClass]="fields.eligible_for_allocation.control.styles.classes">{{fields.eligible_for_allocation.control.label}}</mat-checkbox>
                        <ng-container *ngIf="fields.eligible_for_allocation.invalid">
                          <ng-container *ngFor="let error of fields.eligible_for_allocation.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-notes" *ngIf="!fields.notes.hidden" 
                            class="field-container full {{fields.notes.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.notes.styles.style"
                            [ngClass]="fields.notes.styles.classes">
                        <div class="label-container"
                              title="{{fields.notes.label}}{{fields.notes.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.notes.label}}">{{fields.notes.label}}<span *ngIf="fields.notes.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <textarea cdkTextareaAutosize cdkAutosizeMinRows="3" cdkAutosizeMaxRows="5" data-cy="textBox-multi"formControlName="notes"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{fields.notes.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.notes.control.placeholder}}"
                                [ngStyle]="fields.notes.control.styles.style"
                                [ngClass]="fields.notes.control.styles.classes"> </textarea>
                        <ng-container *ngIf="fields.notes.invalid">
                          <ng-container *ngFor="let error of fields.notes.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-picking_sequence" *ngIf="!fields.picking_sequence.hidden" 
                            class="field-container double {{fields.picking_sequence.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.picking_sequence.styles.style"
                            [ngClass]="fields.picking_sequence.styles.classes">
                        <div class="label-container"
                              title="{{fields.picking_sequence.label}}{{fields.picking_sequence.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.picking_sequence.label}}">{{fields.picking_sequence.label}}<span *ngIf="fields.picking_sequence.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <input data-cy="numberBox" formControlName="picking_sequence"
                                matInput
                                numberBox
                                [format]="fields.picking_sequence.control.format"
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-numberbox {{fields.picking_sequence.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.picking_sequence.control.placeholder}}"
                                [ngStyle]="fields.picking_sequence.control.styles.style"
                                [ngClass]="fields.picking_sequence.control.styles.classes">
                        <ng-container *ngIf="fields.picking_sequence.invalid">
                          <ng-container *ngFor="let error of fields.picking_sequence.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-length" *ngIf="!fields.length.hidden" 
                            class="field-container full {{fields.length.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.length.styles.style"
                            [ngClass]="fields.length.styles.classes">
                        <div class="label-container"
                              title="{{fields.length.label}}{{fields.length.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.length.label}}">{{fields.length.label}}<span *ngIf="fields.length.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <input data-cy="numberBox" formControlName="length"
                                matInput
                                numberBox
                                [format]="fields.length.control.format"
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-numberbox {{fields.length.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.length.control.placeholder}}"
                                [ngStyle]="fields.length.control.styles.style"
                                [ngClass]="fields.length.control.styles.classes">
                        <ng-container *ngIf="fields.length.invalid">
                          <ng-container *ngFor="let error of fields.length.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-width" *ngIf="!fields.width.hidden" 
                            class="field-container full {{fields.width.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.width.styles.style"
                            [ngClass]="fields.width.styles.classes">
                        <div class="label-container"
                              title="{{fields.width.label}}{{fields.width.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.width.label}}">{{fields.width.label}}<span *ngIf="fields.width.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <input data-cy="numberBox" formControlName="width"
                                matInput
                                numberBox
                                [format]="fields.width.control.format"
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-numberbox {{fields.width.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.width.control.placeholder}}"
                                [ngStyle]="fields.width.control.styles.style"
                                [ngClass]="fields.width.control.styles.classes">
                        <ng-container *ngIf="fields.width.invalid">
                          <ng-container *ngFor="let error of fields.width.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-height" *ngIf="!fields.height.hidden" 
                            class="field-container full {{fields.height.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.height.styles.style"
                            [ngClass]="fields.height.styles.classes">
                        <div class="label-container"
                              title="{{fields.height.label}}{{fields.height.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.height.label}}">{{fields.height.label}}<span *ngIf="fields.height.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <input data-cy="numberBox" formControlName="height"
                                matInput
                                numberBox
                                [format]="fields.height.control.format"
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-numberbox {{fields.height.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.height.control.placeholder}}"
                                [ngStyle]="fields.height.control.styles.style"
                                [ngClass]="fields.height.control.styles.classes">
                        <ng-container *ngIf="fields.height.invalid">
                          <ng-container *ngFor="let error of fields.height.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-dimension_uom" *ngIf="!fields.dimension_uom.hidden" 
                            class="field-container full {{fields.dimension_uom.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.dimension_uom.styles.style"
                            [ngClass]="fields.dimension_uom.styles.classes">
                        <div class="label-container"
                              title="{{fields.dimension_uom.label}}{{fields.dimension_uom.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.dimension_uom.label}}">{{fields.dimension_uom.label}}<span *ngIf="fields.dimension_uom.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <Locations-measurement_units_dd_single 
                            data-cy="selector"
                            [type]="fields.dimension_uom.control.type"
                            formControlName="dimension_uom"
                            (displayTextChange)="fields.dimension_uom.control.displayText=$event"
                            [placeholder]="fields.dimension_uom.control.placeholder"
                            [styles]="fields.dimension_uom.control.styles"
                          [typeId]="$fields_dimension_uom_selector_inParams_typeId"
                        >
                        </Locations-measurement_units_dd_single>
                        <ng-container *ngIf="fields.dimension_uom.invalid">
                          <ng-container *ngFor="let error of fields.dimension_uom.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-weight_capacity" *ngIf="!fields.weight_capacity.hidden" 
                            class="field-container full {{fields.weight_capacity.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.weight_capacity.styles.style"
                            [ngClass]="fields.weight_capacity.styles.classes">
                        <div class="label-container"
                              title="{{fields.weight_capacity.label}}{{fields.weight_capacity.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.weight_capacity.label}}">{{fields.weight_capacity.label}}<span *ngIf="fields.weight_capacity.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <input data-cy="numberBox" formControlName="weight_capacity"
                                matInput
                                numberBox
                                [format]="fields.weight_capacity.control.format"
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-numberbox {{fields.weight_capacity.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.weight_capacity.control.placeholder}}"
                                [ngStyle]="fields.weight_capacity.control.styles.style"
                                [ngClass]="fields.weight_capacity.control.styles.classes">
                        <ng-container *ngIf="fields.weight_capacity.invalid">
                          <ng-container *ngFor="let error of fields.weight_capacity.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-weight_uom" *ngIf="!fields.weight_uom.hidden" 
                            class="field-container full {{fields.weight_uom.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.weight_uom.styles.style"
                            [ngClass]="fields.weight_uom.styles.classes">
                        <div class="label-container"
                              title="{{fields.weight_uom.label}}{{fields.weight_uom.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.weight_uom.label}}">{{fields.weight_uom.label}}<span *ngIf="fields.weight_uom.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <Locations-measurement_units_dd_single 
                            data-cy="selector"
                            [type]="fields.weight_uom.control.type"
                            formControlName="weight_uom"
                            (displayTextChange)="fields.weight_uom.control.displayText=$event"
                            [placeholder]="fields.weight_uom.control.placeholder"
                            [styles]="fields.weight_uom.control.styles"
                          [typeId]="$fields_weight_uom_selector_inParams_typeId"
                        >
                        </Locations-measurement_units_dd_single>
                        <ng-container *ngIf="fields.weight_uom.invalid">
                          <ng-container *ngFor="let error of fields.weight_uom.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
                  </div>
                </div>
              </div>

      </ng-container>

      <div *ngIf="$hasMissingRequiredInParams" class="missing-params">
        <h3>Please provide {{$missingRequiredInParams.toString()}}</h3>
      </div>
      <div *ngIf="initialized && !$hasDataLoaded && !$hasMissingRequiredInParams" class="missing-params">
        <h3>No data to display</h3>
      </div>
    </div>
  </div>
</div>