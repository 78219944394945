import { Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { CleanupLoggerService } from './cleanup.logging.service';
import { ReportBaseService } from './report-base.service';

import { UtilsService } from './utils.service';
import { WarehouseTransfers_DatasourceService } from './WarehouseTransfers.datasource.index';

interface IWarehouseTransfers_outbound_bill_of_lading_reportServiceInParams {
  orderId: number}

interface IWarehouseTransfers_outbound_bill_of_lading_reportServiceData {
  Header?: { result?: { Id?: number, CreatedSysUser?: string, LookupCode?: string, OwnerReference?: string, RequestedDeliveryDate?: string, VendorReference?: string, ShipmentOrderLookups?: { ShipmentId?: number, Shipment?: { BillOfLading?: string, GrossWeight?: number, ItemCount?: number, PalletCount?: number, SealId?: string, ShippedDate?: string, TrackingIdentifier?: string, TrailerId?: string } }[], PreferredCarrier?: { Name?: string }, PreferredWarehouse?: { Name?: string, WarehousesContactsLookup?: { ContactId?: number, Contact?: { PrimaryTelephone?: string, Address?: { AttentionOf?: string, City?: string, Country?: string, Line1?: string, PostalCode?: string, State?: string } } }[] }, Project?: { Name?: string, ProjectsContactsLookup?: { ContactId?: number, Contact?: { Id?: number, Address?: { City?: string, Line1?: string, Line2?: string, PostalCode?: string, State?: string } } }[] }, ShipToAddress?: { AttentionOf?: string, FirstName?: string, LastName?: string, Line1?: string, Line2?: string, City?: string, State?: string, PostalCode?: string, Country?: string, PrimaryTelephone?: string, OrderId?: number } }[] }
  Details?: { result?: { ShipmentLineId?: number, LicensePlateId?: number, LotId?: number, PackagedId?: number, PackagedAmount?: number, Lot?: { LookupCode?: string, MaterialId?: number, Material?: { Description?: string, LookupCode?: string } }, Packaged?: { ShortName?: string }, FixedWeights?: { MaterialId?: number, PackagingId?: number, BasePackagingId?: number, BasePackagingQuantity?: number, ShippingVolume?: number, ShippingWeight?: number, Volume?: number, VolumeUomId?: number, Weight?: number, WeightUomId?: number } }[] }
}

@Injectable({ providedIn: 'root' })
export class WarehouseTransfers_outbound_bill_of_lading_reportService extends ReportBaseService<IWarehouseTransfers_outbound_bill_of_lading_reportServiceInParams, IWarehouseTransfers_outbound_bill_of_lading_reportServiceData> {

  protected reportReferenceName = 'outbound_bill_of_lading_report';
  protected appReferenceName = 'WarehouseTransfers';

  constructor(
    utils: UtilsService,
    private datasources: WarehouseTransfers_DatasourceService,
    private logger: CleanupLoggerService
    ) {
      super(utils);
      logger.log('WarehouseTransfers', 'outbound_bill_of_lading_report');
  }

  override throwIfMissingRequiredInParams (inParams: IWarehouseTransfers_outbound_bill_of_lading_reportServiceInParams) {
  }

  protected async getData(inParams: IWarehouseTransfers_outbound_bill_of_lading_reportServiceInParams): Promise<IWarehouseTransfers_outbound_bill_of_lading_reportServiceData> 
  {
    const $utils = this.utils;
    const $report: { inParams: IWarehouseTransfers_outbound_bill_of_lading_reportServiceInParams } = {
      inParams: inParams
    };

    const data: IWarehouseTransfers_outbound_bill_of_lading_reportServiceData = {
    };
    const datasourcesQueries: (() => Promise<void>)[] = [];

    datasourcesQueries.push(async () => {
      const dsParams = {
        orderId:  $report.inParams.orderId 
      };
      
      const dsData = await this.datasources.WarehouseTransfers.ds_outbound_header_bill_of_lading_report.get(dsParams);
      
      data.Header = dsData;
    });
    datasourcesQueries.push(async () => {
      const dsParams = {
        orderId:  $report.inParams.orderId 
      };
      
      const dsData = await this.datasources.WarehouseTransfers.ds_outbound_details_bill_of_lading_report.get(dsParams);
      
      data.Details = dsData;
    });

    await Promise.all(datasourcesQueries.map(t => t()));

    return data;
  }
}
