import { Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { ReportBaseService } from './report-base.service';

import { UtilsService } from './utils.service';
import { Reports_DatasourceService } from './Reports.datasource.index';

interface IReports_custom_purchase_order_reportServiceInParams {
  orderId: number}

interface IReports_custom_purchase_order_reportServiceData {
  Header?: { result?: { Id?: number, CreatedSysDateTime?: string, LookupCode?: string, OwnerReference?: string, RequestedDeliveryDate?: string, VendorReference?: string, ShipmentOrderLookups?: { OrderId?: number, ShipmentId?: number, Shipment?: { LookupCode?: string, Notes?: string, PickupDate?: string, SealId?: string, TrailerId?: string, Carrier?: { Name?: string, ScacCode?: string, ShortName?: string } } }[], Project?: { LookupCode?: string, Name?: string }, PreferredWarehouse?: { Name?: string } } }
  Lines?: { result?: { OrderLines?: { LineNumber?: number, PackagedAmount?: number, ActualPackagedAmount?: number, Packaging?: string, Material_LookupCode?: string, Material_Description?: string, Material_ControllerTypeId?: number, Lot_LookupCode?: string, VendorLot_LookupCode?: string, VendorLot_ExpirationDate?: string, VendorLot_ManufactureDate?: string, TotalGrossWeight?: number, TotalPackagedAmount?: number, Price?: number, TotalPrice?: number }[] } }
  WarehouseContact?: { result?: { FirstName?: string, LastName?: string, Line1?: string, Line2?: string, City?: string, State?: string, PostalCode?: string, Country?: string, Email?: string, Phone?: string } }
  OwnerContact?: { result?: { FirstName?: string, LastName?: string, Line1?: string, Line2?: string, City?: string, State?: string, PostalCode?: string, Country?: string } }
  AccountContact?: { result?: { AttentionOf?: string, FirstName?: string, LastName?: string, Line1?: string, Line2?: string, City?: string, State?: string, PostalCode?: string, Country?: string, PrimaryTelephone?: string, OrderId?: number, Email?: string } }
}

@Injectable({ providedIn: 'root' })
export class Reports_custom_purchase_order_reportService extends ReportBaseService<IReports_custom_purchase_order_reportServiceInParams, IReports_custom_purchase_order_reportServiceData> {

  protected reportReferenceName = 'custom_purchase_order_report';
  protected appReferenceName = 'Reports';

  constructor(
    utils: UtilsService,
    private datasources: Reports_DatasourceService,
    ) {
      super(utils);
  }

  override throwIfMissingRequiredInParams (inParams: IReports_custom_purchase_order_reportServiceInParams) {
  }

  protected async getData(inParams: IReports_custom_purchase_order_reportServiceInParams): Promise<IReports_custom_purchase_order_reportServiceData> 
  {
    const $utils = this.utils;
    const $report: { inParams: IReports_custom_purchase_order_reportServiceInParams } = {
      inParams: inParams
    };

    const data: IReports_custom_purchase_order_reportServiceData = {
    };
    const datasourcesQueries: (() => Promise<void>)[] = [];

    datasourcesQueries.push(async () => {
      const dsParams = {
        orderId:  $report.inParams.orderId 
      };
      
      const dsData = await this.datasources.Reports.ds_purchase_order_header_report.get(dsParams);
      
      data.Header = dsData;
    });
    datasourcesQueries.push(async () => {
      const dsParams = {
        orderId:  $report.inParams.orderId 
      };
      
      const dsData = await this.datasources.Reports.ds_purchase_order_details_report_flow_based.get(dsParams);
      
      data.Lines = dsData;
    });
    datasourcesQueries.push(async () => {
      const dsParams = {
        orderId:  $report.inParams.orderId 
      };
      
      const dsData = await this.datasources.Reports.ds_get_order_warehouse_contact_top1_flow_based.get(dsParams);
      
      data.WarehouseContact = dsData;
    });
    datasourcesQueries.push(async () => {
      const dsParams = {
        orderId:  $report.inParams.orderId 
      };
      
      const dsData = await this.datasources.Reports.ds_get_order_owner_contact_top1_flow_based.get(dsParams);
      
      data.OwnerContact = dsData;
    });
    datasourcesQueries.push(async () => {
      const dsParams = {
        orderId:  $report.inParams.orderId 
      };
      
      const dsData = await this.datasources.Reports.ds_get_order_ship_from_account_vs_order_address.get(dsParams);
      
      data.AccountContact = dsData;
    });

    await Promise.all(datasourcesQueries.map(t => t()));

    return data;
  }
}
