import { Component, OnInit, Input, SimpleChanges, OnChanges, EventEmitter, Output, ViewChild, Inject, OnDestroy, forwardRef } from '@angular/core';
import { FormGroup, FormControl, Validators} from '@angular/forms';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles 
} from './models/control';
import { GridHeaderModel, GridCellModel, GridRowModel, CellStyles, GridContainerStyle, HeaderStyles } from './models/grid'
import { FieldModel } from './models/field'
import { ToolModel } from './models/tool';
import { Styles, ControlContainerStyles } from './models/style';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { isEqual, isNil, set } from 'lodash-es';
import { GridComponent, ELoadingStatus } from './components/grid.component';
import { BaseComponent } from './components/base.component';
import { WorkBook, read as readExcelFile, writeFile as writeExcelFile, utils as excelUtils } from 'xlsx';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootPrintManager_ShellService, EModalSize, EToasterType, EToasterPosition } from './FootPrintManager.shell.service';
import { FootPrintManager_OperationService } from './FootPrintManager.operation.service';
import { FootPrintManager_DatasourceService } from './FootPrintManager.datasource.index';
import { FootPrintManager_FlowService } from './FootPrintManager.flow.index';
import { FootPrintManager_ReportService } from './FootPrintManager.report.index';
import { FootPrintManager_LocalizationService } from './FootPrintManager.localization.service';
import { Language } from './localization.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './FootPrintManager.frontend.types'
import { $frontendTypes as $types} from './FootPrintManager.frontend.types' 

import { Materials_materials_by_project_dd_singleComponent } from './Materials.materials_by_project_dd_single.component'
import { Lots_vendorlots_dd_singleComponent } from './Lots.vendorlots_dd_single.component'
import { Lots_lots_dd_singleComponent } from './Lots.lots_dd_single.component'
import { Materials_material_packagings_dd_singleComponent } from './Materials.material_packagings_dd_single.component'
import { Inventory_licenseplate_by_inventory_dd_singleComponent } from './Inventory.licenseplate_by_inventory_dd_single.component'
import { Inventory_serialnumbers_by_inventory_dd_singleComponent } from './Inventory.serialnumbers_by_inventory_dd_single.component'


interface IFootPrintManager_inventory_transfer_lines_gridComponentEntity {
Id?: number, AllowInactiveInventory?: boolean, ErrorMessage?: string, SourcePackageAmount?: number, SourceMaterial?: { Id?: number, LookupCode?: string }, SourceVendorLot?: { Id?: number, LookupCode?: string }, SourceLot?: { Id?: number, LookupCode?: string }, SourcePackage?: { Id?: number, ShortName?: string }, SourceLicensePlate?: { Id?: number, LookupCode?: string }, SourceSerialNumber?: { Id?: number, LookupCode?: string }, TargetMaterial?: { Id?: number, LookupCode?: string }, Warehouse?: { Id?: number, Name?: string }, Status?: { Id?: number, Name?: string }}

interface IFootPrintManager_inventory_transfer_lines_gridComponentInParams {
  inventoryTransferId: number, sourceProjectId?: number, targetProjectId?: number, warehouseId?: number, can_update?: boolean, can_delete?: boolean}


class FootPrintManager_inventory_transfer_lines_gridComponentRowModel extends GridRowModel {
  grid: FootPrintManager_inventory_transfer_lines_gridComponent;
  entity: IFootPrintManager_inventory_transfer_lines_gridComponentEntity;

  vars: { can_delete?: boolean, can_update?: boolean } = { };


 // temporarily put required on all edit controls
  override formGroup = new FormGroup({
    source_material_edit: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'change' }),
    source_vendorlot_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    source_lot_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    source_packaging_edit: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'change' }),
    source_packaged_amount_edit: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'change' }),
    source_licenseplate_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    source_serial_number_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    target_material_id_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    allow_inactive_inventory_display: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    allow_inactive_inventory_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
  });

  override cells = {
    line_number: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    status: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    state: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    error_message: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    source_material: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
      new SelectBoxModel(
        this.formGroup.controls['source_material_edit'] as DatexFormControl, 
        ESelectBoxType.dropdown, null,
        false, 
        '')
      ),
    source_vendorlot: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
      new SelectBoxModel(
        this.formGroup.controls['source_vendorlot_edit'] as DatexFormControl, 
        ESelectBoxType.dropdown, null,
        false, 
        '')
      ),
    source_lot: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
      new SelectBoxModel(
        this.formGroup.controls['source_lot_edit'] as DatexFormControl, 
        ESelectBoxType.dropdown, null,
        false, 
        '')
      ),
    source_packaging: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
      new SelectBoxModel(
        this.formGroup.controls['source_packaging_edit'] as DatexFormControl, 
        ESelectBoxType.dropdown, null,
        false, 
        '')
      ),
    source_packaged_amount: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
      new NumberBoxModel(this.formGroup.controls['source_packaged_amount_edit'] as DatexFormControl, null, false, '0', '')
      ),
    source_licenseplate: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
      new SelectBoxModel(
        this.formGroup.controls['source_licenseplate_edit'] as DatexFormControl, 
        ESelectBoxType.dropdown, null,
        false, 
        '')
      ),
    source_serial_number: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
      new SelectBoxModel(
        this.formGroup.controls['source_serial_number_edit'] as DatexFormControl, 
        null, null,
        false, 
        '')
      ),
    target_material_id: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
      new SelectBoxModel(
        this.formGroup.controls['target_material_id_edit'] as DatexFormControl, 
        ESelectBoxType.dropdown, null,
        false, 
        '')
      ),
    allow_inactive_inventory: new GridCellModel(
      new CellStyles(null, null),
      new CheckBoxModel(this.formGroup.controls['allow_inactive_inventory_display'] as DatexFormControl, null, true, '')
,
      new CheckBoxModel(this.formGroup.controls['allow_inactive_inventory_edit'] as DatexFormControl, null, false, '')
      ),
  }

  get $fields_source_material_selector_inParams_projectId(): number {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.utils;
    const $grid = this.grid;
    const expr = $grid.inParams.sourceProjectId;
    
    return expr;
  }
  get $fields_source_vendorlot_selector_inParams_materialId(): number {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.utils;
    const $grid = this.grid;
    const expr = $row.cells.source_material.editControl.value;
    
    return expr;
  }
  get $fields_source_vendorlot_selector_inParams_lotId(): number {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.utils;
    const $grid = this.grid;
    const expr = $row.cells.source_lot.editControl.value;
    
    return expr;
  }
  get $fields_source_lot_selector_inParams_materialId(): number {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.utils;
    const $grid = this.grid;
    const expr = $row.cells.source_material.editControl.value;
    
    return expr;
  }
  get $fields_source_lot_selector_inParams_vendorLotId(): number {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.utils;
    const $grid = this.grid;
    const expr = $row.cells.source_vendorlot.editControl.value;
    
    return expr;
  }
  get $fields_source_packaging_selector_inParams_materialId(): number {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.utils;
    const $grid = this.grid;
    const expr = $row.cells.source_material.editControl.value;
    
    return expr;
  }
  get $fields_source_licenseplate_selector_inParams_lotId(): number {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.utils;
    const $grid = this.grid;
    const expr = $row.cells.source_lot.editControl.value;
    
    return expr;
  }
  get $fields_source_licenseplate_selector_inParams_packagingId(): number {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.utils;
    const $grid = this.grid;
    const expr = $row.cells.source_packaging.editControl.value;
    
    return expr;
  }
  get $fields_source_licenseplate_selector_inParams_warehouseId(): number {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.utils;
    const $grid = this.grid;
    const expr = $grid.inParams.warehouseId;
    
    return expr;
  }
  get $fields_source_serial_number_selector_inParams_lotId(): number {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.utils;
    const $grid = this.grid;
    const expr = $row.cells.source_lot.editControl.value;
    
    return expr;
  }
  get $fields_source_serial_number_selector_inParams_packagingId(): number {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.utils;
    const $grid = this.grid;
    const expr = $row.cells.source_packaging.editControl.value;
    
    return expr;
  }
  get $fields_source_serial_number_selector_inParams_warehouseId(): number {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.utils;
    const $grid = this.grid;
    const expr = $grid.inParams.warehouseId;
    
    return expr;
  }
  get $fields_source_serial_number_selector_inParams_licenseplateId(): number {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.utils;
    const $grid = this.grid;
    const expr = $row.cells.source_licenseplate.editControl.value;
    
    return expr;
  }
  get $fields_target_material_id_selector_inParams_projectId(): number {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.utils;
    const $grid = this.grid;
    const expr = $grid.inParams.targetProjectId;
    
    return expr;
  }


  constructor(
    private utils: UtilsService,
private settings: SettingsValuesService,
private shell: FootPrintManager_ShellService,
private datasources: FootPrintManager_DatasourceService,
private flows: FootPrintManager_FlowService,
private reports: FootPrintManager_ReportService,
private localization: FootPrintManager_LocalizationService,
private operations: FootPrintManager_OperationService,
private logger: CleanupLoggerService,
) {
    super();
    
    this.formGroup
      .controls['source_material_edit']
      .valueChanges
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_material_changed();
      });
  }

  async $initializeExisting(grid: FootPrintManager_inventory_transfer_lines_gridComponent, entity: IFootPrintManager_inventory_transfer_lines_gridComponentEntity, propertyChangeCallback: (source: GridRowModel, property: string) => void = null) {
    this.$propertyChangeCallback = propertyChangeCallback;

    this.grid = grid;
    this.entity = entity;

    this.rowId = [this.entity.Id].join('-');

    await this.$dataLoaded();
  }

  async $initializeNew(grid: FootPrintManager_inventory_transfer_lines_gridComponent, entity?: IFootPrintManager_inventory_transfer_lines_gridComponentEntity, propertyChangeCallback: (source: GridRowModel, property: string) => void = null) {
    this.$propertyChangeCallback = propertyChangeCallback;

    this.grid = grid;
    this.isNew = true;

    if (entity) {
      this.entity = entity;
    }
    else {
      this.entity = {};
    }

    this.rowId = [this.entity.Id].join('-');
    this.$dataLoaded();
    // mark the whole form as dirty, since this is a new row 
    // and even if it is valid, it should have a changed state
    this.formGroup.markAsDirty();
  }

  private $unsubscribe$ = new Subject();
  override destroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  $init() {
  }

  async $dataLoad() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.utils;
    const $resultKey = this.entity.Id;
    const inParams = {
      $keys:[$resultKey],
      inventoryTransferId:  $grid.inParams.inventoryTransferId ,
    };
    const data = await this.datasources.InventoryTransfers.ds_inventory_transfer_lines_grid.getByKeys(inParams);
    this.entity = data.result[0];
    await this.$dataLoaded();
  }

  async $dataLoaded() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.utils;
    (this.cells.status.displayControl as TextModel).text = $row.entity?.Status?.Name;
    (this.cells.error_message.displayControl as TextModel).text = $row.entity?.ErrorMessage;
    (this.cells.source_material.displayControl as TextModel).text = $row.entity?.SourceMaterial?.LookupCode;
    (this.cells.source_material.editControl as SelectBoxModel).reset($row.entity?.SourceMaterial?.Id);
    (this.cells.source_vendorlot.displayControl as TextModel).text = $row.entity?.SourceVendorLot?.LookupCode;
    (this.cells.source_vendorlot.editControl as SelectBoxModel).reset($row.entity?.SourceVendorLot?.Id);
    (this.cells.source_lot.displayControl as TextModel).text = $row.entity?.SourceLot?.LookupCode;
    (this.cells.source_lot.editControl as SelectBoxModel).reset($row.entity?.SourceLot?.Id);
    (this.cells.source_packaging.displayControl as TextModel).text = $row.entity?.SourcePackage?.ShortName;
    (this.cells.source_packaging.editControl as SelectBoxModel).reset($row.entity?.SourcePackage?.Id);
    (this.cells.source_packaged_amount.displayControl as TextModel).text = $row.entity?.SourcePackageAmount?.toString();
    (this.cells.source_packaged_amount.editControl as NumberBoxModel).reset($row.entity?.SourcePackageAmount);
    (this.cells.source_licenseplate.displayControl as TextModel).text = $row.entity?.SourceLicensePlate?.LookupCode;
    (this.cells.source_licenseplate.editControl as SelectBoxModel).reset($row.entity?.SourceLicensePlate?.Id);
    (this.cells.source_serial_number.displayControl as TextModel).text = $row.entity?.SourceSerialNumber?.LookupCode;
    (this.cells.source_serial_number.editControl as SelectBoxModel).reset($row.entity?.SourceSerialNumber?.Id);
    (this.cells.target_material_id.displayControl as TextModel).text = $row.entity?.TargetMaterial?.LookupCode;
    (this.cells.target_material_id.editControl as SelectBoxModel).reset($row.entity?.TargetMaterial?.Id);
    (this.cells.allow_inactive_inventory.displayControl as CheckBoxModel).reset($row.entity?.AllowInactiveInventory);
    (this.cells.allow_inactive_inventory.editControl as CheckBoxModel).reset($row.entity?.AllowInactiveInventory);

  }

  override async refresh() {
    if (this.grid.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    await this.$dataLoad();
    // grid refresh skipSelf=true, skipParent=false, skipChildren=false  
    this.grid.$refreshEvent.emit();
  }

  override async save() {
    if (this.isNew) {
      await this.on_save_new_row();
      this.isNew = false;
    } else {
    } 
  }

  override $cellClicked(cellId: string) {
    super.$cellClicked(cellId);
    switch(cellId) {
    }
  }

  //#region private flows
  on_save_new_row(event = null) {
    return this.on_save_new_rowInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_save_new_rowInternal(
    $row: FootPrintManager_inventory_transfer_lines_gridComponentRowModel,
  $grid: FootPrintManager_inventory_transfer_lines_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
    this.logger.log('FootPrintManager', 'inventory_transfer_lines_grid.on_save_new_row');
  try {
      // Check for missing inputs
      let missingInputs: string[] = [];
      if (!$utils.isDefined($grid.inParams.warehouseId)) {
          missingInputs.push(`warehouse`);
      }
  
      if ($utils.isDefined(missingInputs)) {
          throw new Error(`Please choose a ${missingInputs.join(', ')}`);
      }
  
      // Create line
      let result = (await $flows.InventoryTransfers.create_inventory_transfer_lines_flow({
          inventoryTransferId: $grid.inParams.inventoryTransferId,
          lines: [{
              materialId: $row.cells.source_material.editControl.value,
              vendorLotId: $row.cells.source_vendorlot.editControl.value,
              lotId: $row.cells.source_lot.editControl.value,
              packagingId: $row.cells.source_packaging.editControl.value,
              packagedAmount: $row.cells.source_packaged_amount.editControl.value,
              licenseplateId: $row.cells.source_licenseplate.editControl.value,
              serialnumberId: $row.cells.source_serial_number.editControl.value,
              locationId: null,
              warehouseId: $grid.inParams.warehouseId
          }]
      }));
  
      if ($utils.isDefined(result?.reasons)) {
          throw new Error((await $flows.Utilities.grammar_format_string_array_flow({ values: result.reasons})).formattedValue);
      }
  
      $row.entity.Id = result.inventoryTransferLineIds[0];
      await $row.refresh();
  }
  catch (error) {
      while ($utils.isDefined(error.error)) {
          error = error.error;
      }
  
      await $shell.FootPrintManager.openErrorDialog('Error creating inventory transfer line', error.message);
      throw new Error(error.message);
  }
  }
  on_material_changed(event = null) {
    return this.on_material_changedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_material_changedInternal(
    $row: FootPrintManager_inventory_transfer_lines_gridComponentRowModel,
  $grid: FootPrintManager_inventory_transfer_lines_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
    this.logger.log('FootPrintManager', 'inventory_transfer_lines_grid.on_material_changed');
  var isGlobalLot = false;
  var isSinglePackaging = false;
  
  if ($utils.isDefined($row.cells.source_material.editControl.value)) {
      checkGlobalLot();
      checkSinglePackaging();
  } else {
      $row.cells.source_vendorlot.editControl.value = null;
      $row.cells.source_lot.editControl.value = null;
      $row.cells.source_packaging.editControl.value = null;
      $row.cells.source_licenseplate.editControl.value = null;
      $row.cells.source_serial_number.editControl.value = null;
  }
  
  
  /******************************************
   * FUNCTIONS
  *******************************************/
  async function checkGlobalLot() {
      let lotCheck = (await $datasources.Lots.ds_get_lots_by_materialId.getList({ materialId: $row.cells.source_material.editControl.value, $top: 1 })).result[0];
  
      if ($utils.isDefined(lotCheck) && lotCheck.TypeId === 1) {
          $row.cells.source_vendorlot.editControl.value = lotCheck.VendorLotId;
          $row.cells.source_lot.editControl.value = lotCheck.Id;
  
          $row.cells.source_vendorlot.editControl.readOnly = true;
          $row.cells.source_lot.editControl.readOnly = true;
  
          if (isSinglePackaging) {
              $row.cells.source_packaged_amount.editControl.focus();
          }
      }
      else {
          $row.cells.source_vendorlot.editControl.value = null;
          $row.cells.source_lot.editControl.value = null;
  
          $row.cells.source_vendorlot.editControl.readOnly = false;
          $row.cells.source_lot.editControl.readOnly = false;
      }
  }
  
  async function checkSinglePackaging() {
      let packagingResponse = (await $datasources.Materials.ds_get_material_packagings_by_materialId.getList({ materialId: $row.cells.source_material.editControl.value, $top: 1 }));
  
      if ($utils.isDefined(packagingResponse) && packagingResponse.totalCount === 1) {
          $row.cells.source_packaging.editControl.value = packagingResponse.result[0].PackagingId;
  
          $row.cells.source_packaging.editControl.readOnly = true;
  
          if (isGlobalLot) {
              $row.cells.source_packaged_amount.editControl.focus();
          }
      }
      else {
          $row.cells.source_packaging.editControl.value = null;
  
          $row.cells.source_packaging.editControl.readOnly = false;
      }
  }
  }
  //#endregion private flows

}


@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => Materials_materials_by_project_dd_singleComponent),
    forwardRef(() => Lots_vendorlots_dd_singleComponent),
    forwardRef(() => Lots_lots_dd_singleComponent),
    forwardRef(() => Materials_material_packagings_dd_singleComponent),
    forwardRef(() => Inventory_licenseplate_by_inventory_dd_singleComponent),
    forwardRef(() => Inventory_serialnumbers_by_inventory_dd_singleComponent),
  ],
  selector: 'FootPrintManager-inventory_transfer_lines_grid',
  templateUrl: './FootPrintManager.inventory_transfer_lines_grid.component.html'
})
export class FootPrintManager_inventory_transfer_lines_gridComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy {
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  entities: IFootPrintManager_inventory_transfer_lines_gridComponentEntity[];

  pageSize = 50;
  pageSkip = 0;
  totalCount = 0;
  loadingStatus = ELoadingStatus.Loading;

canEdit: boolean = true;
// to stop add from flow
// canAdd = false; //hide add button, check on exitFromLastCell 
// bottomToolbar.addLine.hidden = true; //hide add button, no check on exitFromLastCell 
// bottomToolbar.addLine.control.readOnly = true; //disable add button, check on exitFromLastCell 
canAdd: boolean = true; 
  
  containerStyles: GridContainerStyle = new GridContainerStyle('cellsWidth', ['default','fit-content-table']);


  inParams: IFootPrintManager_inventory_transfer_lines_gridComponentInParams = { inventoryTransferId: null, sourceProjectId: null, targetProjectId: null, warehouseId: null, can_update: null, can_delete: null };


  //#region Variables
  //#endregion
  //#region Events
  
  //#endregion

  headers = {
     line_number: new GridHeaderModel(new HeaderStyles(null, null), 'Line', false, false, null),       status: new GridHeaderModel(new HeaderStyles(null, null), 'Status', false, false, null),       state: new GridHeaderModel(new HeaderStyles(null, null), 'State', false, false, null),       error_message: new GridHeaderModel(new HeaderStyles(null, null), 'Error', false, false, null),       source_material: new GridHeaderModel(new HeaderStyles(null, null), 'Material', true, false, null),       source_vendorlot: new GridHeaderModel(new HeaderStyles(null, null), 'Vendor lot', false, false, null),       source_lot: new GridHeaderModel(new HeaderStyles(null, null), 'Lot', false, false, null),       source_packaging: new GridHeaderModel(new HeaderStyles(null, null), 'Packaging', true, false, null),       source_packaged_amount: new GridHeaderModel(new HeaderStyles(null, null), 'Packaged amount', true, false, null),       source_licenseplate: new GridHeaderModel(new HeaderStyles(null, null), 'License plate', false, false, null),       source_serial_number: new GridHeaderModel(new HeaderStyles(null, null), 'Serial number', false, false, null),       target_material_id: new GridHeaderModel(new HeaderStyles(null, null), 'Target material', false, false, null),       allow_inactive_inventory: new GridHeaderModel(new HeaderStyles(null, null), 'Allow inactive', false, false, null),  
  };

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  rows: FootPrintManager_inventory_transfer_lines_gridComponentRowModel[] = [];
  @ViewChild('$gridComponent', { read:  GridComponent}) $gridComponent: GridComponent;

  @Input('inventoryTransferId') set $inParams_inventoryTransferId(value: any) {
    this.inParams['inventoryTransferId'] = value;
  }
  get $inParams_inventoryTransferId(): any {
    return this.inParams['inventoryTransferId'] ;
  }
  @Input('sourceProjectId') set $inParams_sourceProjectId(value: any) {
    this.inParams['sourceProjectId'] = value;
  }
  get $inParams_sourceProjectId(): any {
    return this.inParams['sourceProjectId'] ;
  }
  @Input('targetProjectId') set $inParams_targetProjectId(value: any) {
    this.inParams['targetProjectId'] = value;
  }
  get $inParams_targetProjectId(): any {
    return this.inParams['targetProjectId'] ;
  }
  @Input('warehouseId') set $inParams_warehouseId(value: any) {
    this.inParams['warehouseId'] = value;
  }
  get $inParams_warehouseId(): any {
    return this.inParams['warehouseId'] ;
  }
  @Input('can_update') set $inParams_can_update(value: any) {
    this.inParams['can_update'] = value;
  }
  get $inParams_can_update(): any {
    return this.inParams['can_update'] ;
  }
  @Input('can_delete') set $inParams_can_delete(value: any) {
    this.inParams['can_delete'] = value;
  }
  get $inParams_can_delete(): any {
    return this.inParams['can_delete'] ;
  }

  topToolbar = {
      delete_line: new ToolModel(new ButtonModel('delete_line', new ButtonStyles(['destructive'], null), false, 'Delete', 'ms-Icon ms-Icon--Delete')
    )
  };

  bottomToolbar = {
    addLine : new ToolModel(new ButtonModel(null, null, false, 'Add row', 'icon-ic_fluent_add_circle_20_regular')),
  };



  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    private shell: FootPrintManager_ShellService,
    private datasources: FootPrintManager_DatasourceService,
    private flows: FootPrintManager_FlowService,
    private reports: FootPrintManager_ReportService,
    private localization: FootPrintManager_LocalizationService,
    private operations: FootPrintManager_OperationService,
    private logger: CleanupLoggerService,
    ) {
    super();
    this.title = 'Inventory transfer lines';
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$checkRequiredInParams();
    if (!this.$hasMissingRequiredInParams) {
      this.$init();
    } else {
      this.$initEmpty();
    }
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$checkRequiredInParams();
      if(!this.$hasMissingRequiredInParams) {
        this.$init();
      } else {
        this.$initEmpty();
      }
    }
  }

  $missingRequiredInParams = [];
  get $hasMissingRequiredInParams(): boolean {
    return !!this.$missingRequiredInParams.length;
  }
  
  $checkRequiredInParams() {
    this.$missingRequiredInParams = [];
      if(isNil(this.inParams.inventoryTransferId)) {
        this.$missingRequiredInParams.push('inventoryTransferId');
      }
  }

  initialized = false;

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();

    this.clearRows();
  }

  async $init() {
    const $grid = this;
    const $utils = this.utils;


    await this.on_init();
    this.initialized = true;

    this.pageSkip = 0;
    this.refresh(true, true, null);
  }

  protected override $initEmpty() {
    this.clearRows();
  }

  private $subscribeFormControlValueChanges() {

  }

  reload() {
    this.pageSkip = 0;
    this.refresh();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    if (this.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();

    // children
    if (skipChildren === false) {
    }

    return result;
  }

  $rowPropertyChangeCallback (source: GridRowModel, property: string): void {
    if (property === 'selected') {
      this.$gridComponent.updateAllSelected();
    }
  }

  async $dataLoad() {
    this.loadingStatus = ELoadingStatus.Loading;
    const $grid = this;
    const $utils = this.utils;
    const inParams = {
      $top: this.pageSize,
      $skip: this.pageSkip,
      inventoryTransferId:  $grid.inParams.inventoryTransferId ,
    };
    try {
    const data = await this.datasources.InventoryTransfers.ds_inventory_transfer_lines_grid.getList(inParams);
      this.entities = data.result;
      this.totalCount = data.totalCount;
      await this.$dataLoaded();
      if (this.totalCount === 0) {
        this.loadingStatus = ELoadingStatus.NoResults;
      } else {
        this.loadingStatus = ELoadingStatus.Loaded;
      }
    } catch(error) {
      console.error("Error loading data:", error);
      this.loadingStatus = ELoadingStatus.Error;
    }
  }

  async $dataLoaded() {
    const $grid = this;
    const $utils = this.utils;
    
    this.clearRows();

    if(this.entities) {
      let rowLoadPromises = [];
      for (let entity of this.entities) {
        const row = new FootPrintManager_inventory_transfer_lines_gridComponentRowModel(
          this.utils,
          this.settings,
          this.shell, 
          this.datasources,
          this.flows,
          this.reports,
          this.localization,
          this.operations,
          this.logger);
        rowLoadPromises.push( row.$initializeExisting(this, entity, this.$rowPropertyChangeCallback.bind(this)));
        this.rows.push(row);
      }
      await Promise.all(rowLoadPromises);
    }

    await this.on_data_loaded();
  }

  clearRows() {
    if(this.rows && this.rows.length) {
      this.rows.forEach(r => r.destroy());
    }
    this.rows = [];
  }

  selectedRows = [];
  hasSelectedRows() {
    return this.selectedRows.length > 0;
  }

  $selectionChanged(selectedRows: any[]) {
    this.selectedRows = selectedRows;
    this.on_row_selected();
  }

  async addRow(entity?: IFootPrintManager_inventory_transfer_lines_gridComponentEntity) {
    const row = new FootPrintManager_inventory_transfer_lines_gridComponentRowModel(
      this.utils,
      this.settings,
      this.shell, 
      this.datasources,
      this.flows,
      this.reports,
      this.localization,
      this.operations,
      this.logger);
    await row.$initializeNew(this, entity, this.$rowPropertyChangeCallback.bind(this));
    this.rows.push(row);
    row.setEditMode();
    return row;
  }
  
  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }

  //#region private flows
  on_row_selected(event = null) {
    return this.on_row_selectedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_row_selectedInternal(
    $grid: FootPrintManager_inventory_transfer_lines_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
    this.logger.log('FootPrintManager', 'inventory_transfer_lines_grid.on_row_selected');
  $grid.topToolbar.delete_line.control.readOnly = $grid.selectedRows.length === 0;
  }
  on_delete_line_clicked(event = null) {
    return this.on_delete_line_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_delete_line_clickedInternal(
    $grid: FootPrintManager_inventory_transfer_lines_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
    this.logger.log('FootPrintManager', 'inventory_transfer_lines_grid.on_delete_line_clicked');
  let errors: {message: string, detail: string}[] = [];
  for (let row of $grid.selectedRows) {
      let result = await $flows.InventoryTransfers.delete_inventory_transfer_line_flow({ inventoryTransferLineId: row.entity.Id });
  
      if ($utils.isDefined(result.reasons)) {
          errors.push({ message: `Line: ${row.entity.Id}`, detail: (await $flows.Utilities.grammar_format_string_array_flow({ values: result.reasons })).formattedValue});
      }
  }
  
  if ($utils.isDefined(errors)) {
      await $shell.FootPrintManager.openErrorDialog(`Error deleting ${errors.length} of ${$grid.selectedRows.length} lines`, null,null, 'Errors', errors );
  } else {
      await $shell.FootPrintManager.openInfoDialog('Successfully deleted inventory transfer lines', null);
  }
  
  $grid.refresh();
  }
  on_data_loaded(event = null) {
    return this.on_data_loadedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_data_loadedInternal(
    $grid: FootPrintManager_inventory_transfer_lines_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
    this.logger.log('FootPrintManager', 'inventory_transfer_lines_grid.on_data_loaded');
  // Don't allow line modifications if missing inputs
  if (!$utils.isDefined($grid.inParams.sourceProjectId) || !$utils.isDefined($grid.inParams.targetProjectId) || !$utils.isDefined($grid.inParams.warehouseId)) {
      $grid.canAdd = false;
      $grid.canEdit = false;
  }
  
  let line_states = (await $flows.InventoryTransfers.get_inventory_transfer_lines_states({ inventoryTransferLineIds: $grid.rows.map(row => row.entity.Id)}));
  
  let has_errors = false;
  let has_separate_states = false;
  for (let line_info of line_states.lines) {
      let row = ($grid.rows.find(row => row.entity.Id === line_info.line.id));
  
      row.cells.line_number.displayControl.text = line_info.line.number.toString();
      row.cells.state.displayControl.text = line_info.status.state;
  
      row.vars.can_delete = !$utils.isDefined(line_info.failActions.deleteReasons);
      row.vars.can_update = !$utils.isDefined(line_info.failActions.updateReasons);
  
      if ($utils.isDefinedTrimmed(line_info.status.eventError)) {
          row.cells.error_message.displayControl.text += ' ' + line_info.status.eventError;
          has_errors = true;
      }
  
      if ($utils.isDefinedTrimmed(line_info.status.state) && line_info.status.state !== line_info.status.name) {
          has_separate_states = true;
      }
  }
  
  $grid.headers.error_message.hidden = !has_errors;
  $grid.headers.state.hidden = !has_separate_states;
  
  
  if (!$grid.inParams.can_delete) {
      $grid.topToolbar.delete_line.hidden = true;
  }
  if (!$grid.inParams.can_update) {
      $grid.canAdd = false;
      $grid.canEdit = false;
  }
  
  
  }
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $grid: FootPrintManager_inventory_transfer_lines_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
    this.logger.log('FootPrintManager', 'inventory_transfer_lines_grid.on_init');
  if (!$utils.isDefined($grid.inParams.can_delete)) {
      $grid.inParams.can_delete = true;
  }
  if (!$utils.isDefined($grid.inParams.can_update)) {
      $grid.inParams.can_update = true;
  }
  }
  //#endregion private flows


 
  close() {
    this.$finish.emit();
  }
}
