import { Injectable } from '@angular/core';
import { ParamMap } from '@angular/router';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { isNil, cloneDeep } from 'lodash-es';

import { ShellService, EModalSize, EToasterType, EToasterPosition } from './shell.service'
export { EModalSize, EToasterType, EToasterPosition } from './shell.service';
import { ToastrService } from 'ngx-toastr';
import { CleanupLoggerService } from './cleanup.logging.service';
import { TransloadOrders_transloadorders_library_homeComponent } from './TransloadOrders.transloadorders_library_home.component';
import { TransloadOrders_conveyances_dd_singleComponent } from './TransloadOrders.conveyances_dd_single.component';
import { TransloadOrders_orderclasses_dd_singleComponent } from './TransloadOrders.orderclasses_dd_single.component';
import { TransloadOrders_orderstatuses_dd_singleComponent } from './TransloadOrders.orderstatuses_dd_single.component';
import { TransloadOrders_transload_composites_by_transload_id_dd_singleComponent } from './TransloadOrders.transload_composites_by_transload_id_dd_single.component';
import { TransloadOrders_conveyances_dd_multiComponent } from './TransloadOrders.conveyances_dd_multi.component';
import { TransloadOrders_orderclasses_dd_multiComponent } from './TransloadOrders.orderclasses_dd_multi.component';
import { TransloadOrders_orderstatuses_dd_multiComponent } from './TransloadOrders.orderstatuses_dd_multi.component';
import { TransloadOrders_transload_composites_by_transload_id_dd_multiComponent } from './TransloadOrders.transload_composites_by_transload_id_dd_multi.component';
import { TransloadOrders_order_crossdocked_licenseplate_count_widgetComponent } from './TransloadOrders.order_crossdocked_licenseplate_count_widget.component';
import { TransloadOrders_order_expected_licenseplate_count_widgetComponent } from './TransloadOrders.order_expected_licenseplate_count_widget.component';
import { TransloadOrders_order_loaded_licenseplate_count_widgetComponent } from './TransloadOrders.order_loaded_licenseplate_count_widget.component';
import { TransloadOrders_order_putaway_licenseplate_count_widgetComponent } from './TransloadOrders.order_putaway_licenseplate_count_widget.component';
import { TransloadOrders_order_total_received_gross_by_date_widgetComponent } from './TransloadOrders.order_total_received_gross_by_date_widget.component';
import { TransloadOrders_order_total_received_order_count_by_date_widgetComponent } from './TransloadOrders.order_total_received_order_count_by_date_widget.component';
import { TransloadOrders_order_total_received_units_by_date_widgetComponent } from './TransloadOrders.order_total_received_units_by_date_widget.component';
import { TransloadOrders_orderline_total_gross_received_widgetComponent } from './TransloadOrders.orderline_total_gross_received_widget.component';
import { TransloadOrders_orderline_total_packaged_amount_widgetComponent } from './TransloadOrders.orderline_total_packaged_amount_widget.component';
import { TransloadOrders_orderline_total_received_widgetComponent } from './TransloadOrders.orderline_total_received_widget.component';

import { Utilities_ShellService } from './Utilities.shell.service';

@Injectable({ providedIn: 'root' })
export class TransloadOrders_ShellService extends ShellService  {
  constructor(
    dialog : MatDialog,
    toastr: ToastrService,
    private logger: CleanupLoggerService,
    public Utilities: Utilities_ShellService,
  ) {
    super(dialog, toastr);
  }

  public TransloadOrders: TransloadOrders_ShellService = this;

  // wizards shouldn't be opened in blades (hacky check with "#unless steps" to recognize the config type)
  public opentransloadorders_library_home(replaceCurrentView?: boolean) {
    this.logger.log('TransloadOrders', 'transloadorders_library_home');
    ShellService.openViewRequest$.next(
      {
        title: 'Home',
        referenceName: 'TransloadOrders_transloadorders_library_home',
        component: TransloadOrders_transloadorders_library_homeComponent,
        replaceCurrentView: replaceCurrentView,
        
      }
    );
  }

  public opentransloadorders_library_homeDialog(
    
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    this.logger.log('TransloadOrders', 'transloadorders_library_home');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      TransloadOrders_transloadorders_library_homeComponent,
      'Home',
      mode,
      dialogSize
    )
  }

  public getComponentInformation(referenceName: string, params: ParamMap): { title: string, component: any, inParams: any } {
    if (referenceName === 'TransloadOrders_transloadorders_library_home') {
      this.logger.log('TransloadOrders', 'transloadorders_library_home');
      const title = 'Home';
      const component = TransloadOrders_transloadorders_library_homeComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'TransloadOrders_conveyances_dd_single') {
      this.logger.log('TransloadOrders', 'conveyances_dd_single');
      const title = 'Conveyances';
      const component = TransloadOrders_conveyances_dd_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'TransloadOrders_orderclasses_dd_single') {
      this.logger.log('TransloadOrders', 'orderclasses_dd_single');
      const title = 'Orderclasses by transload order Dropdown.';
      const component = TransloadOrders_orderclasses_dd_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'TransloadOrders_orderstatuses_dd_single') {
      this.logger.log('TransloadOrders', 'orderstatuses_dd_single');
      const title = 'Order Statuses';
      const component = TransloadOrders_orderstatuses_dd_singleComponent;
      const inParams:{ statusIds?: number[] } = { statusIds: [] };
      if (!isNil(params.get('statusIds'))) {
        const paramValueString = params.get('statusIds');
        inParams.statusIds = this.convertToJSON(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'TransloadOrders_transload_composites_by_transload_id_dd_single') {
      this.logger.log('TransloadOrders', 'transload_composites_by_transload_id_dd_single');
      const title = 'Transload composite licenseplates';
      const component = TransloadOrders_transload_composites_by_transload_id_dd_singleComponent;
      const inParams:{ transload_id: number, excluded_shipment_id?: number } = { transload_id: null, excluded_shipment_id: null };
      if (!isNil(params.get('transload_id'))) {
        const paramValueString = params.get('transload_id');
        inParams.transload_id = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('excluded_shipment_id'))) {
        const paramValueString = params.get('excluded_shipment_id');
        inParams.excluded_shipment_id = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'TransloadOrders_conveyances_dd_multi') {
      this.logger.log('TransloadOrders', 'conveyances_dd_multi');
      const title = 'Conveyances';
      const component = TransloadOrders_conveyances_dd_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'TransloadOrders_orderclasses_dd_multi') {
      this.logger.log('TransloadOrders', 'orderclasses_dd_multi');
      const title = 'Orderclasses by transload order Dropdown.';
      const component = TransloadOrders_orderclasses_dd_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'TransloadOrders_orderstatuses_dd_multi') {
      this.logger.log('TransloadOrders', 'orderstatuses_dd_multi');
      const title = 'Order Statuses';
      const component = TransloadOrders_orderstatuses_dd_multiComponent;
      const inParams:{ statusIds?: number[] } = { statusIds: [] };
      if (!isNil(params.get('statusIds'))) {
        const paramValueString = params.get('statusIds');
        inParams.statusIds = this.convertToJSON(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'TransloadOrders_transload_composites_by_transload_id_dd_multi') {
      this.logger.log('TransloadOrders', 'transload_composites_by_transload_id_dd_multi');
      const title = 'Transload composite licenseplates';
      const component = TransloadOrders_transload_composites_by_transload_id_dd_multiComponent;
      const inParams:{ transload_id: number, excluded_shipment_id?: number } = { transload_id: null, excluded_shipment_id: null };
      if (!isNil(params.get('transload_id'))) {
        const paramValueString = params.get('transload_id');
        inParams.transload_id = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('excluded_shipment_id'))) {
        const paramValueString = params.get('excluded_shipment_id');
        inParams.excluded_shipment_id = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'TransloadOrders_order_crossdocked_licenseplate_count_widget') {
      this.logger.log('TransloadOrders', 'order_crossdocked_licenseplate_count_widget');
      const title = 'Cross-docked LPs';
      const component = TransloadOrders_order_crossdocked_licenseplate_count_widgetComponent;
      const inParams:{ shipmentIds: number[] } = { shipmentIds: [] };
      if (!isNil(params.get('shipmentIds'))) {
        const paramValueString = params.get('shipmentIds');
        inParams.shipmentIds = this.convertToJSON(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'TransloadOrders_order_expected_licenseplate_count_widget') {
      this.logger.log('TransloadOrders', 'order_expected_licenseplate_count_widget');
      const title = 'Expected LPs';
      const component = TransloadOrders_order_expected_licenseplate_count_widgetComponent;
      const inParams:{ orderId: number } = { orderId: null };
      if (!isNil(params.get('orderId'))) {
        const paramValueString = params.get('orderId');
        inParams.orderId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'TransloadOrders_order_loaded_licenseplate_count_widget') {
      this.logger.log('TransloadOrders', 'order_loaded_licenseplate_count_widget');
      const title = 'Loaded LPs';
      const component = TransloadOrders_order_loaded_licenseplate_count_widgetComponent;
      const inParams:{ shipmentIds: number[] } = { shipmentIds: [] };
      if (!isNil(params.get('shipmentIds'))) {
        const paramValueString = params.get('shipmentIds');
        inParams.shipmentIds = this.convertToJSON(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'TransloadOrders_order_putaway_licenseplate_count_widget') {
      this.logger.log('TransloadOrders', 'order_putaway_licenseplate_count_widget');
      const title = 'Putaway LP count';
      const component = TransloadOrders_order_putaway_licenseplate_count_widgetComponent;
      const inParams:{ shipmentId: number } = { shipmentId: null };
      if (!isNil(params.get('shipmentId'))) {
        const paramValueString = params.get('shipmentId');
        inParams.shipmentId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'TransloadOrders_order_total_received_gross_by_date_widget') {
      this.logger.log('TransloadOrders', 'order_total_received_gross_by_date_widget');
      const title = 'Recv gross today';
      const component = TransloadOrders_order_total_received_gross_by_date_widgetComponent;
      const inParams:{ date: string, ownerId?: number, projectId?: number, warehouseId?: number, orderStatusIds?: number[] } = { date: null, ownerId: null, projectId: null, warehouseId: null, orderStatusIds: [] };
      if (!isNil(params.get('date'))) {
        const paramValueString = params.get('date');
        // TODO: date
        inParams.date = paramValueString;
              }
      if (!isNil(params.get('ownerId'))) {
        const paramValueString = params.get('ownerId');
        inParams.ownerId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('warehouseId'))) {
        const paramValueString = params.get('warehouseId');
        inParams.warehouseId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('orderStatusIds'))) {
        const paramValueString = params.get('orderStatusIds');
        inParams.orderStatusIds = this.convertToJSON(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'TransloadOrders_order_total_received_order_count_by_date_widget') {
      this.logger.log('TransloadOrders', 'order_total_received_order_count_by_date_widget');
      const title = 'Recv orders today';
      const component = TransloadOrders_order_total_received_order_count_by_date_widgetComponent;
      const inParams:{ date: string, ownerId?: number, projectId?: number, warehouseId?: number, orderStatusIds?: number[] } = { date: null, ownerId: null, projectId: null, warehouseId: null, orderStatusIds: [] };
      if (!isNil(params.get('date'))) {
        const paramValueString = params.get('date');
        // TODO: date
        inParams.date = paramValueString;
              }
      if (!isNil(params.get('ownerId'))) {
        const paramValueString = params.get('ownerId');
        inParams.ownerId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('warehouseId'))) {
        const paramValueString = params.get('warehouseId');
        inParams.warehouseId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('orderStatusIds'))) {
        const paramValueString = params.get('orderStatusIds');
        inParams.orderStatusIds = this.convertToJSON(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'TransloadOrders_order_total_received_units_by_date_widget') {
      this.logger.log('TransloadOrders', 'order_total_received_units_by_date_widget');
      const title = 'Recv units today';
      const component = TransloadOrders_order_total_received_units_by_date_widgetComponent;
      const inParams:{ date: string, ownerId?: number, projectId?: number, warehouseId?: number, orderStatusIds?: number[] } = { date: null, ownerId: null, projectId: null, warehouseId: null, orderStatusIds: [] };
      if (!isNil(params.get('date'))) {
        const paramValueString = params.get('date');
        // TODO: date
        inParams.date = paramValueString;
              }
      if (!isNil(params.get('ownerId'))) {
        const paramValueString = params.get('ownerId');
        inParams.ownerId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('warehouseId'))) {
        const paramValueString = params.get('warehouseId');
        inParams.warehouseId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('orderStatusIds'))) {
        const paramValueString = params.get('orderStatusIds');
        inParams.orderStatusIds = this.convertToJSON(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'TransloadOrders_orderline_total_gross_received_widget') {
      this.logger.log('TransloadOrders', 'orderline_total_gross_received_widget');
      const title = 'Total gross recv';
      const component = TransloadOrders_orderline_total_gross_received_widgetComponent;
      const inParams:{ orderId: number } = { orderId: null };
      if (!isNil(params.get('orderId'))) {
        const paramValueString = params.get('orderId');
        inParams.orderId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'TransloadOrders_orderline_total_packaged_amount_widget') {
      this.logger.log('TransloadOrders', 'orderline_total_packaged_amount_widget');
      const title = 'Total amount';
      const component = TransloadOrders_orderline_total_packaged_amount_widgetComponent;
      const inParams:{ orderId: number } = { orderId: null };
      if (!isNil(params.get('orderId'))) {
        const paramValueString = params.get('orderId');
        inParams.orderId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'TransloadOrders_orderline_total_received_widget') {
      this.logger.log('TransloadOrders', 'orderline_total_received_widget');
      const title = 'Total received';
      const component = TransloadOrders_orderline_total_received_widgetComponent;
      const inParams:{ orderId: number } = { orderId: null };
      if (!isNil(params.get('orderId'))) {
        const paramValueString = params.get('orderId');
        inParams.orderId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }

    let result = null;
    result = this.Utilities.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    return result;
  }
}
