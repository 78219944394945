import { Inject, Injectable, Injector } from '@angular/core';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';

import { Document360_OperationService } from './Document360.operation.service';
import { Usersnap_OperationService } from './Usersnap.operation.service';
import { Utilities_OperationService } from './Utilities.operation.service';
import { Addresses_OperationService } from './Addresses.operation.service';
import { Carriers_OperationService } from './Carriers.operation.service';
import { DockAppointments_OperationService } from './DockAppointments.operation.service';
import { Locations_OperationService } from './Locations.operation.service';
import { Materials_OperationService } from './Materials.operation.service';
import { PurchaseOrders_OperationService } from './PurchaseOrders.operation.service';
import { EasyPost_OperationService } from './EasyPost.operation.service';
import { Inventory_OperationService } from './Inventory.operation.service';
import { Notifications_OperationService } from './Notifications.operation.service';
import { Owners_OperationService } from './Owners.operation.service';
import { SalesOrders_OperationService } from './SalesOrders.operation.service';
import { Lots_OperationService } from './Lots.operation.service';
import { FootPrintApiManager_OperationService } from './FootPrintApiManager.operation.service';
import { ShipTheory_OperationService } from './ShipTheory.operation.service';
import { Manifesting_OperationService } from './Manifesting.operation.service';
import { FootPrintManager_OperationService } from './FootPrintManager.operation.service';
import { Reports_OperationService } from './Reports.operation.service';


@Injectable({ providedIn: 'root' })
export class app_OperationService {

  constructor(
    private injector: Injector,
    private utils: UtilsService,
  ) {
    this.Document360 = this.injector.get(Document360_OperationService);
    this.Usersnap = this.injector.get(Usersnap_OperationService);
    this.Utilities = this.injector.get(Utilities_OperationService);
    this.Addresses = this.injector.get(Addresses_OperationService);
    this.Carriers = this.injector.get(Carriers_OperationService);
    this.DockAppointments = this.injector.get(DockAppointments_OperationService);
    this.Locations = this.injector.get(Locations_OperationService);
    this.Materials = this.injector.get(Materials_OperationService);
    this.PurchaseOrders = this.injector.get(PurchaseOrders_OperationService);
    this.EasyPost = this.injector.get(EasyPost_OperationService);
    this.Inventory = this.injector.get(Inventory_OperationService);
    this.Notifications = this.injector.get(Notifications_OperationService);
    this.Owners = this.injector.get(Owners_OperationService);
    this.SalesOrders = this.injector.get(SalesOrders_OperationService);
    this.Lots = this.injector.get(Lots_OperationService);
    this.FootPrintApiManager = this.injector.get(FootPrintApiManager_OperationService);
    this.ShipTheory = this.injector.get(ShipTheory_OperationService);
    this.Manifesting = this.injector.get(Manifesting_OperationService);
    this.FootPrintManager = this.injector.get(FootPrintManager_OperationService);
    this.Reports = this.injector.get(Reports_OperationService);
  }

    public Document360: Document360_OperationService;
    public Usersnap: Usersnap_OperationService;
    public Utilities: Utilities_OperationService;
    public Addresses: Addresses_OperationService;
    public Carriers: Carriers_OperationService;
    public DockAppointments: DockAppointments_OperationService;
    public Locations: Locations_OperationService;
    public Materials: Materials_OperationService;
    public PurchaseOrders: PurchaseOrders_OperationService;
    public EasyPost: EasyPost_OperationService;
    public Inventory: Inventory_OperationService;
    public Notifications: Notifications_OperationService;
    public Owners: Owners_OperationService;
    public SalesOrders: SalesOrders_OperationService;
    public Lots: Lots_OperationService;
    public FootPrintApiManager: FootPrintApiManager_OperationService;
    public ShipTheory: ShipTheory_OperationService;
    public Manifesting: Manifesting_OperationService;
    public FootPrintManager: FootPrintManager_OperationService;
    public Reports: Reports_OperationService;
  public app: app_OperationService = this;


  private isAuthorized(operationName: string): Promise<boolean> {
    return this.utils.http.post(`${environment.backendUrl}api/app/operations/${operationName}/isauthorized`, null);
  }
}
