import { 
  Component, 
  OnInit,
  OnChanges,
  OnDestroy,
  Input,
  SimpleChanges,
  EventEmitter,
  Output,
  ViewChild,
  Inject,
  forwardRef
} from '@angular/core';

import { 
  FormGroup,
  FormControl,
  Validators 
} from '@angular/forms';

import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { isEqual, isNil } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil, mergeMap, shareReplay } from 'rxjs/operators';
import { DatexFormControl, validateControlOnChange, validateFormOnControlChange } from './models/datex-form-control';
import { TabItemModel, TabGroupModel } from './models/tab';
import { WidgetModel } from './models/widget';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles,
  ValueControlModel
} from './models/control';
import { Styles, ControlContainerStyles } from './models/style';
import { FieldModel } from './models/field';
import { FieldsetModel } from './models/fieldset';
import { ToolModel } from './models/tool';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { app_ShellService, EModalSize, EToasterType, EToasterPosition } from './app.shell.service';
import { app_OperationService } from './app.operation.service';
import { app_DatasourceService } from './app.datasource.index';
import { app_FlowService } from './app.flow.index';
import { app_ReportService } from './app.report.index';
import { app_LocalizationService } from './app.localization.service';
import { Language } from './localization.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './app.frontend.types'
import { $frontendTypes as $types} from './app.frontend.types' 


import { Locations_location_statuses_dd_singleComponent } from './Locations.location_statuses_dd_single.component'
import { Locations_measurement_units_dd_singleComponent } from './Locations.measurement_units_dd_single.component'

@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => Locations_location_statuses_dd_singleComponent),
    forwardRef(() => Locations_measurement_units_dd_singleComponent),
  ],
  selector: 'app-custom_location_editor',
  templateUrl: './app.custom_location_editor.component.html'
})
export class app_custom_location_editorComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {
  inParams: { locationId: number } = { locationId: null };
  //#region Inputs
  @Input('locationId') set $inParams_locationId(v: number) {
    this.inParams.locationId = v;
  }
  get $inParams_locationId(): number {
    return this.inParams.locationId;
  }
  //#endregion Inputs

  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  outParams: { confirm?: boolean } = { confirm: null };
  //#endregion Outputs

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  //#region Variables
  //#endregion
  entity: { Id?: number, AllocationSequence?: number, ChildFootprint?: number, ChildStackHeight?: number, DimensionUomId?: number, EligibleForAllocation?: boolean, GlobalAllocationSequence?: number, GlobalPickSequence?: number, Height?: number, Length?: number, Name?: string, notes?: string, PickSequence?: number, StatusId?: number, WarehouseId?: number, WeightCapacity?: number, WeightUomId?: number, Width?: number, Status?: { Name?: string }, WeightUom?: { Short_name?: string }, DimensionsUom?: { Short_name?: string } };

  formGroup: FormGroup = new FormGroup({
    name: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    status: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    eligible_for_allocation: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    notes: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    picking_sequence: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    length: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    width: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    height: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    dimension_uom: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    weight_capacity: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    weight_uom: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
  });
  
  get valid(): boolean {
    return this.formGroup.valid;
  }

  toolbar = {
      confirm: new ToolModel(new ButtonModel('confirm', new ButtonStyles(['primary'], null), false, 'Confirm', '')
    ),
      cancel: new ToolModel(new ButtonModel('cancel', new ButtonStyles(['secondary'], null), false, 'Cancel', '')
    )
  };

  fields = {
    name: new FieldModel(new TextBoxModel(this.formGroup.controls['name'] as DatexFormControl, null, false, '')
, new ControlContainerStyles(null, null), 'Name', false)
,
    status: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['status'] as DatexFormControl, 
  null, null,
  false, 
  '')
, new ControlContainerStyles(null, null), 'Status', false)
,
    eligible_for_allocation: new FieldModel(new CheckBoxModel(this.formGroup.controls['eligible_for_allocation'] as DatexFormControl, null, false, '')
, new ControlContainerStyles(null, null), 'Eligible For Allocation', false)
,
    notes: new FieldModel(new TextBoxModel(this.formGroup.controls['notes'] as DatexFormControl, null, false, '')
, new ControlContainerStyles(null, null), 'Notes', false)
,
    picking_sequence: new FieldModel(new NumberBoxModel(this.formGroup.controls['picking_sequence'] as DatexFormControl, null, false, '0', '')
, new ControlContainerStyles(null, null), 'Picking sequence', false)
,
    length: new FieldModel(new NumberBoxModel(this.formGroup.controls['length'] as DatexFormControl, null, false, '0.00', '')
, new ControlContainerStyles(null, null), 'Length', false)
,
    width: new FieldModel(new NumberBoxModel(this.formGroup.controls['width'] as DatexFormControl, null, false, '0.00', '')
, new ControlContainerStyles(null, null), 'Width', false)
,
    height: new FieldModel(new NumberBoxModel(this.formGroup.controls['height'] as DatexFormControl, null, false, '0.00', '')
, new ControlContainerStyles(null, null), 'Height', false)
,
    dimension_uom: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['dimension_uom'] as DatexFormControl, 
  null, null,
  false, 
  '')
, new ControlContainerStyles(null, null), 'Dimension Uom', false)
,
    weight_capacity: new FieldModel(new NumberBoxModel(this.formGroup.controls['weight_capacity'] as DatexFormControl, null, false, '0.00', '')
, new ControlContainerStyles(null, null), 'Weight Capacity', false)
,
    weight_uom: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['weight_uom'] as DatexFormControl, 
  null, null,
  false, 
  '')
, new ControlContainerStyles(null, null), 'Weight Uom', false)
,
  };

  fieldsets = {
  newGroup1: new FieldsetModel('', true, false, true),
};


  //#region fields inParams
  get $fields_dimension_uom_selector_inParams_typeId(): number {
    if (!this.entity) return null; 
    const $editor = this;
    const $utils = this.utils;
    const expr = 1;
    
    return expr;
  }

  get $fields_weight_uom_selector_inParams_typeId(): number {
    if (!this.entity) return null; 
    const $editor = this;
    const $utils = this.utils;
    const expr = 3;
    
    return expr;
  }

  //#endregion fields inParams

  $formGroupFieldValidationObservables = {
    name: this.fields.name.control.valueChanges
    ,
    status: this.fields.status.control.valueChanges
    ,
    eligible_for_allocation: this.fields.eligible_for_allocation.control.valueChanges
    ,
    notes: this.fields.notes.control.valueChanges
    ,
    picking_sequence: this.fields.picking_sequence.control.valueChanges
    ,
    length: this.fields.length.control.valueChanges
    ,
    width: this.fields.width.control.valueChanges
    ,
    height: this.fields.height.control.valueChanges
    ,
    dimension_uom: this.fields.dimension_uom.control.valueChanges
    ,
    weight_capacity: this.fields.weight_capacity.control.valueChanges
    ,
    weight_uom: this.fields.weight_uom.control.valueChanges
    ,
  }
  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    private shell: app_ShellService,
    private datasources: app_DatasourceService,
    private flows: app_FlowService,
    private reports: app_ReportService,
    private localization: app_LocalizationService,
    private operations: app_OperationService,
    private logger: CleanupLoggerService,
    ) { 
    super();
    this.$subscribeFormControlValueChanges();
    
  }

  ngOnInit(): void {
    this.$checkRequiredInParams();
    if (!this.$hasMissingRequiredInParams) {
      this.$init();
    } else {
      this.$initEmpty();
    }
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$checkRequiredInParams();
      if(!this.$hasMissingRequiredInParams) {
        this.$init();
      } else {
        this.$initEmpty();
      }
    }
  }

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }
  $missingRequiredInParams = [];
  get $hasMissingRequiredInParams(): boolean {
    return !!this.$missingRequiredInParams.length;
  }
  
  $checkRequiredInParams() {
    this.$missingRequiredInParams = [];
      if(isNil(this.inParams.locationId)) {
        this.$missingRequiredInParams.push('locationId');
      }
  }

  initialized = false;
  $hasDataLoaded = false;

  async $init() {
    this.title = 'Edit Location';
    
    await this.on_init();
    await this.$dataLoad();
    this.initialized = true;
  }

  async $dataLoad() {
    const $editor = this;
    const $utils = this.utils;

    const dsParams = {
      locationId:  $editor.inParams.locationId 
    };

    const data = await this.datasources.app.custom_ds_location_editor.get(dsParams);

    if (isNil(data.result)) {
      this.$hasDataLoaded = false;
      this.entity = null;
    } else {
      this.$hasDataLoaded = true;
      this.entity = data.result;
      await this.$dataLoaded();
    }
  }

  async $dataLoaded() {
    const $editor = this;
    const $utils = this.utils;
   
    (this.fields.name.control as TextBoxModel).reset($editor.entity.Name);
    (this.fields.status.control as SelectBoxModel).reset($editor.entity.StatusId);
    (this.fields.eligible_for_allocation.control as CheckBoxModel).reset($editor.entity.EligibleForAllocation);
    (this.fields.notes.control as TextBoxModel).reset($editor.entity.notes);
    (this.fields.picking_sequence.control as NumberBoxModel).reset($editor.entity.PickSequence);
    (this.fields.length.control as NumberBoxModel).reset($editor.entity.Length);
    (this.fields.width.control as NumberBoxModel).reset($editor.entity.Width);
    (this.fields.height.control as NumberBoxModel).reset($editor.entity.Height);
    (this.fields.dimension_uom.control as SelectBoxModel).reset($editor.entity.DimensionUomId);
    (this.fields.weight_capacity.control as NumberBoxModel).reset($editor.entity.WeightCapacity);
    (this.fields.weight_uom.control as SelectBoxModel).reset($editor.entity.WeightUomId);

  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    if (this.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();
    
    // children
    if (skipChildren === false) {
      this.$refreshChildren(childToSkip);
    }

    return result;
  }

  $refreshChildren(childToSkip: string) {
  }

  close() {
    this.$finish.emit();
  }

  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }
  
  private $subscribeFormControlValueChanges() {
    this.$formGroupFieldValidationObservables
      .name
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .status
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .eligible_for_allocation
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .notes
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .picking_sequence
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .length
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .width
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .height
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .dimension_uom
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .weight_capacity
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .weight_uom
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
  }

  //#region private flows
  on_confirm_clicked(event = null) {
    return this.on_confirm_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_confirm_clickedInternal(
    $editor: app_custom_location_editorComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
    this.logger.log('app', 'custom_location_editor.on_confirm_clicked');
  
  if ($editor.fields.name.control.isChanged) {
      const locations = (await $datasources.Locations.ds_get_locations_by_name_and_warehouseId.get({
          warehouseId: $editor.entity.WarehouseId,
          name: $editor.fields.name.control.value
      })).result;
      if ($utils.isDefined(locations)) {
          throw new Error('Duplicate location found');
      }
  }
  var licenseplateId;
  
  const looseLicenseplate = (await $datasources.Locations.ds_get_licenseplate_by_loose_locationId.get({ locationId: $editor.inParams.locationId })).result;
  if ($utils.isDefined(looseLicenseplate)) {
       licenseplateId = looseLicenseplate[0].Id
  }
  
  
  // Construct payload with any values that have changed
  const payload: any = {};
  const licenseplatePayload: any = {};
  
  
  // General Properties
  if ($editor.fields.name.control.isChanged) {
      payload.Name = $editor.fields.name.control.value;
  }
  if ($editor.fields.status.control.isChanged) {
      payload.StatusId = $editor.fields.status.control.value;
      licenseplatePayload.StatusId = $editor.fields.status.control.value;
  }
  if ($editor.fields.eligible_for_allocation.control.isChanged) {
      payload.EligibleForAllocation = $editor.fields.eligible_for_allocation.control.value;
  }
  
  // Dimensions
  if ($editor.fields.length.control.isChanged) {
      payload.Length = $editor.fields.length.control.value;
  }
  if ($editor.fields.width.control.isChanged) {
      payload.Width = $editor.fields.width.control.value;
  }
  if ($editor.fields.height.control.isChanged) {
      payload.Height = $editor.fields.height.control.value;
  }
  if ($editor.fields.dimension_uom.control.isChanged) {
      payload.DimensionUomId = $editor.fields.dimension_uom.control.value;
  }
  // Weight
  if ($editor.fields.weight_capacity.control.isChanged) {
      payload.WeightCapacity = $editor.fields.weight_capacity.control.value;
  }
  if ($editor.fields.weight_uom.control.isChanged) {
      payload.WeightUomId = $editor.fields.weight_uom.control.value;
  }
  
  if ($editor.fields.picking_sequence.control.isChanged) {
      payload.PickSequence = $editor.fields.picking_sequence.control.value;
      payload.GlobalPickSequence = $editor.fields.picking_sequence.control.value;
  }
  
  // Custom UDFs
  if ($editor.fields.notes.control.isChanged) {
      payload.notes = $editor.fields.notes.control.value;
  }
  
  if (!$utils.isDefined(payload)) { return; }
  
  // Make API call
  try {
      (await $flows.Utilities.crud_update_flow({
          entitySet: 'LocationContainers',
          id: $editor.entity.Id,
          entity: payload
      }));
  
      if ($utils.isDefined(licenseplateId) && $utils.isDefined(licenseplatePayload)) {
          (await $flows.Utilities.crud_update_flow({
              entitySet: 'LicensePlates',
              id: licenseplateId,
              entity: licenseplatePayload
          }));
  
      }
  
      $editor.outParams.confirm = true;
      $editor.close();
  }
  catch (error) {
      $shell.Locations.showErrorDetails('Save', 'Error on save.', error);
      throw error; // To prevent display mode
  }
  }
  on_cancel_clicked(event = null) {
    return this.on_cancel_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_cancel_clickedInternal(
    $editor: app_custom_location_editorComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
    this.logger.log('app', 'custom_location_editor.on_cancel_clicked');
  $editor.outParams.confirm = false;
  $editor.close();
  }
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $editor: app_custom_location_editorComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
    this.logger.log('app', 'custom_location_editor.on_init');
  $editor.outParams.confirm = false;
  }
  //#endregion private flows
}
