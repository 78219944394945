import { 
  Component, 
  OnInit,
  OnChanges,
  OnDestroy,
  Input,
  SimpleChanges,
  EventEmitter,
  Output,
  ViewChild,
  Inject,
  forwardRef
} from '@angular/core';

import { 
  FormGroup,
  FormControl,
  Validators 
} from '@angular/forms';

import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { isEqual, isNil } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil, mergeMap, shareReplay } from 'rxjs/operators';
import { DatexFormControl, validateControlOnChange, validateFormOnControlChange } from './models/datex-form-control';
import { TabItemModel, TabGroupModel } from './models/tab';
import { WidgetModel } from './models/widget';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles,
  ValueControlModel
} from './models/control';
import { Styles, ControlContainerStyles } from './models/style';
import { FieldModel } from './models/field';
import { FieldsetModel } from './models/fieldset';
import { ToolModel } from './models/tool';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootPrintManager_ShellService, EModalSize, EToasterType, EToasterPosition } from './FootPrintManager.shell.service';
import { FootPrintManager_OperationService } from './FootPrintManager.operation.service';
import { FootPrintManager_DatasourceService } from './FootPrintManager.datasource.index';
import { FootPrintManager_FlowService } from './FootPrintManager.flow.index';
import { FootPrintManager_ReportService } from './FootPrintManager.report.index';
import { FootPrintManager_LocalizationService } from './FootPrintManager.localization.service';
import { Language } from './localization.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './FootPrintManager.frontend.types'
import { $frontendTypes as $types} from './FootPrintManager.frontend.types' 


import { FootPrintManager_load_container_orders_gridComponent } from './FootPrintManager.load_container_orders_grid.component';
import { FootPrintManager_temperature_readings_gridComponent } from './FootPrintManager.temperature_readings_grid.component';
import { LoadContainers_container_types_singleComponent } from './LoadContainers.container_types_single.component'
import { LoadContainers_load_container_statuses_singleComponent } from './LoadContainers.load_container_statuses_single.component'
import { Locations_warehouses_dd_singleComponent } from './Locations.warehouses_dd_single.component'
import { Locations_locations_dd_singleComponent } from './Locations.locations_dd_single.component'
import { LoadContainers_order_types_singleComponent } from './LoadContainers.order_types_single.component'
import { LoadContainers_carriers_singleComponent } from './LoadContainers.carriers_single.component'
import { LoadContainers_carrier_service_types_singleComponent } from './LoadContainers.carrier_service_types_single.component'
import { LoadContainers_shipment_priorities_singleComponent } from './LoadContainers.shipment_priorities_single.component'

@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => FootPrintManager_load_container_orders_gridComponent),
    forwardRef(() => FootPrintManager_temperature_readings_gridComponent),
    forwardRef(() => LoadContainers_container_types_singleComponent),
    forwardRef(() => LoadContainers_load_container_statuses_singleComponent),
    forwardRef(() => Locations_warehouses_dd_singleComponent),
    forwardRef(() => Locations_locations_dd_singleComponent),
    forwardRef(() => LoadContainers_order_types_singleComponent),
    forwardRef(() => LoadContainers_carriers_singleComponent),
    forwardRef(() => LoadContainers_carrier_service_types_singleComponent),
    forwardRef(() => LoadContainers_shipment_priorities_singleComponent),
  ],
  selector: 'FootPrintManager-load_container_editor',
  templateUrl: './FootPrintManager.load_container_editor.component.html'
})
export class FootPrintManager_load_container_editorComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {
  inParams: { loadContainerId: number } = { loadContainerId: null };
  //#region Inputs
  @Input('loadContainerId') set $inParams_loadContainerId(v: number) {
    this.inParams.loadContainerId = v;
  }
  get $inParams_loadContainerId(): number {
    return this.inParams.loadContainerId;
  }
  //#endregion Inputs

  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  //#region Variables
  vars: { edit?: boolean, warehouseId?: number, ownerId?: number, projectId?: number, carrierId?: number, createdCount?: number } = { };
  //#endregion
  entity: { Id?: number, AvailableDate?: string, CallOutDate?: string, CheckOutDate?: string, ContainerSize?: string, ContainsPortalVisibleAttachments?: boolean, CreatedSysDateTime?: string, CreatedSysUser?: string, InYardDate?: string, LastOnsiteDate?: string, LastPierDate?: string, LookupCode?: string, ModifiedSysDateTime?: string, ModifiedSysUser?: string, Notes?: string, Priority?: number, ReasonCodeId?: number, SealIdentifier?: string, TrailerNumber?: string, Carrier?: { Id?: number, Name?: string }, CarrierServiceType?: { Id?: number, Name?: string }, ContainerType?: { Id?: number, Name?: string }, OrderType?: { Id?: number, Name?: string }, Status?: { Id?: number, Name?: string }, YardLocation?: { Id?: number, Name?: string, WarehouseId?: number }, DockAppointmentsLoadContainersLookup?: { DockAppointmentId?: number, DockAppointment?: { LookupCode?: string, ScheduledLocation?: { Name?: string } } }[] };

  formGroup: FormGroup = new FormGroup({
    lookupCode: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    container_type: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    status: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    container_size: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    warehouse: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    yard_location: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    order_type: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    carrier: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    carrier_service: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    seal_id: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    trailer_number: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    priority: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    notes: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    available_date: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    callout_date: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    checkout_date: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    inyard_date: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    lastonsite_date: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    lastpier_date: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    created_date: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    modified_date: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
  });
  
  get valid(): boolean {
    return this.formGroup.valid;
  }

  toolbar = {
      process: new ToolModel(new ButtonModel('process', new ButtonStyles(null, null), false, 'Process', 'ms-Icon ms-Icon--Process')
    ),
      separator1: new ToolModel(new SeparatorModel(new Styles(null, null))
    ),
      appointment: new ToolModel(new ButtonModel('appointment', new ButtonStyles(null, null), false, 'Appointment', 'icon-ic_fluent_calendar_ltr_20_regular')
    ),
      separator2: new ToolModel(new SeparatorModel(new Styles(null, null))
    ),
      print: new ToolModel(new ButtonModel('print', new ButtonStyles(null, null), false, 'Print', 'icon-ic_fluent_print_20_regular')
    ),
      attachments: new ToolModel(new ButtonModel('attachments', new ButtonStyles(null, null), false, ' ', 'icon-ic_fluent_attach_20_regular')
    )
  };

  fields = {
    lookupCode: new FieldModel(new TextBoxModel(this.formGroup.controls['lookupCode'] as DatexFormControl, null, false, '')
, new ControlContainerStyles(null, null), 'Container code', false)
,
    container_type: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['container_type'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false, 
  '')
, new ControlContainerStyles(null, null), 'Container type', false)
,
    status: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['status'] as DatexFormControl, 
  null, null,
  false, 
  '')
, new ControlContainerStyles(null, null), 'Status', false)
,
    container_size: new FieldModel(new TextBoxModel(this.formGroup.controls['container_size'] as DatexFormControl, null, false, '')
, new ControlContainerStyles(null, null), 'Container size', false)
,
    warehouse: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['warehouse'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false, 
  '')
, new ControlContainerStyles(null, null), 'Warehouse', false)
,
    yard_location: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['yard_location'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false, 
  '')
, new ControlContainerStyles(null, null), 'Yard location', false)
,
    order_type: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['order_type'] as DatexFormControl, 
  ESelectBoxType.radioButtonsCheckboxes, null,
  false, 
  '')
, new ControlContainerStyles(null, null), 'Order type', false)
,
    carrier: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['carrier'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false, 
  '')
, new ControlContainerStyles(null, null), 'Carrier', false)
,
    carrier_service: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['carrier_service'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false, 
  '')
, new ControlContainerStyles(null, null), 'Service type', false)
,
    seal_id: new FieldModel(new TextBoxModel(this.formGroup.controls['seal_id'] as DatexFormControl, null, false, '')
, new ControlContainerStyles(null, null), 'Seal ID', false)
,
    trailer_number: new FieldModel(new TextBoxModel(this.formGroup.controls['trailer_number'] as DatexFormControl, null, false, '')
, new ControlContainerStyles(null, null), 'Trailer number', false)
,
    priority: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['priority'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false, 
  '')
, new ControlContainerStyles(null, null), 'Priority', false)
,
    notes: new FieldModel(new TextBoxModel(this.formGroup.controls['notes'] as DatexFormControl, null, false, '')
, new ControlContainerStyles(null, null), 'Notes', false)
,
    available_date: new FieldModel(new DateBoxModel(this.formGroup.controls['available_date'] as DatexFormControl, null, false, '', 'date')
, new ControlContainerStyles(null, null), 'Available', false)
,
    callout_date: new FieldModel(new DateBoxModel(this.formGroup.controls['callout_date'] as DatexFormControl, null, false, '', 'date')
, new ControlContainerStyles(null, null), 'Call out', false)
,
    checkout_date: new FieldModel(new DateBoxModel(this.formGroup.controls['checkout_date'] as DatexFormControl, null, false, '', 'date')
, new ControlContainerStyles(null, null), 'Check out', false)
,
    inyard_date: new FieldModel(new DateBoxModel(this.formGroup.controls['inyard_date'] as DatexFormControl, null, false, '', 'date')
, new ControlContainerStyles(null, null), 'In yard', false)
,
    lastonsite_date: new FieldModel(new DateBoxModel(this.formGroup.controls['lastonsite_date'] as DatexFormControl, null, false, '', 'date')
, new ControlContainerStyles(null, null), 'Last onsite', false)
,
    lastpier_date: new FieldModel(new DateBoxModel(this.formGroup.controls['lastpier_date'] as DatexFormControl, null, false, '', 'date')
, new ControlContainerStyles(null, null), 'Last pier', false)
,
    created_date: new FieldModel(new TextBoxModel(this.formGroup.controls['created_date'] as DatexFormControl, null, true, '')
, new ControlContainerStyles(null, null), 'Created', false)
,
    modified_date: new FieldModel(new TextBoxModel(this.formGroup.controls['modified_date'] as DatexFormControl, null, true, '')
, new ControlContainerStyles(null, null), 'Modified', false)
,
  };

  fieldsets = {
  newGroup1: new FieldsetModel('Header', false, true, true),
  newGroup2: new FieldsetModel('Shipping info', false, true, false),
  newGroup3: new FieldsetModel('Dates', false, true, false),
};

    rootTabGroup = new TabGroupModel();
  
    subTabGroups = {
    };
  
    onTabSelected(event: MatSelectChange) {
      event.value.activate();
    }
  
    tabs = {
      orders: new TabItemModel(
        this.rootTabGroup, 
        'Orders', 
        ),
      temperatures: new TabItemModel(
        this.rootTabGroup, 
        'Temperatures', 
        ),
    };
  
    //#region tabs inParams
    get $tabs_orders_load_container_orders_grid_inParams_loadContainerId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.inParams.loadContainerId;
      
      return expr;
    }
  
    get $tabs_orders_load_container_orders_grid_inParams_edit(): boolean {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.vars.edit;
      
      return expr;
    }
  
    get $tabs_orders_load_container_orders_grid_inParams_warehouseId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.fields.warehouse.control.value;
      
      return expr;
    }
  
    get $tabs_orders_load_container_orders_grid_inParams_orderTypeId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.fields.order_type.control.value;
      
      return expr;
    }
  
    get $tabs_orders_load_container_orders_grid_inParams_status_id(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.entity.Status?.Id;
      
      return expr;
    }
  
    get $tabs_temperatures_temperature_readings_grid_inParams_loadContainerId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.inParams.loadContainerId;
      
      return expr;
    }
  
    //#endregion tabs inParams
  
    //#region tabs children
      @ViewChild('$tabs_orders', { read: FootPrintManager_load_container_orders_gridComponent }) $tabs_orders: FootPrintManager_load_container_orders_gridComponent;
      @ViewChild('$tabs_temperatures', { read: FootPrintManager_temperature_readings_gridComponent }) $tabs_temperatures: FootPrintManager_temperature_readings_gridComponent;
    //#endregion tabs children

  //#region fields inParams
  get $fields_yard_location_selector_inParams_warehouseId(): number {
    if (!this.entity) return null; 
    const $editor = this;
    const $utils = this.utils;
    const expr = $editor.fields.warehouse.control.value;
    
    return expr;
  }

  get $fields_yard_location_selector_inParams_typeId(): number {
    if (!this.entity) return null; 
    const $editor = this;
    const $utils = this.utils;
    const expr = 6;
    
    return expr;
  }

  get $fields_carrier_service_selector_inParams_carrierId(): number {
    if (!this.entity) return null; 
    const $editor = this;
    const $utils = this.utils;
    const expr = $editor.fields.carrier.control.value;
    
    return expr;
  }

  //#endregion fields inParams

  $formGroupFieldValidationObservables = {
    lookupCode: this.fields.lookupCode.control.valueChanges
    ,
    container_type: this.fields.container_type.control.valueChanges
    ,
    status: this.fields.status.control.valueChanges
    ,
    container_size: this.fields.container_size.control.valueChanges
    ,
    warehouse: this.fields.warehouse.control.valueChanges
    ,
    yard_location: this.fields.yard_location.control.valueChanges
    ,
    order_type: this.fields.order_type.control.valueChanges
    ,
    carrier: this.fields.carrier.control.valueChanges
    ,
    carrier_service: this.fields.carrier_service.control.valueChanges
    ,
    seal_id: this.fields.seal_id.control.valueChanges
    ,
    trailer_number: this.fields.trailer_number.control.valueChanges
    ,
    priority: this.fields.priority.control.valueChanges
    ,
    notes: this.fields.notes.control.valueChanges
    ,
    available_date: this.fields.available_date.control.valueChanges
    ,
    callout_date: this.fields.callout_date.control.valueChanges
    ,
    checkout_date: this.fields.checkout_date.control.valueChanges
    ,
    inyard_date: this.fields.inyard_date.control.valueChanges
    ,
    lastonsite_date: this.fields.lastonsite_date.control.valueChanges
    ,
    lastpier_date: this.fields.lastpier_date.control.valueChanges
    ,
    created_date: this.fields.created_date.control.valueChanges
    ,
    modified_date: this.fields.modified_date.control.valueChanges
    ,
  }
  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    private shell: FootPrintManager_ShellService,
    private datasources: FootPrintManager_DatasourceService,
    private flows: FootPrintManager_FlowService,
    private reports: FootPrintManager_ReportService,
    private localization: FootPrintManager_LocalizationService,
    private operations: FootPrintManager_OperationService,
    private logger: CleanupLoggerService,
    ) { 
    super();
    this.$subscribeFormControlValueChanges();
    
    //#region tabs tab init
    this.rootTabGroup.tabs = [
      this.tabs.orders,
      this.tabs.temperatures,
    ]; 
    //#endregion tabs tab init
  }

  ngOnInit(): void {
    this.$checkRequiredInParams();
    if (!this.$hasMissingRequiredInParams) {
      this.$init();
    } else {
      this.$initEmpty();
    }
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$checkRequiredInParams();
      if(!this.$hasMissingRequiredInParams) {
        this.$init();
      } else {
        this.$initEmpty();
      }
    }
  }

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }
  $missingRequiredInParams = [];
  get $hasMissingRequiredInParams(): boolean {
    return !!this.$missingRequiredInParams.length;
  }
  
  $checkRequiredInParams() {
    this.$missingRequiredInParams = [];
      if(isNil(this.inParams.loadContainerId)) {
        this.$missingRequiredInParams.push('loadContainerId');
      }
  }

  initialized = false;
  $hasDataLoaded = false;

  async $init() {
    this.title = 'Load Container Editor';
    
    await this.on_init();
    await this.$dataLoad();
    this.initialized = true;
  }

  async $dataLoad() {
    const $editor = this;
    const $utils = this.utils;

    const dsParams = {
      loadContainerId:  $editor.inParams.loadContainerId 
    };

    const data = await this.datasources.LoadContainers.ds_load_container_editor.get(dsParams);

    if (isNil(data.result)) {
      this.$hasDataLoaded = false;
      this.entity = null;
    } else {
      this.$hasDataLoaded = true;
      this.entity = data.result;
      await this.$dataLoaded();
    }
  }

  async $dataLoaded() {
    const $editor = this;
    const $utils = this.utils;
   
    (this.fields.lookupCode.control as TextBoxModel).reset($editor.entity.LookupCode);
    (this.fields.container_type.control as SelectBoxModel).reset($editor.entity.ContainerType?.Id);
    (this.fields.status.control as SelectBoxModel).reset($editor.entity.Status?.Id);
    (this.fields.container_size.control as TextBoxModel).reset($editor.entity.ContainerSize);
    (this.fields.order_type.control as SelectBoxModel).reset($editor.entity.OrderType?.Id);
    (this.fields.carrier.control as SelectBoxModel).reset($editor.entity.Carrier?.Id);
    (this.fields.carrier_service.control as SelectBoxModel).reset($editor.entity.CarrierServiceType?.Id);
    (this.fields.seal_id.control as TextBoxModel).reset($editor.entity.SealIdentifier);
    (this.fields.trailer_number.control as TextBoxModel).reset($editor.entity.TrailerNumber);
    (this.fields.priority.control as SelectBoxModel).reset($editor.entity.Priority);
    (this.fields.notes.control as TextBoxModel).reset($editor.entity.Notes);
    (this.fields.available_date.control as DateBoxModel).reset($editor.entity.AvailableDate);
    (this.fields.callout_date.control as DateBoxModel).reset($editor.entity.CallOutDate);
    (this.fields.checkout_date.control as DateBoxModel).reset($editor.entity.CheckOutDate);
    (this.fields.inyard_date.control as DateBoxModel).reset($editor.entity.InYardDate);
    (this.fields.lastonsite_date.control as DateBoxModel).reset($editor.entity.LastOnsiteDate);
    (this.fields.lastpier_date.control as DateBoxModel).reset($editor.entity.LastPierDate);
    
    

    await this.on_data_loaded();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    if (this.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();
    
    // children
    if (skipChildren === false) {
      this.$refreshChildren(childToSkip);
    }

    return result;
  }

  $refreshChildren(childToSkip: string) {
    //#region tabs children
      if (childToSkip !== '$tabs_orders') {
        if (!isNil(this.$tabs_orders) && !this.tabs.orders.hidden) {
          this.$tabs_orders.refresh(true, false, null);
        }
      }    
      if (childToSkip !== '$tabs_temperatures') {
        if (!isNil(this.$tabs_temperatures) && !this.tabs.temperatures.hidden) {
          this.$tabs_temperatures.refresh(true, false, null);
        }
      }    
    //#endregion tabs children
  }

  close() {
    this.$finish.emit();
  }

  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }
  
  private $subscribeFormControlValueChanges() {
    this.$formGroupFieldValidationObservables
      .lookupCode
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_save_clicked();
      });
    this.$formGroupFieldValidationObservables
      .container_type
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_save_clicked();
      });
    this.$formGroupFieldValidationObservables
      .status
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_save_clicked();
      });
    this.$formGroupFieldValidationObservables
      .container_size
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_save_clicked();
      });
    this.$formGroupFieldValidationObservables
      .warehouse
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_warehouse_change();
      });
    this.$formGroupFieldValidationObservables
      .yard_location
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_save_clicked();
      });
    this.$formGroupFieldValidationObservables
      .order_type
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_order_type_change();
      });
    this.$formGroupFieldValidationObservables
      .carrier
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_save_clicked();
      });
    this.$formGroupFieldValidationObservables
      .carrier_service
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_save_clicked();
      });
    this.$formGroupFieldValidationObservables
      .seal_id
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_save_clicked();
      });
    this.$formGroupFieldValidationObservables
      .trailer_number
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .priority
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_save_clicked();
      });
    this.$formGroupFieldValidationObservables
      .notes
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_save_clicked();
      });
    this.$formGroupFieldValidationObservables
      .available_date
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_save_clicked();
      });
    this.$formGroupFieldValidationObservables
      .callout_date
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_save_clicked();
      });
    this.$formGroupFieldValidationObservables
      .checkout_date
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_save_clicked();
      });
    this.$formGroupFieldValidationObservables
      .inyard_date
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_save_clicked();
      });
    this.$formGroupFieldValidationObservables
      .lastonsite_date
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_save_clicked();
      });
    this.$formGroupFieldValidationObservables
      .lastpier_date
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_save_clicked();
      });
    this.$formGroupFieldValidationObservables
      .created_date
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .modified_date
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
  }

  //#region private flows
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $editor: FootPrintManager_load_container_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
    this.logger.log('FootPrintManager', 'load_container_editor.on_init');
  //O.Arias - 07/25/2024
  
  //Set the edit variable to false
  $editor.vars.edit = false;
  
  //Start the button as read only
  $editor.toolbar.process.control.readOnly = true;
  
  // Call common flow to apply the role based permissions
  await $editor.apply_operations();
  
  //For required field issues
  $editor.fields.yard_location.control.readOnly = true
  
  //Hide the print button
  $editor.toolbar.print.hidden = true;
  }
  on_data_loaded(event = null) {
    return this.on_data_loadedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_data_loadedInternal(
    $editor: FootPrintManager_load_container_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
    this.logger.log('FootPrintManager', 'load_container_editor.on_data_loaded');
  //O.Arias - 09/20/2023
  
  //Set the title on the editor
  $editor.title = `Load Container ${$editor.entity.LookupCode}`;
  
  // Set Date/Time Formats
  const format = `${$settings.FootPrintManager.DateFormat}, ${$settings.FootPrintManager.TimeFormat.toUpperCase() == '24 HOUR' ? 'HH:mm' : 'LT'}`;
  
  if ($utils.isDefined(format)) {
  
      if ($utils.isDefined($editor.entity.CreatedSysDateTime)) {
          $editor.fields.created_date.control.value = $utils.date.format($editor.entity.CreatedSysDateTime, format);
      }
  
      if ($utils.isDefined($editor.entity.ModifiedSysDateTime)) {
          $editor.fields.modified_date.control.value = $utils.date.format($editor.entity.ModifiedSysDateTime, format);
      }
  }
  
  //4:Completed, 5:CallOut, 6:Closed
  if ($editor.entity.Status.Id === 4 || $editor.entity.Status.Id === 5 || $editor.entity.Status.Id === 6) {
  
      $editor.fields.available_date.control.readOnly = true;
      $editor.fields.callout_date.control.readOnly = true;
      $editor.fields.carrier.control.readOnly = true;
      $editor.fields.carrier_service.control.readOnly = true;
      $editor.fields.checkout_date.control.readOnly = true;
      $editor.fields.container_size.control.readOnly = true;
      $editor.fields.container_type.control.readOnly = true;
      $editor.fields.created_date.control.readOnly = true;
      $editor.fields.inyard_date.control.readOnly = true;
      $editor.fields.lastonsite_date.control.readOnly = true;
      $editor.fields.lastpier_date.control.readOnly = true;
      $editor.fields.lookupCode.control.readOnly = true;
      $editor.fields.modified_date.control.readOnly = true;
      $editor.fields.order_type.control.readOnly = true;
      $editor.fields.priority.control.readOnly = true;
      $editor.fields.seal_id.control.readOnly = true;
      $editor.fields.status.control.readOnly = true;
      $editor.fields.trailer_number.control.readOnly = true;
      $editor.fields.warehouse.control.readOnly = true;
      $editor.fields.yard_location.control.readOnly = true;
      $editor.fields.notes.control.readOnly = true;
  };
  
  if ($utils.isDefined($editor.entity.YardLocation)) {
      $editor.vars.warehouseId = $editor.entity.YardLocation.WarehouseId;
      $editor.fields.warehouse.control.value = $editor.entity.YardLocation.WarehouseId;
      $editor.fields.yard_location.control.value = $editor.entity.YardLocation.Id;
      $editor.fields.yard_location.control.readOnly = false;
  } else {
      $editor.fields.yard_location.control.readOnly = true;
  }
  
  // Apply appointment text logic
  const dockAppointments = (await $datasources.DockAppointments.ds_get_dock_appointment_by_loadContainerId.get({
      loadContainerId: $editor.inParams.loadContainerId
  })).result;
  
  if ($utils.isDefined(dockAppointments)) {
      $editor.toolbar.appointment.control.label = (await $flows.DockAppointments.get_formatted_dock_appointment({
          dockAppointmentId: dockAppointments[0]?.DockAppointmentId,
      })).formattedDockAppointment;
  
  } else {
      // Reset the appointment label back to original
      $editor.toolbar.appointment.control.styles.resetStyle();
      $editor.toolbar.appointment.control.label = 'Appointment'
  };
  
  let load_container_id:number = $editor.inParams.loadContainerId
  const cnt = (await $datasources.LoadContainers.ds_get_shipment_load_containers.get({load_container_id:load_container_id})).totalCount
  if (cnt>0)
  { $editor.fields.order_type.control.readOnly = true}
  else
  { $editor.fields.order_type.control.readOnly = false}
  
  
  /****FUNCTIONS****/
  
  function disableComponent(component: any) {
      if ($utils.isDefined(component?.control?.readOnly)) {
          component.control.readOnly = true;
      }
      component.hidden = true;
  }
  function enableComponent(component: any) {
      if ($utils.isDefined(component?.control?.readOnly)) {
          component.control.readOnly = false;
      }
      component.hidden = false;
  
  }
  }
  on_save_clicked(event = null) {
    return this.on_save_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_save_clickedInternal(
    $editor: FootPrintManager_load_container_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
    this.logger.log('FootPrintManager', 'load_container_editor.on_save_clicked');
  //O.Arias - 07/17/2023
  
  try {
  
          //Build the contract payload
          let payload: any = {};
  
          if ($editor.fields.available_date.control.isChanged) {
              payload.AvailableDate = $editor.fields.available_date.control.value;
          };
          if ($editor.fields.callout_date.control.isChanged) {
              payload.CallOutDate = $editor.fields.callout_date.control.value;
          };
          if ($editor.fields.carrier.control.isChanged) {
              payload.CarrierId = $editor.fields.carrier.control.value;
          };
          if ($editor.fields.carrier_service.control.isChanged) {
              payload.CarrierServiceId = $editor.fields.carrier_service.control.value;
          };
          if ($editor.fields.checkout_date.control.isChanged) {
              payload.CheckOutDate = $editor.fields.checkout_date.control.value;
          };
          if ($editor.fields.container_size.control.isChanged) {
              payload.ContainerSize = $editor.fields.container_size.control.value;
          };
          if ($editor.fields.container_type.control.isChanged) {
              payload.LoadContainerContainerTypeId = $editor.fields.container_type.control.value;
          };
          if ($editor.fields.inyard_date.control.isChanged) {
              payload.InYardDate = $editor.fields.inyard_date.control.value;
          };
          if ($editor.fields.lastonsite_date.control.isChanged) {
              payload.LastOnsiteDate = $editor.fields.lastonsite_date.control.value;
          };
          if ($editor.fields.lastpier_date.control.isChanged) {
              payload.LastPierDate = $editor.fields.lastpier_date.control.value;
          };
          if ($editor.fields.lookupCode.control.isChanged) {
              payload.LookupCode = $editor.fields.lookupCode.control.value;
          };
          if ($editor.fields.order_type.control.isChanged) {
              payload.OrderTypeId = $editor.fields.order_type.control.value;
          };
          if ($editor.fields.priority.control.isChanged) {
              payload.Priority = $editor.fields.priority.control.value;
          };
          if ($editor.fields.seal_id.control.isChanged) {
              payload.SealIdentifier = $editor.fields.seal_id.control.value;
          };
          if ($editor.fields.trailer_number.control.isChanged) {
              payload.TrailerNumber = $editor.fields.trailer_number.control.value;
          };
          if ($editor.fields.yard_location.control.isChanged) {
              payload.YardLocationId = $editor.fields.yard_location.control.value;
          };
          if ($editor.fields.notes.control.isChanged) {
              payload.Notes = $editor.fields.notes.control.value;
          };
          if ($editor.fields.status.control.isChanged) {
              payload.StatusId = $editor.fields.status.control.value;
          };
  
          await $flows.Utilities.crud_update_flow({ entitySet: 'LoadContainers', id: $editor.entity.Id, entity: payload });
  
          await $editor.refresh();
  
  } catch (error) {
      const errorMessage = $utils.isDefined(error?.error?.error) ? error?.error?.error.message : error;
      const errorDetail = $utils.isDefined(error?.error?.error) ? error?.error?.error : error;
      const errorDescription = `Load Container ${$editor.entity.LookupCode} - ${errorMessage}`;
      await $shell.FootPrintManager.openErrorDialog('Update load container error', 'An error occurred during updating of the load container', [errorDescription], null, [{ message: errorDescription, detail: errorDetail }]);
  }
  
  }
  on_grid_output(event = null) {
    return this.on_grid_outputInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_grid_outputInternal(
    $editor: FootPrintManager_load_container_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
    this.logger.log('FootPrintManager', 'load_container_editor.on_grid_output');
  //O.Arias - 05/03/2024
  
  if ($utils.isDefined($event.warehouseId)) {
      if (!$utils.isDefined($editor.vars.warehouseId)) {
          $editor.fields.warehouse.control.value = $event.warehouseId;
          $editor.fields.yard_location.control.readOnly = false;
          $editor.vars.warehouseId = $event.warehouseId;
      };
  };
  
  if ($utils.isDefined($event.ownerId)) {
      if (!$utils.isDefined($editor.vars.ownerId)) {
          $editor.vars.ownerId = $event.ownerId;
      };
  };
  
  if ($utils.isDefined($event.projectId)) {
      if (!$utils.isDefined($editor.vars.projectId)) {
          $editor.vars.projectId = $event.projectId;
      };
  };
  
  if ($utils.isDefined($event.orderTypeId)) {
      if ($event.orderTypeId === $editor.fields.order_type.control.value) {
          $editor.fields.order_type.control.readOnly = true;
      };
  };
  
  if ($utils.isDefined($event.carrierId)) {
      if (!$utils.isDefined($editor.vars.carrierId)) {
          $editor.vars.carrierId = $event.carrierId;
      };
  };
  
  if ($utils.isDefined($event.createdCount)) {
      $editor.vars.createdCount = $event.createdCount;
      if ($event.createdCount > 0) {
          $editor.toolbar.process.control.readOnly = false;
      } else {
          $editor.toolbar.process.control.readOnly = true;
      };
  };
  }
  under_development(event = null) {
    return this.under_developmentInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async under_developmentInternal(
    $editor: FootPrintManager_load_container_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
    this.logger.log('FootPrintManager', 'load_container_editor.under_development');
  //O.Arias - 07/17/2023
  
  await $shell.FootPrintManager.openErrorDialog('Under development.', 'This feature is currently under development.');
  }
  on_temperature_clicked(event = null) {
    return this.on_temperature_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_temperature_clickedInternal(
    $editor: FootPrintManager_load_container_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
    this.logger.log('FootPrintManager', 'load_container_editor.on_temperature_clicked');
  //O.Arias 07/18/2023   
  
  await $shell.FootPrintManager.opentemperature_capture_formDialog({ loadContainerId: $editor.inParams.loadContainerId });
  
  $editor.refresh();
  }
  on_appointment_clicked(event = null) {
    return this.on_appointment_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_appointment_clickedInternal(
    $editor: FootPrintManager_load_container_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
    this.logger.log('FootPrintManager', 'load_container_editor.on_appointment_clicked');
  //O.Arias - 07/27/2023
  
  const dockAppointments = (await $datasources.DockAppointments.ds_get_dock_appointment_by_loadContainerId.get({
      loadContainerId: $editor.inParams.loadContainerId
  })).result;
  
  if ($utils.isDefined(dockAppointments)) {
  
      const dialogResult = await $shell.FootPrintManager.opendock_appointment_editorDialog({
          dockAppointmentId: dockAppointments[0].DockAppointmentId,
          loadContainerId: $editor.inParams.loadContainerId
      });
  
  } else {
  
      const nextAppointmentId = (await $flows.Utilities.reserve_nextId_flow({ entity: 'DockAppointment' })).nextId;
  
      const dialogResult = await $shell.FootPrintManager.opendock_appointment_creation_formDialog({
         
          warehouseId: [$editor.fields.warehouse.control.value],
          lookupcode: nextAppointmentId.toString(),
          scheduledArrival: $utils.isDefined($editor.entity.AvailableDate) ? $editor.entity.InYardDate : $utils.date.now(),
          scheduledDeparture: $utils.isDefined($editor.entity.CheckOutDate) ? $utils.date.add(1, 'hour', $editor.entity.AvailableDate) : $utils.date.add(1, 'hour', $utils.date.now()),
          typeId: 1,
          ownerId:  $editor.vars.ownerId,
          projectId: $editor.vars.projectId,
          carrierId: $editor.vars.carrierId,
          loadContainerId: $editor.inParams.loadContainerId
      });
  
  }
  
  // Always refresh as the user might have made some changes to an existing address
  await $editor.refresh();
  
  }
  on_process_clicked(event = null) {
    return this.on_process_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_process_clickedInternal(
    $editor: FootPrintManager_load_container_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
    this.logger.log('FootPrintManager', 'load_container_editor.on_process_clicked');
  
  const orders = (await $datasources.LoadContainers.ds_orders_by_load_container_grid.get({ loadContainerId: $editor.entity.Id })).result;
  
  const inbound_orders = orders.filter(o => o.OrderClass.OrderTypeId === 1 && o.OrderStatusId === 1);
  const outbound_orders = orders.filter(o => o.OrderClass.OrderTypeId === 2 && o.OrderStatusId === 1);
  
  let selectedRows: typeof outbound_orders = [];
  
  //Process inbound orders
  if (inbound_orders.length > 0) {
  
      selectedRows = inbound_orders;
  
      if (selectedRows.length === 0) {
          $shell.FootPrintManager.openErrorDialog('Error processing orders', 'No orders selected.');
          return;
      } else {
          const candidates = [];
          const failures = [];
          const errors = [];
  
          for (const row of selectedRows) {
              const flowParams = {
                  orderId: row.Id
              };
  
              if (row.OrderClass.OrderClassTypeId === 1) {
                  var confirmResult = await $flows.PurchaseOrders.is_purchase_order_processable_flow(flowParams);
              }
              else if (row.OrderClass.OrderClassTypeId === 12) {
                  confirmResult = await $flows.AsnOrders.is_asn_order_processable_flow({ orderId: row.Id });
              }
  
              const reasons = confirmResult.reasons;
              if ($utils.isDefined(reasons)) {
                  failures.push(row);
                  errors.push(`Order ${row.LookupCode} ${(await $flows.Utilities.grammar_format_string_array_flow({ values: reasons })).formattedValue}`);
              } else {
                  candidates.push(row);
              }
          }
  
          // no candidate
          if (candidates.length === 0) {
              const title = 'Error processing orders';
              const errorMessage = `Order(s) ${failures.map(c => c.LookupCode).join(',')} cannot be processed`;
              const errorList = errors;
              await $shell.FootPrintManager.openErrorDialog(title, errorMessage, errorList);
              return;
          } else {
              const confirmCandidates = `Order(s) ${candidates.map(c => c.LookupCode).join(',')} - Once processed, the order can be received.`
  
              let confirm = false;
              if (failures.length >= 1) {
                  const title = 'Some order(s) cannot be processed';
                  const message = `Do you still want to continue?\r\n\r\n ${confirmCandidates}\r\n\r\n ${errors.join('\r\n\r\n')}`;
                  confirm = await $shell.FootPrintManager.openConfirmationDialog(title, message);
              } else {
                  const title = 'Process the following order(s)';
                  const message = confirmCandidates;
                  confirm = await $shell.FootPrintManager.openConfirmationDialog(title, message, 'Proceed');
              }
  
              if (confirm) {
                  const candidateSuccess = [];
                  const candidateFailures = [];
                  const errorMsgList = [];
                  const errorMsgListDetails = [];
  
                  for (const candidate of candidates) {
                      try {
                          const flowParams = {
                              orderId: candidate.Id
                          }
  
                          let reasons: string[];
                          if (candidate.OrderClass.OrderClassTypeId === 1) {
                              var result = await $flows.PurchaseOrders.process_purchase_order_flow(flowParams);
                              if ($utils.isDefined(result.reason)) {
                                  reasons = [result.reason];
                              }
                          }
                          else if (candidate.OrderClass.OrderClassTypeId === 12) {
                              reasons = (await $flows.AsnOrders.process_asn_order_flow({ orderId: candidate.Id })).reasons;
                          }
  
                          if ($utils.isDefined(reasons)) {
                              candidateFailures.push(candidate);
                              errorMsgList.push(`Order ${candidate.LookupCode} ${(await $flows.Utilities.grammar_format_string_array_flow({ values: reasons })).formattedValue}`);
                          } else {
                              candidateSuccess.push(candidate);
                          }
  
                      } catch (error) {
                          candidateFailures.push(candidate);
                          const errorMessage = $utils.isDefined(error?.error?.error) ? error?.error?.error.message : error;
                          const errorDetail = $utils.isDefined(error?.error?.error) ? error?.error?.error : error;
                          const errorDescription = `Order ${candidate.LookupCode} - ${errorMessage}`;
                          errorMsgList.push(errorDescription);
                          errorMsgListDetails.push({ message: errorDescription, detail: errorDetail });
                      }
                  }
  
                  // all succeeded
                  if (candidateSuccess.length === candidates.length) {
                      const title = 'All order(s) processed';
                      const message = `Order(s) ${candidateSuccess.map(c => c.LookupCode).join(',')} processed`;
                      await $shell.FootPrintManager.openInfoDialog(title, message);
                      await $editor.refresh();
                  } else {
                      // all failures
                      if (candidateFailures.length === candidates.length) {
                          const title = 'All order(s) failed to process';
                          const message = `Order(s) ${candidateFailures.map(c => c.LookupCode).join(',')} could not be processed`;
                          await $shell.FootPrintManager.openErrorDialog(title, message, errorMsgList, null, errorMsgListDetails);
                      } else {
                          const title = 'Some order(s) could not be processed';
                          const success = `Order(s) ${candidateSuccess.map(c => c.LookupCode).join(',')} were processed.`;
                          const errors = `The following order(s) could not be processed: ${candidateFailures.map(c => c.LookupCode).join(',')}`;
                          const message = `${success} \r\n\r\n${errors}`;
                          await $shell.FootPrintManager.openErrorDialog(title, message, errorMsgList, null, errorMsgListDetails);
                          await $editor.refresh();
                      }
                  }
              }
          }
      }
  };
  
  //Process Outbounds
  if (outbound_orders.length > 0) {
  
      selectedRows = outbound_orders;
  
      if (selectedRows.length === 0) {
          $shell.FootPrintManager.openErrorDialog('Order Process Error', 'No orders selected.');
          return;
      }
      else {
          let ordersSucceeded: { orderId: number, orderCode: string }[] = [];
          let ordersFailed: { orderId: number, orderCode: string, errorMessage: string }[] = [];
  
          /*** Prompt for processing sales order options ***/
          const dialogResult = await $shell.FootPrintManager.opensales_orders_processing_options_formDialog({
              isMultiShipment: false,
              waveOptionDefaultCreate: false
          }, 'modal');
  
          if (!dialogResult.confirm) { return; }
  
          dialogResult.waveOptions.priority = $utils.isDefined(dialogResult.waveOptions.priority) ? dialogResult.waveOptions.priority : 1;
          dialogResult.waveOptions.description = $utils.isDefined(dialogResult.waveOptions.description) ? dialogResult.waveOptions.description : `Load Container ${$editor.entity.LookupCode} - Created via processing flow.`
          dialogResult.waveOptions.isCreateManualAllocationTasks = dialogResult.waveOptions.isCreateManualAllocationTasks ?? false
  
          /*** Validate that selected shipments are part of the same warehouse ***/
          if (dialogResult.waveOptions.groupOnWave) {
              var warehouseCheck = null;
              var firstWarehouse = null;
  
              for (const row of selectedRows) {
  
                  const shipment = (await $datasources.SalesOrders.ds_get_shipments_by_shipmentId.get({
                      shipmentId: row.ShipmentOrderLookups[0].ShipmentId
                  })).result;
                  warehouseCheck = shipment.ExpectedWarehouseId;
                  if (firstWarehouse == null) {
                      firstWarehouse = warehouseCheck;
                  }
                  if (warehouseCheck != firstWarehouse) {
                      $shell.FootPrintManager.openErrorDialog('Process Error', 'Selected orders are not part of the same warehouse, group on a single wave requested.');
                      return;
                  }
              }
  
              // Create single wave
              const wave = await $flows.SalesOrders.create_single_wave_flow({
                  warehouseId: warehouseCheck,
                  description: dialogResult.waveOptions.description,
                  priorityId: dialogResult.waveOptions.priority
              });
  
              if ($utils.isDefined(wave)) {
                  var groupedWaveId = wave.waveId;
              }
              else {
                  $shell.FootPrintManager.openErrorDialog('Process Error', 'Error creating single wave');
                  return;
              }
          }
  
          let targetState = 2 // Process order
          if (dialogResult.waveOptions.isCreateWave) { targetState = 3; }
          if (dialogResult.waveOptions.isProcessWave) { targetState = 4; }
          if (dialogResult.waveOptions.isReleaseWave) { targetState = 5; }
  
          const orders = selectedRows.map(o => {
              return {
                  orderId: o.Id,
                  orderStatusId: o.OrderStatusId,
                  orderStatus: o.Status.Name,
                  requestedDeliveryDate: o.RequestedDeliveryDate,
                  shipmentId: o.ShipmentOrderLookups[0]?.ShipmentId,
                  expectedDate: o.ShipmentOrderLookups[0]?.Shipment.ExpectedDate,
                  containerSize: o.ShipmentOrderLookups[0]?.Shipment.ContainerSize,
                  shipmentStatusId: o.ShipmentOrderLookups[0]?.Shipment.StatusId,
                  waveId: o.ShipmentOrderLookups[0]?.Shipment.WaveId,
                  waveStatus: o.ShipmentOrderLookups[0]?.Shipment.Wave?.Status.Name,
                  waveStatusId: o.ShipmentOrderLookups[0]?.Shipment.Wave?.StatusId
              }
          });
  
          const order_states = (await $flows.SalesOrders.get_sales_order_state({ orders: orders })).states;
          const state_array = order_states.map(row => row.orderId);
  
          /*** Process orders ***/
          for (const row of selectedRows) {
              try {
                  const result = await $flows.SalesOrders.is_sales_order_processable_flow({ orderId: row.Id });
                  if ($utils.isDefined(result.reason)) {
                      throw new Error(result.reason);
                  }
  
                  let orderIndex = -1;
                  let orderId = row.Id;
                  let stateId: number = row.OrderStatusId;
                  let stateName: string = row.Status.Name;
  
                  orderIndex = state_array.indexOf(orderId)
                  if (orderIndex > -1) {
                      stateId = order_states[orderIndex].stateId;
                      stateName = order_states[orderIndex].stateName;
                  };
  
                  for (let i = 0; i < targetState; i++) {
  
                      if (stateId > 0) {
                          if (stateId < 5) {
                              switch (stateId) {
                                  case 1: {
                                      // Check if order is soft allocatable
                                      const soft = await $flows.SalesOrders.is_sales_order_soft_allocatable_flow({
                                          orderId: row.Id
                                      });
  
                                      if ($utils.isDefined(soft.reason)) { throw new Error(soft.reason); }
  
                                      // Process the order
                                      const result = await $flows.SalesOrders.process_sales_order_flow({
                                          order_id: row.Id,
                                          shipment_id: row.ShipmentOrderLookups[0].ShipmentId
                                      });
  
                                      if ($utils.isDefined(result.reason)) { throw new Error(result.reason); }
  
                                      break;
                                  }
                                  case 2: {
                                      if (dialogResult.waveOptions.groupOnWave) {
                                          // Update shipment with waveId
                                          await $flows.Utilities.crud_update_flow({
                                              entitySet: 'Shipments',
                                              id: row.ShipmentOrderLookups[0].ShipmentId,
                                              entity: { WaveId: groupedWaveId }
                                          });
  
                                          break;
                                      }
                                      else {
                                          const waveResult = await $flows.SalesOrders.create_wave_sales_order_flow({
                                              orderId: row.Id,
                                              shipmentId: row.ShipmentOrderLookups[0].ShipmentId
                                          });
  
                                          if ($utils.isDefined(waveResult.reason)) {
                                              throw new Error(waveResult.reason)
                                          }
  
                                          break;
                                      }
  
                                  }
                                  case 3: {
                                      var shipment = (await $datasources.SalesOrders.ds_get_shipments_by_shipmentId.get({
                                          shipmentId: row.ShipmentOrderLookups[0].ShipmentId
                                      })).result;
  
                                      if (dialogResult.waveOptions.groupOnWave && shipment.WaveId == groupedWaveId) {
                                          // Check if candidate was already added
                                          const rowFound = ordersSucceeded.find(
                                              element => element.orderId === row.Id
  
                                          );
  
                                          break;
                                      }
                                      else {
                                          const waveResult = await $flows.SalesOrders.process_wave_sales_order_flow({
                                              orderId: row.Id,
                                              shipmentId: row.ShipmentOrderLookups[0].ShipmentId,
                                              is_create_manual_allocation_tasks: dialogResult.waveOptions.isCreateManualAllocationTasks
                                          });
  
                                          if ($utils.isDefined(waveResult.reason)) { throw new Error(waveResult.reason); }
  
                                          break;
                                      }
                                  }
                                  case 4: {
                                      var shipment = (await $datasources.SalesOrders.ds_get_shipments_by_shipmentId.get({
                                          shipmentId: row.ShipmentOrderLookups[0].ShipmentId
                                      })).result;
  
                                      if (dialogResult.waveOptions.groupOnWave && shipment.WaveId == groupedWaveId) {
                                          break;
                                      }
                                      else {
                                          const waveResult = await $flows.SalesOrders.release_wave_sales_order_flow({
                                              orderId: row.Id,
                                              shipmentId: row.ShipmentOrderLookups[0].ShipmentId
                                          });
  
                                          if ($utils.isDefined(waveResult.reason)) { throw new Error(waveResult.reason); }
  
                                          ordersSucceeded.push({ orderId: row.Id, orderCode: row.LookupCode });
                                      }
  
                                      break;
                                  }
                              }
                          }
                      }
                  }
              }
              catch (error) {
                  let targetError = error;
                  while ($utils.isDefined(targetError.error)) {
                      targetError = targetError.error;
                  }
  
                  ordersFailed.push({
                      orderId: row.Id,
                      orderCode: row.LookupCode,
                      errorMessage: targetError.message
                  });
              }
          }
  
          let candidates = selectedRows.filter(r => !ordersFailed.find(f => f.orderId === r.Id));
  
          // If group by wave, process and release wave now
          if (dialogResult.waveOptions.groupOnWave) {
              // Abort if no candidates or if set to abort when there are errors
              if (candidates.length > 0 && (candidates.length === selectedRows.length || !dialogResult.waveOptions.abortIfErrors)) {
                  const waveResult = await $flows.SalesOrders.process_single_wave_flow({
                      waveId: groupedWaveId,
                      createManualAllocationTasks: false
                  });
  
                  if ($utils.isDefined(waveResult.reason)) {
                      $shell.FootPrintManager.openErrorDialog('Process Single Wave Error', `${waveResult.reason}`);
                      for (const candidate of candidates) {
                          ordersFailed.push({ orderId: candidate.Id, orderCode: candidate.LookupCode, errorMessage: waveResult.reason });
                      }
                  }
                  else {
                      const waveReleaseResult = await $flows.SalesOrders.release_single_wave_flow({ waveId: groupedWaveId });
                      if ($utils.isDefined(waveReleaseResult.reason)) {
                          $shell.FootPrintManager.openErrorDialog('Release Single Wave Error', `${waveReleaseResult.reason}`);
                          for (const candidate of candidates) {
                              ordersFailed.push({ orderId: candidate.Id, orderCode: candidate.LookupCode, errorMessage: waveReleaseResult.reason });
                          }
                      }
                      else {
                          for (const candidate of candidates) {
                              ordersSucceeded.push({ orderId: candidate.Id, orderCode: candidate.LookupCode });
                          }
                      }
                  }
              }
              else {
                  for (let row of selectedRows) {
                      let isRevertable = (await $flows.SalesOrders.is_sales_order_revertable_flow({ orderId: row.Id, shipmentId: row.ShipmentOrderLookups[0]?.ShipmentId }));
                      if (!$utils.isDefined(isRevertable.reason)) {
                          await $flows.SalesOrders.revert_sales_order_flow({ orderId: row.Id, shipmentId: row.ShipmentOrderLookups[0]?.ShipmentId })
                      }
  
                      if (!ordersFailed.find(o => o.orderId === row.Id)) {
                          ordersFailed.push({ orderId: row.Id, orderCode: row.LookupCode, errorMessage: 'Aborted because errors exist on other orders for this wave' });
                      }
                  }
  
                  // Cancel PickSlips
                  let pickslips = (await $datasources.SalesOrders.ds_get_pickslips_by_waveId.get({ waveId: groupedWaveId })).result;
                  for (let pickslip of pickslips) {
                      // Cancel if not already cancelled or completed
                      if (pickslip.StatusId !== 8 && pickslip.StatusId !== 4) {
                          await $flows.Utilities.crud_update_flow({
                              entitySet: 'PickSlips',
                              id: pickslip.Id,
                              entity: {
                                  StatusId: 8
                              }
                          });
                      }
                  }
  
                  // Cancel wave
                  await $flows.Utilities.crud_update_flow({
                      entitySet: 'Waves',
                      id: groupedWaveId,
                      entity: {
                          StatusId: 5
                      }
                  });
              }
          }
  
          // all succeeded
          if (ordersSucceeded.length === selectedRows.length) {
              await $shell.FootPrintManager.openInfoDialog('All order(s) processed', `Order(s) ${ordersSucceeded.map(c => c.orderCode).join(',')} processed`);
              await $editor.refresh();
          }
          // all failed
          else if (ordersFailed.length === selectedRows.length) {
              await $shell.FootPrintManager.openErrorDialog('All order(s) failed to process',
                  `Order(s) ${ordersFailed.map(c => c.orderCode).join(',')} could not be processed`,
                  null,
                  'Errors',
                  ordersFailed.map(o => ({ message: `Order ${o.orderCode}`, detail: o.errorMessage })));
          }
          // Some succeeded, some failed
          else {
              await $shell.FootPrintManager.openErrorDialog('Some order(s) could not be processed',
                  `Order(s) ${ordersSucceeded.map(c => c.orderCode).join(',')} were processed.`,
                  null,
                  'Errors',
                  ordersFailed.map(o => ({ message: `Order ${o.orderCode}`, detail: o.errorMessage })));
              await $editor.refresh();
          }
      }
  };
  }
  apply_operations(event = null) {
    return this.apply_operationsInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async apply_operationsInternal(
    $editor: FootPrintManager_load_container_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
    this.logger.log('FootPrintManager', 'load_container_editor.apply_operations');
  //Get the temperature capture operation
  
  if (await $operations.FootPrintManager.Disable_Temperatures_Entry.isAuthorized()) {
      $editor.tabs.temperatures.hidden = true;
  };
  }
  on_attachments_clicked(event = null) {
    return this.on_attachments_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_attachments_clickedInternal(
    $editor: FootPrintManager_load_container_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
    this.logger.log('FootPrintManager', 'load_container_editor.on_attachments_clicked');
  await $shell.FootPrintManager.openentity_attachments_gridDialog({ entityType: 'LoadContainer', entityKeys: [{ name: 'Id', value: $editor.entity.Id }]});
  }
  on_warehouse_change(event = null) {
    return this.on_warehouse_changeInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_warehouse_changeInternal(
    $editor: FootPrintManager_load_container_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
    this.logger.log('FootPrintManager', 'load_container_editor.on_warehouse_change');
  //O.Arias - 05/03/2024
  
  if ($utils.isDefined($editor.fields.warehouse.control.value)) {
      $editor.fields.yard_location.control.readOnly = false;
      $editor.vars.warehouseId = $editor.fields.warehouse.control.value;
  } else {
      $editor.fields.yard_location.control.readOnly = true;
      $editor.vars.warehouseId = 0;
  }
  }
  on_order_type_change(event = null) {
    return this.on_order_type_changeInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_order_type_changeInternal(
    $editor: FootPrintManager_load_container_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
    this.logger.log('FootPrintManager', 'load_container_editor.on_order_type_change');
  
  
  try{
  
  let load_container_id:number = $editor.inParams.loadContainerId
  const load_container_payload: any = {};
  load_container_payload.orderTypeId = $editor.fields.order_type.control.value
  
   $flows.Utilities.crud_update_flow({
   entitySet: 'LoadContainers',
   id: load_container_id,
   entity: load_container_payload
   })
  
   } catch (error) {
      const errorMessage = $utils.isDefined(error?.error?.error) ? error?.error?.error.message : error;
      const errorDetail = $utils.isDefined(error?.error?.error) ? error?.error?.error : error;
      const errorDescription = `Load Container ${$editor.entity.LookupCode} - ${errorMessage}`;
      await $shell.FootPrintManager.openErrorDialog('Update load container error', 'An error occurred during order type change', [errorDescription], null, [{ message: errorDescription, detail: errorDetail }]);
  }
  }
  //#endregion private flows
}
