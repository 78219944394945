import { 
  Component, 
  OnInit,
  OnChanges,
  OnDestroy,
  Input,
  SimpleChanges,
  Output,
  EventEmitter,
  ViewChild,
  Inject,
  forwardRef
} from '@angular/core';

import { 
  FormGroup,
  Validators 
} from '@angular/forms';

import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { isEqual, isNil, isEmpty } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  SeparatorModel,
  ButtonStyles 
} from './models/control';
import { Styles, ControlContainerStyles } from './models/style';
import { FieldModel } from './models/field';
import { ToolModel } from './models/tool';
import { TabItemModel, TabGroupModel } from './models/tab';
import { WidgetModel } from './models/widget';
import { FieldsetModel } from './models/fieldset';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootPrintManager_ShellService, EModalSize, EToasterType, EToasterPosition } from './FootPrintManager.shell.service';
import { FootPrintManager_OperationService } from './FootPrintManager.operation.service';
import { FootPrintManager_DatasourceService } from './FootPrintManager.datasource.index';
import { FootPrintManager_FlowService } from './FootPrintManager.flow.index';
import { FootPrintManager_ReportService } from './FootPrintManager.report.index';
import { FootPrintManager_LocalizationService } from './FootPrintManager.localization.service';
import { Language } from './localization.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './FootPrintManager.frontend.types'
import { $frontendTypes as $types} from './FootPrintManager.frontend.types' 


import { FootPrintManager_activity_gridComponent } from './FootPrintManager.activity_grid.component';
import { ExcelMaterialImport_owners_dd_singleComponent } from './ExcelMaterialImport.owners_dd_single.component'
import { ExcelMaterialImport_projects_dd_multiComponent } from './ExcelMaterialImport.projects_dd_multi.component'
import { ExcelOrderImport_warehouses_dd_multiComponent } from './ExcelOrderImport.warehouses_dd_multi.component'
import { Invoices_operation_codes_dd_multiComponent } from './Invoices.operation_codes_dd_multi.component'
import { Inventory_task_statuses_dd_multiComponent } from './Inventory.task_statuses_dd_multi.component'

@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => FootPrintManager_activity_gridComponent),
    forwardRef(() => ExcelMaterialImport_owners_dd_singleComponent),
    forwardRef(() => ExcelMaterialImport_projects_dd_multiComponent),
    forwardRef(() => ExcelOrderImport_warehouses_dd_multiComponent),
    forwardRef(() => Invoices_operation_codes_dd_multiComponent),
    forwardRef(() => Inventory_task_statuses_dd_multiComponent),
  ],
  selector: 'FootPrintManager-activity_hub',
  templateUrl: './FootPrintManager.activity_hub.component.html'
})
export class FootPrintManager_activity_hubComponent extends BaseComponent implements OnInit, OnDestroy {


  @Input() showInDialog: boolean = false; 
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();  
  //#endregion Outputs
  hasToolbar: boolean = true;



  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  //#region Variables
  //#endregion
  formGroup: FormGroup = new FormGroup({
    owner: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    project: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    warehouse: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    operation_code: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    status: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
  });
  
  toolbar = {
  };

  actionbar = {
  };

 filters = {
    owner: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['owner'] as DatexFormControl, 
  null, null,
  false, 
  '')
, new ControlContainerStyles(null, null), 'Owner', false)
,
    project: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['project'] as DatexFormControl, 
  null, null,
  false, 
  '')
, new ControlContainerStyles(null, null), 'Project', false)
,
    warehouse: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['warehouse'] as DatexFormControl, 
  null, null,
  false, 
  '')
, new ControlContainerStyles(null, null), 'Warehouse', false)
,
    operation_code: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['operation_code'] as DatexFormControl, 
  null, null,
  false, 
  '')
, new ControlContainerStyles(null, null), 'Operation codes', false)
,
    status: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['status'] as DatexFormControl, 
  null, null,
  false, 
  '')
, new ControlContainerStyles(null, null), 'Statuses', false)
,
  };


  filtersets = {
  newGroup1: new FieldsetModel('Label of newGroup1', true, false, true),
};

    rootTabGroup = new TabGroupModel();
  
    subTabGroups = {
    };
  
    onTabSelected(event: MatSelectChange) {
      event.value.activate();
    }
  
    tabs = {
      activity: new TabItemModel(
        this.rootTabGroup, 
        'Activity', 
        ),
    };
  
    //#region tabs inParams
    cacheValueFor_$tabs_activity_activity_grid_inParams_project_ids: number[];
    get $tabs_activity_activity_grid_inParams_project_ids(): number[] {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.filters.project.control.value;
      
      if(!isEqual(this.cacheValueFor_$tabs_activity_activity_grid_inParams_project_ids, expr)) {
        this.cacheValueFor_$tabs_activity_activity_grid_inParams_project_ids = expr;
      }
      return this.cacheValueFor_$tabs_activity_activity_grid_inParams_project_ids;
    }
  
    cacheValueFor_$tabs_activity_activity_grid_inParams_owner_ids: number[];
    get $tabs_activity_activity_grid_inParams_owner_ids(): number[] {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.filters.owner.control.value;
      
      if(!isEqual(this.cacheValueFor_$tabs_activity_activity_grid_inParams_owner_ids, expr)) {
        this.cacheValueFor_$tabs_activity_activity_grid_inParams_owner_ids = expr;
      }
      return this.cacheValueFor_$tabs_activity_activity_grid_inParams_owner_ids;
    }
  
    cacheValueFor_$tabs_activity_activity_grid_inParams_warehouse_ids: number[];
    get $tabs_activity_activity_grid_inParams_warehouse_ids(): number[] {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.filters.warehouse.control.value;
      
      if(!isEqual(this.cacheValueFor_$tabs_activity_activity_grid_inParams_warehouse_ids, expr)) {
        this.cacheValueFor_$tabs_activity_activity_grid_inParams_warehouse_ids = expr;
      }
      return this.cacheValueFor_$tabs_activity_activity_grid_inParams_warehouse_ids;
    }
  
    cacheValueFor_$tabs_activity_activity_grid_inParams_operation_codes: number[];
    get $tabs_activity_activity_grid_inParams_operation_codes(): number[] {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.filters.operation_code.control.value;
      
      if(!isEqual(this.cacheValueFor_$tabs_activity_activity_grid_inParams_operation_codes, expr)) {
        this.cacheValueFor_$tabs_activity_activity_grid_inParams_operation_codes = expr;
      }
      return this.cacheValueFor_$tabs_activity_activity_grid_inParams_operation_codes;
    }
  
    cacheValueFor_$tabs_activity_activity_grid_inParams_status_ids: number[];
    get $tabs_activity_activity_grid_inParams_status_ids(): number[] {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.filters.status.control.value;
      
      if(!isEqual(this.cacheValueFor_$tabs_activity_activity_grid_inParams_status_ids, expr)) {
        this.cacheValueFor_$tabs_activity_activity_grid_inParams_status_ids = expr;
      }
      return this.cacheValueFor_$tabs_activity_activity_grid_inParams_status_ids;
    }
  
    get $tabs_activity_activity_grid_inParams_seven_days_before_flag(): boolean {
      const $hub = this;
      const $utils = this.utils;
      const expr = true;
      
      return expr;
    }
  
    //#endregion tabs inParams
  
    //#region tabs children
      @ViewChild('$tabs_activity', { read: FootPrintManager_activity_gridComponent }) $tabs_activity: FootPrintManager_activity_gridComponent;
    //#endregion tabs children

  //#region filters inParams
  get $fields_owner_selector_inParams_statusId(): number {
    const $hub = this;
    const $utils = this.utils;
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //const $l10n = this.localization;
    const expr = 1;
    
    return expr;
  }



  get $fields_owner_selector_inParams_projectId(): number {
    const $hub = this;
    const $utils = this.utils;
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //const $l10n = this.localization;
    const expr = $hub.filters.project.control.value;
    
    return expr;
  }



  get $fields_project_selector_inParams_ownerId(): number {
    const $hub = this;
    const $utils = this.utils;
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //const $l10n = this.localization;
    const expr = $hub.filters.owner.control.value;
    
    return expr;
  }



  //#endregion filters inParams

  get hubTitle(): string {
    const $hub = this;
    const $utils = this.utils;
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //const $l10n = this.localization;
    return `Activity Hub`;
  }

  get hubDescription(): string {
    const $hub = this;
    const $utils = this.utils;
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //const $l10n = this.localization;
    return `Your landing page to view Footprint Activity.`;
  }

  constructor(
  private utils: UtilsService,
  private settings: SettingsValuesService,
  private shell: FootPrintManager_ShellService,
  private datasources: FootPrintManager_DatasourceService,
  private flows: FootPrintManager_FlowService,
  private reports: FootPrintManager_ReportService,
  private localization: FootPrintManager_LocalizationService,
  private operations: FootPrintManager_OperationService,
  private logger: CleanupLoggerService,
  ) { 
    super();
    this.$subscribeFormControlValueChanges();
    this.hasToolbar = !isEmpty(this.toolbar);

    //#region tabs tab init
    this.rootTabGroup.tabs = [
      this.tabs.activity,
    ]; 
    //#endregion tabs tab init
  }

  ngOnInit(): void {
    this.$init();
  }
  

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }



  initialized = false;

  async $init() {
    this.title = 'Activity Hub';
    
    const $hub = this;
    const $utils = this.utils;

    (this.filters.status.control as SelectBoxModel).reset([1,4]);


    this.initialized = true;
  }

  private $subscribeFormControlValueChanges() {
    this.formGroup
      .controls['owner']
      .valueChanges
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_owner_change();
      });
  }
  close() {
    this.$finish.emit();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = Promise.resolve(null);
    
    // children
    if (skipChildren === false) {
      this.$refreshChildren(childToSkip);
    }

    return result;
  }

  $refreshChildren(childToSkip: string) {
    //#region tabs children
    if (childToSkip !== '$tabs_activity') {
      if (!isNil(this.$tabs_activity) && !this.tabs.activity.hidden) {
        this.$tabs_activity.refresh(true, false, null);
      }
    }
    //#endregion tabs children
  }

  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }

  //#region private flows
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $hub: FootPrintManager_activity_hubComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
    this.logger.log('FootPrintManager', 'activity_hub.on_init');
  
  
  }
  on_project_change(event = null) {
    return this.on_project_changeInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_project_changeInternal(
    $hub: FootPrintManager_activity_hubComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
    this.logger.log('FootPrintManager', 'activity_hub.on_project_change');
  const projectId = $hub.filters.project.control.value
  
  
  // Set Owner from Project
  if ($utils.isDefined(projectId) && !$utils.isDefined($hub.filters.owner.control.value)) {
      const project = (await $datasources.Materials.ds_get_project_by_projectId.get({
          projectId: projectId
      })).result;
     
      if ($utils.isDefined(project)) {
  
          $hub.filters.owner.control.value = project[0].OwnerId;
  
      }
  
  }
  
  }
  on_owner_change(event = null) {
    return this.on_owner_changeInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_owner_changeInternal(
    $hub: FootPrintManager_activity_hubComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
    this.logger.log('FootPrintManager', 'activity_hub.on_owner_change');
  $hub.filters.project.control.value = null;
  }
  //#endregion private flows
}
