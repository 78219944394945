import { Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { CleanupLoggerService } from './cleanup.logging.service';
import { ReportBaseService } from './report-base.service';

import { UtilsService } from './utils.service';
import { ShippingContainers_DatasourceService } from './ShippingContainers.datasource.index';

interface IShippingContainers_shipping_label_reportServiceInParams {
  shippingContainerId: number}

interface IShippingContainers_shipping_label_reportServiceData {
}

@Injectable({ providedIn: 'root' })
export class ShippingContainers_shipping_label_reportService extends ReportBaseService<IShippingContainers_shipping_label_reportServiceInParams, IShippingContainers_shipping_label_reportServiceData> {

  protected reportReferenceName = 'shipping_label_report';
  protected appReferenceName = 'ShippingContainers';

  constructor(
    utils: UtilsService,
    private datasources: ShippingContainers_DatasourceService,
    private logger: CleanupLoggerService
    ) {
      super(utils);
      logger.log('ShippingContainers', 'shipping_label_report');
  }

  override throwIfMissingRequiredInParams (inParams: IShippingContainers_shipping_label_reportServiceInParams) {
  }

  protected async getData(inParams: IShippingContainers_shipping_label_reportServiceInParams): Promise<IShippingContainers_shipping_label_reportServiceData> 
  {
    const $utils = this.utils;
    const $report: { inParams: IShippingContainers_shipping_label_reportServiceInParams } = {
      inParams: inParams
    };

    const data: IShippingContainers_shipping_label_reportServiceData = {
    };
    const datasourcesQueries: (() => Promise<void>)[] = [];


    await Promise.all(datasourcesQueries.map(t => t()));

    return data;
  }
}
