import { Component, OnInit, Input, SimpleChanges, OnChanges, EventEmitter, Output, ViewChild, Inject, OnDestroy, forwardRef } from '@angular/core';
import { FormGroup, FormControl, Validators} from '@angular/forms';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles 
} from './models/control';
import { GridHeaderModel, GridCellModel, GridRowModel, CellStyles, GridContainerStyle, HeaderStyles } from './models/grid'
import { FieldModel } from './models/field'
import { ToolModel } from './models/tool';
import { Styles, ControlContainerStyles } from './models/style';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { isEqual, isNil, set } from 'lodash-es';
import { GridComponent, ELoadingStatus } from './components/grid.component';
import { BaseComponent } from './components/base.component';
import { WorkBook, read as readExcelFile, writeFile as writeExcelFile, utils as excelUtils } from 'xlsx';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { Invoices_ShellService, EModalSize, EToasterType, EToasterPosition } from './Invoices.shell.service';
import { Invoices_OperationService } from './Invoices.operation.service';
import { Invoices_DatasourceService } from './Invoices.datasource.index';
import { Invoices_FlowService } from './Invoices.flow.index';
import { Invoices_ReportService } from './Invoices.report.index';
import { Invoices_LocalizationService } from './Invoices.localization.service';
import { Language } from './localization.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './Invoices.frontend.types'
import { $frontendTypes as $types} from './Invoices.frontend.types' 

import { Invoices_transaction_statuses_dd_singleComponent } from './Invoices.transaction_statuses_dd_single.component'


interface IInvoices_invoicing_transactions_gridComponentEntity {
transactionId?: string, status?: string, projectId?: number, billingContractId?: number, invoiceId?: number, instructionId?: string, process?: string, notes?: string, createdOn?: string, modifiedOn?: string, parentTransactionId?: string, requestId?: string, termId?: number, taxScheduleId?: number, shipmentId?: number, warehouseId?: number, oneInvoicePerShipment?: boolean, oneInvoicePerWarehouse?: boolean, billingAggregationStrategyIds?: number[], instructionName?: string, transactionType?: string, billingTaskId?: number, useExistingInvoice?: boolean}

interface IInvoices_invoicing_transactions_gridComponentInParams {
  billingContractId?: number, projectId?: number, invoiceId?: number, transactionId?: string, instructionId?: string, requestId?: string}


class Invoices_invoicing_transactions_gridComponentRowModel extends GridRowModel {
  grid: Invoices_invoicing_transactions_gridComponent;
  entity: IInvoices_invoicing_transactions_gridComponentEntity;



 // temporarily put required on all edit controls
  override formGroup = new FormGroup({
  });

  override cells = {
    id: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    type: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    instructionName: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    instructionId: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    status: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    projectId: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    billingContractId: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    invoiceId: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    shipmentId: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    warehouseId: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    billingTaskId: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    process: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    billingAggregationStrategyIds: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    notes: new GridCellModel(
      new CellStyles(['grid-table-cell-link'], null),
      new TextModel(null, null )
,
null
      ),
    transactionId: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    requestId: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    createdOn: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    modifiedOn: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
  }



  constructor(
    private utils: UtilsService,
private settings: SettingsValuesService,
private shell: Invoices_ShellService,
private datasources: Invoices_DatasourceService,
private flows: Invoices_FlowService,
private reports: Invoices_ReportService,
private localization: Invoices_LocalizationService,
private operations: Invoices_OperationService,
private logger: CleanupLoggerService,
) {
    super();
    
  }

  async $initializeExisting(grid: Invoices_invoicing_transactions_gridComponent, entity: IInvoices_invoicing_transactions_gridComponentEntity, propertyChangeCallback: (source: GridRowModel, property: string) => void = null) {
    this.$propertyChangeCallback = propertyChangeCallback;

    this.grid = grid;
    this.entity = entity;

    this.rowId = [this.entity.transactionId].join('-');

    await this.$dataLoaded();
  }

  async $initializeNew(grid: Invoices_invoicing_transactions_gridComponent, entity?: IInvoices_invoicing_transactions_gridComponentEntity, propertyChangeCallback: (source: GridRowModel, property: string) => void = null) {
    this.$propertyChangeCallback = propertyChangeCallback;

    this.grid = grid;
    this.isNew = true;

    if (entity) {
      this.entity = entity;
    }
    else {
      this.entity = {};
    }

    this.rowId = [this.entity.transactionId].join('-');
    this.$dataLoaded();
    // mark the whole form as dirty, since this is a new row 
    // and even if it is valid, it should have a changed state
    this.formGroup.markAsDirty();
  }

  private $unsubscribe$ = new Subject();
  override destroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  $init() {
  }

  async $dataLoad() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.utils;
    const $resultKey = this.entity.transactionId;
    const inParams = {
      $keys:[$resultKey],
      search:  $grid.fullTextSearch ,
      billingContractId:  $grid.inParams.billingContractId ,
      projectId:  $grid.inParams.projectId ,
      invoiceId:  $grid.inParams.invoiceId ,
      instructionId:  $grid.inParams.instructionId ,
      transactionId:  $grid.inParams.transactionId ,
      requestId:  $grid.inParams.requestId ,
      status:  $grid.filters.status.control.value ,
    };
    const data = await this.datasources.Invoices.ds_invoicing_transactions.getByKeys(inParams);
    this.entity = data.result[0];
    await this.$dataLoaded();
  }

  async $dataLoaded() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.utils;
    (this.cells.id.displayControl as TextModel).text = $row.entity.transactionId;
    (this.cells.type.displayControl as TextModel).text = $row.entity.transactionType;
    (this.cells.instructionName.displayControl as TextModel).text = $row.entity.instructionName;
    (this.cells.instructionId.displayControl as TextModel).text = $row.entity.instructionId;
    (this.cells.status.displayControl as TextModel).text = $row.entity.status;
    (this.cells.projectId.displayControl as TextModel).text = $row.entity.projectId?.toString();
    (this.cells.billingContractId.displayControl as TextModel).text = $row.entity.billingContractId?.toString();
    (this.cells.invoiceId.displayControl as TextModel).text = $row.entity.invoiceId?.toString();
    (this.cells.shipmentId.displayControl as TextModel).text = $row.entity.shipmentId?.toString();
    (this.cells.warehouseId.displayControl as TextModel).text = $row.entity.warehouseId?.toString();
    (this.cells.billingTaskId.displayControl as TextModel).text = $row.entity.billingTaskId?.toString();
    (this.cells.process.displayControl as TextModel).text = $row.entity.process;
    (this.cells.billingAggregationStrategyIds.displayControl as TextModel).text = $row.entity.billingAggregationStrategyIds?.join(",");
    (this.cells.transactionId.displayControl as TextModel).text = $row.entity.parentTransactionId;
    (this.cells.requestId.displayControl as TextModel).text = $row.entity.requestId;
    (this.cells.createdOn.displayControl as TextModel).text = $row.entity.createdOn?.toString();
    (this.cells.modifiedOn.displayControl as TextModel).text = $row.entity.modifiedOn?.toString();

    await this.on_row_data_loaded();
  }

  override async refresh() {
    await this.$dataLoad();
    // grid refresh skipSelf=true, skipParent=false, skipChildren=false  
    this.grid.$refreshEvent.emit();
  }

  override async save() {
    if (this.isNew) {
    } else {
    } 
  }

  override $cellClicked(cellId: string) {
    super.$cellClicked(cellId);
    switch(cellId) {
      case 'notes' : {
        this.on_notes_clicked();
        break;
      }
    }
  }

  //#region private flows
  on_row_data_loaded(event = null) {
    return this.on_row_data_loadedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_row_data_loadedInternal(
    $row: Invoices_invoicing_transactions_gridComponentRowModel,
  $grid: Invoices_invoicing_transactions_gridComponent, 
    $shell: Invoices_ShellService,
    $datasources: Invoices_DatasourceService,
    $flows: Invoices_FlowService,
    $reports: Invoices_ReportService,
    $settings: SettingsValuesService,
    $operations: Invoices_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Invoices_LocalizationService,
    $event: any
  ) {
    this.logger.log('Invoices', 'invoicing_transactions_grid.on_row_data_loaded');
  //O.Arias - 03/27/2024
  
  const format = `MM/DD/YYYY, LT`;
  
  if ($utils.isDefined(format)) {
      if ($utils.isDefined($row.entity.createdOn)) {
          $row.cells.createdOn.displayControl.text = $utils.date.format($row.entity.createdOn, format);
      };
      if ($utils.isDefined($row.entity.modifiedOn)) {
          $row.cells.modifiedOn.displayControl.text = $utils.date.format($row.entity.modifiedOn, format);
      };
  };
  
  let notes = $row.entity.notes;
  
  if ($utils.isDefined(notes)) {
      notes = (notes.length > 50) ? notes.substring(0, 50) : notes;
      $row.cells.notes.displayControl.text = notes;
  };
  }
  on_notes_clicked(event = null) {
    return this.on_notes_clickedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_notes_clickedInternal(
    $row: Invoices_invoicing_transactions_gridComponentRowModel,
  $grid: Invoices_invoicing_transactions_gridComponent, 
    $shell: Invoices_ShellService,
    $datasources: Invoices_DatasourceService,
    $flows: Invoices_FlowService,
    $reports: Invoices_ReportService,
    $settings: SettingsValuesService,
    $operations: Invoices_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Invoices_LocalizationService,
    $event: any
  ) {
    this.logger.log('Invoices', 'invoicing_transactions_grid.on_notes_clicked');
  //O.Arias - 04/09/2024
  
  let payload_json: any;
  
  let payload_string: string = $row.entity.notes;
  
  try {
      payload_json = JSON.parse(payload_string);
  } catch (e) {
  };
  
  if (!$utils.isDefined(payload_json)) {
      $shell.Invoices.openInfoDialog("Notes", payload_string);
  };
  try {
      if ($utils.isDefined(payload_json)) {
          await $shell.Invoices.openview_json_payloadDialog({ payload: payload_json, title: "Notes" });
      };
  
  } catch {
      $shell.Invoices.openInfoDialog("Notes", payload_string);
  };
  }
  //#endregion private flows

}


@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => Invoices_transaction_statuses_dd_singleComponent),
  ],
  selector: 'Invoices-invoicing_transactions_grid',
  templateUrl: './Invoices.invoicing_transactions_grid.component.html'
})
export class Invoices_invoicing_transactions_gridComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy {
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  entities: IInvoices_invoicing_transactions_gridComponentEntity[];

  pageSize = 25;
  pageSkip = 0;
  totalCount = 0;
  loadingStatus = ELoadingStatus.Loading;


// to stop add from flow
// canAdd = false; //hide add button, check on exitFromLastCell 
// bottomToolbar.addLine.hidden = true; //hide add button, no check on exitFromLastCell 
// bottomToolbar.addLine.control.readOnly = true; //disable add button, check on exitFromLastCell 
 
  
  containerStyles: GridContainerStyle = new GridContainerStyle('cellsWidth', ['fit-content-table']);

  fullTextSearch: string;

  inParams: IInvoices_invoicing_transactions_gridComponentInParams = { billingContractId: null, projectId: null, invoiceId: null, transactionId: null, instructionId: null, requestId: null };


  //#region Variables
  //#endregion
  //#region Events
  
  //#endregion

  headers = {
     id: new GridHeaderModel(new HeaderStyles(null, null), 'Id', false, false, null),       type: new GridHeaderModel(new HeaderStyles(null, null), 'Type', false, false, null),       instructionName: new GridHeaderModel(new HeaderStyles(null, null), 'Instruction name', false, false, null),       instructionId: new GridHeaderModel(new HeaderStyles(null, null), 'Instruction id', false, false, null),       status: new GridHeaderModel(new HeaderStyles(null, null), 'Status', false, false, null),       projectId: new GridHeaderModel(new HeaderStyles(null, null), 'Project id', false, false, null),       billingContractId: new GridHeaderModel(new HeaderStyles(null, null), 'Billing contract id', false, false, null),       invoiceId: new GridHeaderModel(new HeaderStyles(null, null), 'Invoice id', false, false, null),       shipmentId: new GridHeaderModel(new HeaderStyles(null, null), 'Shipment id', false, false, null),       warehouseId: new GridHeaderModel(new HeaderStyles(null, null), 'Warehouse id', false, false, null),       billingTaskId: new GridHeaderModel(new HeaderStyles(null, null), 'Billing task id', false, false, null),       process: new GridHeaderModel(new HeaderStyles(null, null), 'Process', false, false, null),       billingAggregationStrategyIds: new GridHeaderModel(new HeaderStyles(null, null), 'Billing aggregation strategy ids', false, false, null),       notes: new GridHeaderModel(new HeaderStyles(null, null), 'Notes', false, false, null),       transactionId: new GridHeaderModel(new HeaderStyles(null, null), 'Transaction id', false, false, null),       requestId: new GridHeaderModel(new HeaderStyles(null, null), 'Request id', false, false, null),       createdOn: new GridHeaderModel(new HeaderStyles(null, null), 'Created on', false, false, null),       modifiedOn: new GridHeaderModel(new HeaderStyles(null, null), 'Modified on', false, false, null),  
  };

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  rows: Invoices_invoicing_transactions_gridComponentRowModel[] = [];
  @ViewChild('$gridComponent', { read:  GridComponent}) $gridComponent: GridComponent;

  @Input('billingContractId') set $inParams_billingContractId(value: any) {
    this.inParams['billingContractId'] = value;
  }
  get $inParams_billingContractId(): any {
    return this.inParams['billingContractId'] ;
  }
  @Input('projectId') set $inParams_projectId(value: any) {
    this.inParams['projectId'] = value;
  }
  get $inParams_projectId(): any {
    return this.inParams['projectId'] ;
  }
  @Input('invoiceId') set $inParams_invoiceId(value: any) {
    this.inParams['invoiceId'] = value;
  }
  get $inParams_invoiceId(): any {
    return this.inParams['invoiceId'] ;
  }
  @Input('transactionId') set $inParams_transactionId(value: any) {
    this.inParams['transactionId'] = value;
  }
  get $inParams_transactionId(): any {
    return this.inParams['transactionId'] ;
  }
  @Input('instructionId') set $inParams_instructionId(value: any) {
    this.inParams['instructionId'] = value;
  }
  get $inParams_instructionId(): any {
    return this.inParams['instructionId'] ;
  }
  @Input('requestId') set $inParams_requestId(value: any) {
    this.inParams['requestId'] = value;
  }
  get $inParams_requestId(): any {
    return this.inParams['requestId'] ;
  }

  topToolbar = {
      reset_to_accruing: new ToolModel(new ButtonModel('reset_to_accruing', new ButtonStyles(null, null), false, 'Reset to accruing', 'ms-Icon ms-Icon--Reset')
    ),
      clean_transactions: new ToolModel(new ButtonModel('clean_transactions', new ButtonStyles(null, null), false, 'Clean transactions', 'ms-Icon ms-Icon--EraseTool')
    )
  };

  bottomToolbar = {
  };

  formGroup: FormGroup = new FormGroup({
    status: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
  });

  filters = {
    status: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['status'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false, 
  '')
, new ControlContainerStyles(null, null), 'Status', false)
,
  }

  //#region filters inParams
  //#endregion filters inParams

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    private shell: Invoices_ShellService,
    private datasources: Invoices_DatasourceService,
    private flows: Invoices_FlowService,
    private reports: Invoices_ReportService,
    private localization: Invoices_LocalizationService,
    private operations: Invoices_OperationService,
    private logger: CleanupLoggerService,
    ) {
    super();
    this.title = 'invoicing_transactions_grid';
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$init();
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$init();
    }
  }


  initialized = false;

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();

    this.clearRows();
  }

  async $init() {
    const $grid = this;
    const $utils = this.utils;


    this.initialized = true;

    this.pageSkip = 0;
    this.refresh(true, true, null);
  }

  protected override $initEmpty() {
    this.clearRows();
  }

  private $subscribeFormControlValueChanges() {

    this.formGroup.valueChanges.pipe(takeUntil(this.$unsubscribe$)).subscribe(value => {
      this.reload();
    });
  }

  reload() {
    this.pageSkip = 0;
    this.refresh();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();

    // children
    if (skipChildren === false) {
    }

    return result;
  }

  $rowPropertyChangeCallback (source: GridRowModel, property: string): void {
    if (property === 'selected') {
      this.$gridComponent.updateAllSelected();
    }
  }

  async $dataLoad() {
    this.loadingStatus = ELoadingStatus.Loading;
    if(!this.formGroup.valid) {
      return;
    }
    const $grid = this;
    const $utils = this.utils;
    const inParams = {
      $top: this.pageSize,
      $skip: this.pageSkip,
      search:  $grid.fullTextSearch ,
      billingContractId:  $grid.inParams.billingContractId ,
      projectId:  $grid.inParams.projectId ,
      invoiceId:  $grid.inParams.invoiceId ,
      instructionId:  $grid.inParams.instructionId ,
      transactionId:  $grid.inParams.transactionId ,
      requestId:  $grid.inParams.requestId ,
      status:  $grid.filters.status.control.value ,
    };
    try {
    const data = await this.datasources.Invoices.ds_invoicing_transactions.getList(inParams);
      this.entities = data.result;
      this.totalCount = data.totalCount;
      await this.$dataLoaded();
      if (this.totalCount === 0) {
        this.loadingStatus = ELoadingStatus.NoResults;
      } else {
        this.loadingStatus = ELoadingStatus.Loaded;
      }
    } catch(error) {
      console.error("Error loading data:", error);
      this.loadingStatus = ELoadingStatus.Error;
    }
  }

  async $dataLoaded() {
    const $grid = this;
    const $utils = this.utils;
    
    this.clearRows();

    if(this.entities) {
      let rowLoadPromises = [];
      for (let entity of this.entities) {
        const row = new Invoices_invoicing_transactions_gridComponentRowModel(
          this.utils,
          this.settings,
          this.shell, 
          this.datasources,
          this.flows,
          this.reports,
          this.localization,
          this.operations,
          this.logger);
        rowLoadPromises.push( row.$initializeExisting(this, entity, this.$rowPropertyChangeCallback.bind(this)));
        this.rows.push(row);
      }
      await Promise.all(rowLoadPromises);
    }

  }

  clearRows() {
    if(this.rows && this.rows.length) {
      this.rows.forEach(r => r.destroy());
    }
    this.rows = [];
  }

  selectedRows = [];
  hasSelectedRows() {
    return this.selectedRows.length > 0;
  }

  $selectionChanged(selectedRows: any[]) {
    this.selectedRows = selectedRows;
    this.on_row_selected();
  }

  
  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }

  //#region private flows
  on_reset_clicked(event = null) {
    return this.on_reset_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_reset_clickedInternal(
    $grid: Invoices_invoicing_transactions_gridComponent,
  
    $shell: Invoices_ShellService,
    $datasources: Invoices_DatasourceService,
    $flows: Invoices_FlowService,
    $reports: Invoices_ReportService,
    $settings: SettingsValuesService,
    $operations: Invoices_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Invoices_LocalizationService,
    $event: any
  ) {
    this.logger.log('Invoices', 'invoicing_transactions_grid.on_reset_clicked');
  //O.Arias - 04/22/2024
  
  const selected_rows = $grid.selectedRows;
  let errors: { id: string, errorMessages: string[] }[] = [];
  let resubmitted_records = [];
  
  if (selected_rows.length === 0) {
      $shell.Invoices.openErrorDialog('Reset error!', 'No records were selected!');
      return;
  } else {
  
      for (const row of selected_rows) {
  
          let current_error: string;
          let success = false;
          current_error = null;
  
          try {
  
              let entity = row.entity
  
              if (entity.status === "Accruing") {
                  throw ({ message: `Record ${entity.transactionId} is already in Accruing status.` })
              };
  
              await transaction_update({
                  "transactionId": entity.transactionId,
                  "status": "Accruing"
              });
  
              await log_add({
                  "level": 0,
                  "process": "invoicing_transactions_grid",
                  "transactionId": entity.transactionId,
                  "notes": JSON.stringify({action: "Reset", row: row})
              });
  
              success = true;
  
          } catch (error) {
  
              let target_error = error;
  
              if (typeof target_error === "string") {
                  target_error = { message: target_error };
              } else {
                  while ($utils.isDefined(target_error.error)) {
                      target_error = target_error.error;
                  };
              };
  
              if (!$utils.isDefined(target_error.message)) {
                  target_error = { message: `Uncaught exception! ${JSON.stringify(target_error)}` };
              };
  
              current_error = target_error.message;
          };
  
          if (success) {
              resubmitted_records.push(row.entity.transactionId.toString())
          } else {
              errors.push({
                  id: row.entity.transactionId, errorMessages: [current_error]
              });
          };
      };
  
      if ($utils.isDefined(errors)) {
  
          await $shell.Invoices.openErrorDialog(
              `Reset record${selected_rows.length > 1 ? 's' : ''}`,
              resubmitted_records.length > 0 ? `Record${selected_rows.length > 1 ? 's' : ''} ${(await $flows.Utilities.grammar_format_string_array_flow({ values: resubmitted_records })).formattedValue} successfully reset.` : `No records were reset.`,
              null,
              `Errors resetting record${selected_rows.length > 1 ? 's' : ''}`,
              errors.map(error => ({ detail: error.errorMessages[0], message: `Id: ${error.id}` })))
      };
  };
  
  $grid.refresh();
  
  async function log_add(input: $types.Invoices.Log) {
      await $flows.Invoices.invoicing_log({ action: "Write", payload: input });
  };
  
  async function transaction_update(input: $types.Invoices.Transaction) {
      await $flows.Invoices.invoicing_transaction({ action: "Update", payload: [input] });
  };
  }
  on_row_selected(event = null) {
    return this.on_row_selectedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_row_selectedInternal(
    $grid: Invoices_invoicing_transactions_gridComponent,
  
    $shell: Invoices_ShellService,
    $datasources: Invoices_DatasourceService,
    $flows: Invoices_FlowService,
    $reports: Invoices_ReportService,
    $settings: SettingsValuesService,
    $operations: Invoices_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Invoices_LocalizationService,
    $event: any
  ) {
    this.logger.log('Invoices', 'invoicing_transactions_grid.on_row_selected');
  //O.Arias - 04/22/2024
  
  const selectedRowsCount = $grid.selectedRows.length;
  
  $grid.topToolbar.reset_to_accruing.control.readOnly = (await check_status(selectedRowsCount >= 0));
  
  async function check_status(allow: boolean) {
      for (let row of $grid.selectedRows) {
          if (row.entity.status !== "Accruing")
          {
              allow = false;
          };
      };
      return allow;
  };
  }
  on_clean(event = null) {
    return this.on_cleanInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_cleanInternal(
    $grid: Invoices_invoicing_transactions_gridComponent,
  
    $shell: Invoices_ShellService,
    $datasources: Invoices_DatasourceService,
    $flows: Invoices_FlowService,
    $reports: Invoices_ReportService,
    $settings: SettingsValuesService,
    $operations: Invoices_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Invoices_LocalizationService,
    $event: any
  ) {
    this.logger.log('Invoices', 'invoicing_transactions_grid.on_clean');
  //O.Arias - 04/22/2024
  $flows.Invoices.invoicing_transaction({action: "Clean"});
  $grid.refresh();
  }
  //#endregion private flows


 
  close() {
    this.$finish.emit();
  }
}
