<div class="blade-wrapper">
  <div class="blade-header">
    <div *ngIf="initialized && $hasDataLoaded && !$hasMissingRequiredInParams" class="blade-tools">
        <app-toolbar [toolbar]="toolbar">
            <ng-template toolbarToolDef="save" let-tool>
              <button mat-button
                      data-cy="button"
                      class="datex-button"
                      [disabled]="tool.control.readOnly"
                      [ngStyle]="tool.control.styles.style"
                      [ngClass]="tool.control.styles.classes"
                      (click)="on_save($event)"
                      >
                <div class="button-label">
                  <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                    <div class="button-icon">
                      <i data-cy="button-icon" class="datex-default-spinner"></i>
                    </div>
                  </ng-container>
                  <ng-template #showIcon>
                    <div class="button-icon">
                      <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                    </div>
                  </ng-template>
                  <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                </div>
              
              </button>
            </ng-template>
        </app-toolbar>
    </div>
    <ng-content></ng-content>
  </div>
  <div class="blade-content">
    <div class="datex-editor">
      <ng-container *ngIf="initialized && $hasDataLoaded && !$hasMissingRequiredInParams">
        <div [formGroup]="formGroup"
             class="formdata">
                <div data-cy="fieldset-id-instructions"
                     *ngIf="!fieldsets.instructions.hidden"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.instructions.collapsible }">
                    <div *ngIf="!fieldsets.instructions.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.instructions.toggle()">
                      <span class="fieldsetsTitle-text">Instuctions</span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.instructions.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.instructions.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.instructions.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.instructions.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-name" *ngIf="!fields.name.hidden" 
                            class="field-container full {{fields.name.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.name.styles.style"
                            [ngClass]="fields.name.styles.classes">
                        <div class="label-container"
                              title="{{fields.name.label}}{{fields.name.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.name.label}}">{{fields.name.label}}<span *ngIf="fields.name.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <input data-cy="textBox"formControlName="name"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{fields.name.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.name.control.placeholder}}"
                                [ngStyle]="fields.name.control.styles.style"
                                [ngClass]="fields.name.control.styles.classes"> 
                        <ng-container *ngIf="fields.name.invalid">
                          <ng-container *ngFor="let error of fields.name.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-terms" *ngIf="!fields.terms.hidden" 
                            class="field-container full {{fields.terms.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.terms.styles.style"
                            [ngClass]="fields.terms.styles.classes">
                        <div class="label-container"
                              title="{{fields.terms.label}}{{fields.terms.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.terms.label}}">{{fields.terms.label}}<span *ngIf="fields.terms.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <Invoices-invoice_terms_dd_single 
                            data-cy="selector"
                            [type]="fields.terms.control.type"
                            formControlName="terms"
                            (displayTextChange)="fields.terms.control.displayText=$event"
                            [placeholder]="fields.terms.control.placeholder"
                            [styles]="fields.terms.control.styles"
                        >
                        </Invoices-invoice_terms_dd_single>
                        <ng-container *ngIf="fields.terms.invalid">
                          <ng-container *ngFor="let error of fields.terms.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-tax_schedule" *ngIf="!fields.tax_schedule.hidden" 
                            class="field-container full {{fields.tax_schedule.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.tax_schedule.styles.style"
                            [ngClass]="fields.tax_schedule.styles.classes">
                        <div class="label-container"
                              title="{{fields.tax_schedule.label}}{{fields.tax_schedule.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.tax_schedule.label}}">{{fields.tax_schedule.label}}<span *ngIf="fields.tax_schedule.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <Invoices-tax_schedules_dd_single 
                            data-cy="selector"
                            [type]="fields.tax_schedule.control.type"
                            formControlName="tax_schedule"
                            (displayTextChange)="fields.tax_schedule.control.displayText=$event"
                            [placeholder]="fields.tax_schedule.control.placeholder"
                            [styles]="fields.tax_schedule.control.styles"
                        >
                        </Invoices-tax_schedules_dd_single>
                        <ng-container *ngIf="fields.tax_schedule.invalid">
                          <ng-container *ngFor="let error of fields.tax_schedule.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-billing_aggregation_strategies" *ngIf="!fields.billing_aggregation_strategies.hidden" 
                            class="field-container full {{fields.billing_aggregation_strategies.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.billing_aggregation_strategies.styles.style"
                            [ngClass]="fields.billing_aggregation_strategies.styles.classes">
                        <div class="label-container"
                              title="{{fields.billing_aggregation_strategies.label}}{{fields.billing_aggregation_strategies.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.billing_aggregation_strategies.label}}">{{fields.billing_aggregation_strategies.label}}<span *ngIf="fields.billing_aggregation_strategies.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <Invoices-billing_aggregation_strategies_dd_multi 
                            data-cy="selector-multi"
                            [type]="fields.billing_aggregation_strategies.control.type"
                            formControlName="billing_aggregation_strategies"
                            (displayTextChange)="fields.billing_aggregation_strategies.control.displayText=$event"
                            [placeholder]="fields.billing_aggregation_strategies.control.placeholder"
                            [styles]="fields.billing_aggregation_strategies.control.styles"
                          [id]="$fields_billing_aggregation_strategies_selector_inParams_id"
                        >
                        </Invoices-billing_aggregation_strategies_dd_multi>
                        <ng-container *ngIf="fields.billing_aggregation_strategies.invalid">
                          <ng-container *ngFor="let error of fields.billing_aggregation_strategies.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-one_invoice_per_warehouse" *ngIf="!fields.one_invoice_per_warehouse.hidden" 
                            class="field-container full {{fields.one_invoice_per_warehouse.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.one_invoice_per_warehouse.styles.style"
                            [ngClass]="fields.one_invoice_per_warehouse.styles.classes">
                        <div class="label-container"
                              title="{{fields.one_invoice_per_warehouse.label}}{{fields.one_invoice_per_warehouse.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.one_invoice_per_warehouse.label}}">{{fields.one_invoice_per_warehouse.label}}<span *ngIf="fields.one_invoice_per_warehouse.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <mat-slide-toggle data-cy="slideToggle" formControlName="one_invoice_per_warehouse"
                                      color="primary"
                                      class="datex-toggle"
                                      [ngStyle]="fields.one_invoice_per_warehouse.control.styles.style"
                                      [ngClass]="fields.one_invoice_per_warehouse.control.styles.classes">{{fields.one_invoice_per_warehouse.control.label}}</mat-slide-toggle>
                        <ng-container *ngIf="fields.one_invoice_per_warehouse.invalid">
                          <ng-container *ngFor="let error of fields.one_invoice_per_warehouse.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-one_invoice_per_shipment" *ngIf="!fields.one_invoice_per_shipment.hidden" 
                            class="field-container full {{fields.one_invoice_per_shipment.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.one_invoice_per_shipment.styles.style"
                            [ngClass]="fields.one_invoice_per_shipment.styles.classes">
                        <div class="label-container"
                              title="{{fields.one_invoice_per_shipment.label}}{{fields.one_invoice_per_shipment.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.one_invoice_per_shipment.label}}">{{fields.one_invoice_per_shipment.label}}<span *ngIf="fields.one_invoice_per_shipment.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <mat-slide-toggle data-cy="slideToggle" formControlName="one_invoice_per_shipment"
                                      color="primary"
                                      class="datex-toggle"
                                      [ngStyle]="fields.one_invoice_per_shipment.control.styles.style"
                                      [ngClass]="fields.one_invoice_per_shipment.control.styles.classes">{{fields.one_invoice_per_shipment.control.label}}</mat-slide-toggle>
                        <ng-container *ngIf="fields.one_invoice_per_shipment.invalid">
                          <ng-container *ngFor="let error of fields.one_invoice_per_shipment.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-active" *ngIf="!fields.active.hidden" 
                            class="field-container full {{fields.active.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.active.styles.style"
                            [ngClass]="fields.active.styles.classes">
                        <div class="label-container"
                              title="{{fields.active.label}}{{fields.active.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.active.label}}">{{fields.active.label}}<span *ngIf="fields.active.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <mat-slide-toggle data-cy="slideToggle" formControlName="active"
                                      color="primary"
                                      class="datex-toggle"
                                      [ngStyle]="fields.active.control.styles.style"
                                      [ngClass]="fields.active.control.styles.classes">{{fields.active.control.label}}</mat-slide-toggle>
                        <ng-container *ngIf="fields.active.invalid">
                          <ng-container *ngFor="let error of fields.active.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-create_new_invoice" *ngIf="!fields.create_new_invoice.hidden" 
                            class="field-container full {{fields.create_new_invoice.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.create_new_invoice.styles.style"
                            [ngClass]="fields.create_new_invoice.styles.classes">
                        <div class="label-container"
                              title="{{fields.create_new_invoice.label}}{{fields.create_new_invoice.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.create_new_invoice.label}}">{{fields.create_new_invoice.label}}<span *ngIf="fields.create_new_invoice.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <mat-slide-toggle data-cy="slideToggle" formControlName="create_new_invoice"
                                      color="primary"
                                      class="datex-toggle"
                                      [ngStyle]="fields.create_new_invoice.control.styles.style"
                                      [ngClass]="fields.create_new_invoice.control.styles.classes">{{fields.create_new_invoice.control.label}}</mat-slide-toggle>
                        <ng-container *ngIf="fields.create_new_invoice.invalid">
                          <ng-container *ngFor="let error of fields.create_new_invoice.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
                  </div>
                </div>
              </div>

        <div class="datex-tabcontrol" *ngIf="!this.rootTabGroup.allTabsHidden">
          <div class="tab-container">
            <div *ngIf="!tabs.transactions.hidden" class="tab" data-cy="tab-transactions">
              <h2 [className]="tabs.transactions.active? 'active': ''" (click)="tabs.transactions.activate()">{{tabs.transactions.title}}</h2>
            </div>
            <div *ngIf="!tabs.logs.hidden" class="tab" data-cy="tab-logs">
              <h2 [className]="tabs.logs.active? 'active': ''" (click)="tabs.logs.activate()">{{tabs.logs.title}}</h2>
            </div>
          </div>
        
              <Invoices-invoicing_transactions_grid *ngIf="tabs.transactions.active"
              #$tabs_transactions
              [instructionId]="$tabs_transactions_invoicing_transactions_grid_inParams_instructionId"
              ($refreshEvent)="refresh(false, false, '$tabs_transactions')">
              </Invoices-invoicing_transactions_grid>
              <Invoices-invoicing_logs_grid *ngIf="tabs.logs.active"
              #$tabs_logs
              [instructionId]="$tabs_logs_invoicing_logs_grid_inParams_instructionId"
              ($refreshEvent)="refresh(false, false, '$tabs_logs')">
              </Invoices-invoicing_logs_grid>
        </div>
      </ng-container>

      <div *ngIf="$hasMissingRequiredInParams" class="missing-params">
        <h3>Please provide {{$missingRequiredInParams.toString()}}</h3>
      </div>
      <div *ngIf="initialized && !$hasDataLoaded && !$hasMissingRequiredInParams" class="missing-params">
        <h3>No data to display</h3>
      </div>
    </div>
  </div>
</div>