import { Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { ReportBaseService } from './report-base.service';

import { UtilsService } from './utils.service';
import { Reports_DatasourceService } from './Reports.datasource.index';

interface IReports_custom_packing_list_b2c_reportServiceInParams {
  orderId?: number}

interface IReports_custom_packing_list_b2c_reportServiceData {
  Header?: { result?: { Id?: number, CreatedSysDateTime?: string, CreatedSysUser?: string, LookupCode?: string, Notes?: string, OwnerReference?: string, RequestedDeliveryDate?: string, VendorReference?: string, ShipmentOrderLookups?: { OrderId?: number, ShipmentId?: number, Shipment?: { Id?: number, ItemCount?: number, LookupCode?: string, TrackingIdentifier?: string, Carrier?: { Name?: string }, CarrierServiceType?: { Name?: string } } }[], Project?: { Name?: string, Owner?: { Name?: string } }, shipToAddress?: { AttentionOf?: string, FirstName?: string, LastName?: string, Line1?: string, Line2?: string, City?: string, State?: string, PostalCode?: string, Country?: string, PrimaryTelephone?: string, OrderId?: number, accountName?: string, accountLookupcode?: string }, shipByAddress?: { Id?: number, Project?: { Id?: number, Name?: string, Owner?: { Id?: number, Name?: string, OwnersContactsLookup?: { ContactId?: number, Contact?: { Id?: number, FirstName?: string, LastName?: string, PrimaryTelephone?: string, TypeId?: number, Address?: { City?: string, Country?: string, Line1?: string, Line2?: string, PostalCode?: string, State?: string } } }[] } } }, soldByAddress?: { Id?: number, Project?: { Id?: number, Name?: string, Owner?: { Id?: number, Name?: string, OwnersContactsLookup?: { ContactId?: number, Contact?: { Id?: number, FirstName?: string, LastName?: string, PrimaryTelephone?: string, TypeId?: number, Address?: { City?: string, Country?: string, Line1?: string, Line2?: string, PostalCode?: string, State?: string } } }[] } } } } }
  Lines?: { result?: { packagedAmount?: number, orderedAmount?: number, ShipmentLine?: { ShipmentId?: number, OrderId?: number, OrderLineNumber?: number, OrderLine?: { PackagedAmount?: number, Material?: { LookupCode?: string, Description?: string, ProjectId?: number } } } }[] }
  Logo?: { result?: { Id?: number, FileName?: string, FileExtension?: string, Description?: string, CreatedSysDateTime?: string, ModifiedSysDateTime?: string, FileSize?: number, FileContent?: string } }
  AddressLogo?: { result?: { Id?: number, FileName?: string, FileExtension?: string, Description?: string, CreatedSysDateTime?: string, ModifiedSysDateTime?: string, FileSize?: number, FileContent?: string } }
}

@Injectable({ providedIn: 'root' })
export class Reports_custom_packing_list_b2c_reportService extends ReportBaseService<IReports_custom_packing_list_b2c_reportServiceInParams, IReports_custom_packing_list_b2c_reportServiceData> {

  protected reportReferenceName = 'custom_packing_list_b2c_report';
  protected appReferenceName = 'Reports';

  constructor(
    utils: UtilsService,
    private datasources: Reports_DatasourceService,
    ) {
      super(utils);
  }

  override throwIfMissingRequiredInParams (inParams: IReports_custom_packing_list_b2c_reportServiceInParams) {
  }

  protected async getData(inParams: IReports_custom_packing_list_b2c_reportServiceInParams): Promise<IReports_custom_packing_list_b2c_reportServiceData> 
  {
    const $utils = this.utils;
    const $report: { inParams: IReports_custom_packing_list_b2c_reportServiceInParams } = {
      inParams: inParams
    };

    const data: IReports_custom_packing_list_b2c_reportServiceData = {
    };
    const datasourcesQueries: (() => Promise<void>)[] = [];

    datasourcesQueries.push(async () => {
      const dsParams = {
        orderId:  $report.inParams.orderId 
      };
      
      const dsData = await this.datasources.Reports.custom_ds_packing_list_report_zagg_header.get(dsParams);
      
      data.Header = dsData;
    });
    datasourcesQueries.push(async () => {
      const dsParams = {
        orderId:  $report.inParams.orderId 
      };
      
      const dsData = await this.datasources.Reports.custom_ds_packing_list_zagg_report_lines.get(dsParams);
      
      data.Lines = dsData;
    });
    datasourcesQueries.push(async () => {
      const dsParams = {
        orderId:  $report.inParams.orderId 
      };
      
      const dsData = await this.datasources.Reports.custom_ds_get_owner_logo_attachment.get(dsParams);
      
      data.Logo = dsData;
    });
    datasourcesQueries.push(async () => {
      const dsParams = {
        orderId:  $report.inParams.orderId 
      };
      
      const dsData = await this.datasources.Reports.custom_ds_get_owner_address_logo_attachment.get(dsParams);
      
      data.AddressLogo = dsData;
    });

    await Promise.all(datasourcesQueries.map(t => t()));

    return data;
  }
}
